import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../Context/context';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMainJobById, getMainJobsApplyEmployees } from '../../api/home';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from './BasicDetailsForm';
import { Avatar, Button, Card, Grid, Pagination } from '@mui/material';
import industry from "../../assets/imgs/page/job-single/industry.svg"
import jobLevel from "../../assets/imgs/page/job-single/job-level.svg"
import salary from "../../assets/imgs/page/job-single/salary.svg"
import experience from "../../assets/imgs/page/job-single/experience.svg"
import location from "../../assets/imgs/page/job-single/location.svg"
import { logoRedColor } from './JobPostInfo';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import CallIcon from '@mui/icons-material/Call';
import NoDataFound from '../NoDataFound';
import CandidateDetails, { getGenderFromId } from './CandidateDetails';



const CandidateList = ({ selectedJobId, jobTitle }) => {
    const [isOpenSelectedCandidate, setIsOpenSelectedCandidate] = useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState("");
    // const [count, setCount] = useState(0)
    // const [activePage, setActivePage] = useState(1);


    const {
        selectedJob,
        setIsJobDetailsOpen,
        setViewCandidateList,
        setCandidateListData,
        candidateListData,
        setAlertMessage,
        setLogin,
        login,
        setSelectedJob
    } = useContext(Context);

    const navigate = useNavigate()

    useEffect(() => {
        getMainJobById(selectedJobId)
            .then((res) => {
                setSelectedJob(res?.data?.data?.job_data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        return () => {
            setViewCandidateList(false);
        };
    }, []);


    useEffect(() => {
        getMainJobsApplyEmployees(selectedJobId)
            .then((res) => {
                setCandidateListData(res?.data?.data?.employee_data?.rows);
                // setCount(res?.data?.data?.employee_data?.count);
            })
            .catch((err) => {
                console.log(err);
                errorAlert(setAlertMessage, err, navigate, setLogin, login);
            });
    }, []);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div>
            <div
                onClick={() => {
                    setViewCandidateList(false);
                    setIsJobDetailsOpen(false);
                }}
                style={{
                    // position: "absolute",
                    // top: "10px",
                    // left: "10px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                }}>
                <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
                <span style={{ fontSize: "1.3rem", marginLeft: "10px" }}>Back</span>
            </div>
            <div className="row">
                <h5 style={{ textAlign: "left", margin: "20px 0px 20px 0px" }}>
                    Candidates for {jobTitle}
                </h5>
            </div>
            <Grid container alignItems={"center"}>
                {console.log(candidateListData, "candidateListData")}
                {
                    candidateListData?.length > 0 ?
                        candidateListData?.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Card style={{ backgroundColor: "#F1F1F1", padding: "15px" }}>
                                        <Grid container direction={"column"} spacing={2}>
                                            <Grid item>
                                                <Grid container alignItems={"center"} spacing={1}>
                                                    <Grid item>
                                                        {
                                                            item?.user?.profile_image ? (
                                                                <Avatar
                                                                    alt="profile"
                                                                    src={item?.user?.profile_image}
                                                                    sx={{ width: 56, height: 56 }} />
                                                            ) : (
                                                                <Avatar
                                                                    {...stringAvatar(`${item?.user?.first_name} ${item?.user?.last_name}`)}
                                                                    alt="Remy Sharp"
                                                                    // src="/static/images/avatar/1.jpg"
                                                                    sx={{ width: 56, height: 56 }}
                                                                />
                                                            )
                                                        }

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={"column"} justifyContent={"center"}>
                                                            <Grid item>
                                                                <span style={{
                                                                    fontWeight: 600

                                                                }}>{`${item?.user?.first_name} ${item?.user?.last_name}`}</span> {" "}<strong onClick={
                                                                    () => {
                                                                        setIsOpenSelectedCandidate(true);
                                                                        setSelectedCandidate(item);
                                                                    }
                                                                } style={{ color: logoRedColor, fontSize: "12px", cursor: "pointer" }}>Details</strong>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container alignItems={"center"} spacing={1} style={{ fontSize: "12px" }}>
                                                                    <Grid item style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                        {/* <div className="sidebar-icon-item">
                                                                            <img
                                                                                src={experience}
                                                                                alt="kindjob"
                                                                            />
                                                                        </div> */}
                                                                        <span>{getGenderFromId(item?.user?.gender)}</span>
                                                                    </Grid>
                                                                    {/* <Grid item style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                        <div className="sidebar-icon-item">
                                                                            <img
                                                                                src={experience}
                                                                                alt="kindjob"
                                                                            />
                                                                        </div>
                                                                        <span>Fresher</span>
                                                                    </Grid>
                                                                    <Grid item style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                        <div className="sidebar-icon-item">
                                                                            <img
                                                                                src={location}
                                                                                alt="kindjob"
                                                                            />
                                                                        </div>
                                                                        <span>location</span>
                                                                    </Grid> */}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                item?.user?.user_preference?.resume_url && <Grid item style={{ cursor: "pointer", display: "flex", alignItems: "center", fontWeight: "bold", color: logoRedColor, fontSize: "12px" }}>
                                                    <DescriptionIcon style={{ fontSize: "16px" }} /> <a style={{ textDecoration: "none", color: logoRedColor }} href={item?.user?.user_preference?.resume_url ?
                                                        item?.user?.user_preference?.resume_url : null}
                                                        target="_blank" rel="noreferrer">View resume</a>
                                                </Grid>
                                            }

                                            <Grid item>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "15px",
                                                    color: "#66789c",
                                                    fontWeight: 500
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        gap: "40px",
                                                        fontSize: "12px"
                                                    }}>
                                                        <div >
                                                            <SchoolIcon sx={{ height: 15, width: 15 }} /> Education:
                                                        </div>
                                                        <div style={{ color: "black", }}>
                                                            {item?.user?.user_educations?.length > 0
                                                                ? item?.user?.user_educations?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <a className="btn-tags btn-tags-sm mb-10 mr-5">
                                                                                {item?.degree_title}
                                                                            </a>
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        gap: "70px",
                                                        fontSize: "12px"
                                                    }}>
                                                        <div >
                                                            <MilitaryTechIcon sx={{ height: 15, width: 15 }} /> Skills:
                                                        </div>
                                                        <div style={{ color: "black", }}>
                                                            {item?.user?.user_preference?.skills?.length > 0
                                                                ? item?.user?.user_preference?.skills?.map(
                                                                    (skill) => {
                                                                        return (
                                                                            <a className="btn-tags btn-tags-sm mb-10 mr-5">
                                                                                {skill}
                                                                            </a>
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        gap: "40px",
                                                        fontSize: "12px"
                                                    }}>
                                                        <div >
                                                            <GTranslateIcon sx={{ height: 15, width: 15 }} /> Language:
                                                        </div>
                                                        <div style={{ color: "black", }}>
                                                            {item?.user?.user_preference?.language?.length > 0
                                                                ? item?.user?.user_preference?.language?.map(
                                                                    (skill) => {
                                                                        return (
                                                                            <a className="btn-tags btn-tags-sm mb-10 mr-5">
                                                                                {skill}
                                                                            </a>
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item style={{ marginTop: "10px" }}>
                                                        <Button style={{ display: "flex", gap: '5px', fontSize: "12px", backgroundColor: "green", color: "white" }}>
                                                            <CallIcon /> {" "}Show Number
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Card>
                                    {/* {candidateListData?.length > 0 ? (
                                        <div
                                            className="paginations"
                                            style={{
                                                bottom: 0,
                                                backgroundColor: "white",
                                                position: "sticky",
                                                padding: "10px",
                                                width: "100%"
                                            }}>
                                            <Pagination
                                                count={Math.ceil(count / 8)}
                                                page={activePage}
                                                onChange={(e, pageNumber) => {
                                                    setActivePage(pageNumber);
                                                }}
                                            />
                                        </div>
                                    ) : null} */}
                                </Grid>
                            )
                        }) :
                        <NoDataFound noDataMessage="No Candidates Found" />
                }


            </Grid>

            {isOpenSelectedCandidate && (
                <CandidateDetails
                    selectedCandidate={selectedCandidate}
                    setIsOpenSelectedCandidate={setIsOpenSelectedCandidate}
                    isOpenSelectedCandidate={isOpenSelectedCandidate}
                />
            )}
        </div>
    )
}

export default CandidateList