
import Layout from "./Layout";
import SubscribeEmail from "./SubscribeEmail";
import about1 from "../assets/imgs/about1.svg"
import about2 from "../assets/imgs/about2.svg"
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import SEOData from "./SEOData";

export default function About() {
    const theme = useTheme()

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <>
            <SEOData keyword="About" />
            <Layout>
                <div>
                    <section className="section-box">
                        <div className="breacrumb-cover bg-img-about">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2 className="mb-10">About Us</h2>
                                        <p className="font-about color-text-paragraph-2">
                                            Get the latest news, updates and tips
                                        </p>
                                    </div>
                                    <div className="col-lg-6 text-lg-end">
                                        <ul className="breadcrumbs mt-40">
                                            <li>
                                                <a className="home-icon" href="/">
                                                    Home
                                                </a>
                                            </li>
                                            <li>About Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-box mt-30">
                        <div className="post-loop-grid">
                            <div className="container">
                                <div className="text-center">
                                    {/* <h6 className="f-18 color-text-mutted text-uppercase">
                    Our company
                  </h6> */}
                                    <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                                        About Our Company
                                    </h2>
                                    {/* <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">
                    Welcome to Kind Jobs, the premier job portal for job seekers and employers in India. Our
                    vision is to create a world where everyone can find meaningful and fulfilling work that aligns
                    with their values and aspiraAons. We believe that work should be more than just a means to
                    earn a living; it should be an opportunity for personal growth, professional development,
                    and making a posiAve impact on society.
                  </p>
                  <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">
                    If you're a job seeker looking for that life-changing
                    Opportunity or an employer looking for your dream
                    candidate, you have just arrived at the right platform.
                    With Kind Jobs, you can explore exciting job opportunities specially catered only for you!

                  </p>
                  <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">
                    We believe in "Certified Jobs, Qualified Candidates,
                    One Platform - Kind Jobs"!


                  </p> */}
                                </div>
                                <div className="row mt-10">
                                    {/* <div className="col-lg-6 col-md-12 col-sm-12">
                    <img
                      src="assets/imgs/page/about/img-about2.png"
                      alt="joxBox"
                    />
                  </div> */}
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* <h3 className="mt-15">What we can do?</h3> */}
                                        <div className="mt-20 ">
                                            <p className="font-about color-text-paragraph mt-20 wow animate__animated animate__fadeInUp">
                                                Welcome to Kind Jobs, the premier job portal for job
                                                seekers and employers in India. Our vision is to create
                                                a world where everyone can find meaningful and
                                                fulfilling work that aligns with their values and
                                                aspirations. We believe that work should be more than
                                                just a means to earn a living; it should be an
                                                opportunity for personal growth, professional
                                                development, and making a positive impact on society.
                                            </p>
                                            <p className="font-about color-text-paragraph mt-20 wow animate__animated animate__fadeInUp">
                                                At Kind Jobs, we are on a mission to revolutionize the
                                                way people approach the job search process. We
                                                understand that finding the right job can be a daunting
                                                task, filled with uncertainty and anxiety. That's why we
                                                have dedicated ourselves to creating a platform that not
                                                only connects job seekers with the right opportunities
                                                but also empowers them with the resources and support
                                                they need to navigate their career paths successfully.
                                            </p>
                                            <p className="font-about color-text-paragraph mt-20 wow animate__animated animate__fadeInUp">
                                                Our primary objective is to connect the right talent
                                                with the right opportunities, providing a reliable and
                                                user-friendly platform that makes the job search process
                                                more efficient and eﬀective for both job seekers and
                                                employers. We strive to be the go-to destination for
                                                individuals seeking their dream job and for companies
                                                seeking the perfect candidate.
                                            </p>
                                            <h4
                                                className="aboutUsTitle"
                                                style={{ margin: "30px 0px 10px 0px" }}>
                                                To achieve our objectives, we have set forth the
                                                following goals:
                                            </h4>
                                            <ul
                                                style={{
                                                    listStyle: "revert",
                                                    marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                                                    [theme.breakpoints.down('sm')]: {
                                                        marginLeft: "0px",
                                                    },
                                                }}>
                                                <div className="about-us-content">
                                                    <div className="col-xl-6 col-xs-12">
                                                        <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                                            <strong>Empowering Job Seekers:</strong> We aim to
                                                            empower job seekers by providing them with a wide
                                                            range of job opportunities, resources, and tools
                                                            to enhance their career prospects. Our platform
                                                            oﬀers comprehensive job listings from leading
                                                            companies across various industries, ensuring that
                                                            job seekers have access to the best opportunities
                                                            in the market. We also provide resources to help
                                                            job seekers improve their resumes, prepare for
                                                            interviews, and showcase their skills and
                                                            experience. Through our career guidance articles,
                                                            webinars, and workshops, we strive to equip job
                                                            seekers with the knowledge and skills necessary to
                                                            excel in their chosen fields.
                                                        </li>
                                                        <li style={{ marginBottom: '20px' }} className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                                            <strong>
                                                                Simplifying Recruitment for Employers:
                                                            </strong>{" "}
                                                            We are committed to simplifying the recruitment
                                                            process for employers. We understand that finding
                                                            the right candidate for a position can be
                                                            time-consuming and challenging. Our platform oﬀers
                                                            a comprehensive recruitment solution that includes
                                                            job posting, candidate search, and applicant
                                                            tracking. We aim to help employers find the best
                                                            talent quickly and efficiently, saving them time
                                                            and resources in the hiring process. Through our
                                                            advanced search filters and matching algorithms,
                                                            we ensure that employers can identify and connect
                                                            with candidates who meet their specific
                                                            requirements.
                                                        </li>
                                                    </div>
                                                    <div className="col-xl-6 col-xs-12 d-xl-block col-md-6">
                                                        <div
                                                            className="banner-imgs"
                                                            style={{ height: "100%" }}>
                                                            <div
                                                                className="block-1"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    height: "100%",
                                                                }}>
                                                                <img
                                                                    src={about1}
                                                                    alt="kindjob"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="about-us-content">
                                                    <div className="col-xl-6 col-xs-12 col-md-6">
                                                        <div
                                                            className="banner-imgs"
                                                            style={{ height: "100%" }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    height: "100%",
                                                                }}>
                                                                <img
                                                                    src={about2}
                                                                    alt="kindjob"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-xs-12">
                                                        <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                                            <strong>
                                                                Fostering a Kind and Inclusive Workplace:
                                                            </strong>{" "}
                                                            At Kind Jobs, we believe in the power of kindness
                                                            and aim to promote kindness in the workplace. We
                                                            are dedicated to creating a platform that fosters
                                                            kindness, diversity, and inclusivity. We strive to
                                                            connect employers and job seekers who value these
                                                            principles, creating a positive and supportive
                                                            work environment. We actively encourage employers
                                                            to adopt fair and inclusive hiring practices and
                                                            provide resources to help them build diverse and
                                                            equitable teams. Additionally, we regularly
                                                            highlight companies that prioritize kindness and
                                                            social responsibility, showcasing their
                                                            initiatives and inspiring others to follow suit.
                                                        </li>
                                                        <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                                            <strong>Creating a Community:</strong> We aim to
                                                            create a vibrant and engaged community of job
                                                            seekers and employers. Our platform is designed to
                                                            facilitate connections, networking, and
                                                            collaboration. We encourage users to interact,
                                                            share insights, and build relationships within our
                                                            community. Through our blog, forums, and social
                                                            media channels, we foster meaningful discussions
                                                            on career-related topics, industry trends, and
                                                            professional development. We believe that by
                                                            nurturing this community, we can create a support
                                                            system where individuals can learn from each
                                                            other, seek advice, and discover new
                                                            opportunities.
                                                        </li>
                                                        <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp mt-10">
                                                            <strong>Continuous Improvement:</strong> We are
                                                            committed to continuously improving our platform
                                                            and services. We understand that the job market is
                                                            dynamic and ever-evolving, and we strive to stay
                                                            ahead of the curve. We actively seek feedback from
                                                            our users and strive to incorporate their
                                                            suggestions and ideas into our platform's
                                                            development. We invest in research and development
                                                            to leverage emerging technologies and industry
                                                            best practices. By doing so, we aim to provide the
                                                            best user experience and ensure that our platform
                                                            remains a trusted and valuable resource for job
                                                            seekers and employers alike.
                                                        </li>
                                                    </div>
                                                </div>
                                            </ul>
                                            <p className="font-about color-text-paragraph mt-20 wow animate__animated animate__fadeInUp">
                                                By pursuing our vision, mission, objectives, and goals,
                                                we aim to make a significant impact on the job market in
                                                India and contribute to the professional growth and
                                                success of individuals and organizations. Thank you for
                                                choosing Kind Jobs, and we look forward to being a part
                                                of your journey towards finding the perfect job or the
                                                perfect candidate!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <SubscribeEmail />
                </div>
            </Layout>
        </>
    );
}
