/* eslint-disable react-hooks/exhaustive-deps */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Icon } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Context } from '../Context/context'
import { employerSetupData, getBrowesByCategory, getTopRecruiters } from '../api/home'
import apple from '../assets/imgs/apple.JPG'
import callHome from '../assets/imgs/callHome.svg'
import googleplay from '../assets/imgs/googleplay.JPG'
import home1 from '../assets/imgs/home1.png'
import home2 from '../assets/imgs/home2.png'
import searchHome from '../assets/imgs/searchHome.svg'
import userHome from '../assets/imgs/userHome.svg'
import { logoRedColor } from './Header'
import Layout from './Layout'
import SEOData from './SEOData'
import SubscribeEmail from './SubscribeEmail'
import CategorySlider from './employer/CategoryHomePage'


const EmployerHomePage = () => {

    const { navigate } = useNavigate()

    const {
        setTopRecruiter,
        // setCategoryJob,
        setBrowseByCategory,
        setAdditionalPerksOption,
        setDepartmentDataOption,
        setIndustryDataOption,
        setJobRoleDataOption,
        setJobTypeDataOption,
        setJoiningFeeTypeOption,
        setNoOfEmployeeOption,
        setPayTypeOption,
        setRoleDataOption,
        // jobTypeDataOption,
        // isTokenExpired,
        // setIsTokenExpired,
        setLogin,
        login,
        setToken,
        // setAlertMessage,
        // alertMessage,
        // setSeoData,
        // seoData,
        // setWelcomeMessage,
        // welcomeMessage,
        setIsLoginPageOpen,
    } = useContext(Context);

    const handleDownloadApp = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    const handleDownloadAppIOS = () => {
        Swal.fire({
            icon: "info",
            confirmButtonColor: logoRedColor,
            title: "Coming Soon!",
        });
    };

    useEffect(() => {
        getTopRecruiters()
            .then((res) => {
                setTopRecruiter(res?.data?.data?.department_jobs);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getBrowesByCategory()
            .then((res) => {
                setBrowseByCategory(res?.data?.data?.department_data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleEmployerLogin = () => {
        let token = localStorage.getItem("employerToken") ? true : false;
        if (
            (JSON.parse(localStorage.getItem("is_profile_created")) === 1 ||
                JSON.parse(localStorage.getItem("userData")) === true) &&
            JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true &&
            token
        ) {
            localStorage.setItem("welcomeMessage", true);
            navigate("/postjob");
        } else {
            if (JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true) {
                setLogin({
                    ...login,
                    detailsPage: true,
                    setPhone: false,
                });
                navigate("/employer/login");
            } else {
                setLogin({
                    ...login,
                    detailsPage: false,
                    setPhone: true,
                });
                navigate("/employer/login");
                setIsLoginPageOpen(true);
            }
        }
    };

    useEffect(() => {
        employerSetupData()
            .then((res) => {
                if (res?.data?.statusCode === 401) {
                    localStorage.removeItem("employerToken");
                    localStorage.removeItem("userData");
                } else {
                    setDepartmentDataOption(res?.data?.data?.department_data);
                    setAdditionalPerksOption(res?.data?.data?.additional_perks);
                    setIndustryDataOption(res?.data?.data?.industry_data);
                    setJobRoleDataOption(res?.data?.data?.job_role_data);
                    setJobTypeDataOption(res?.data?.data?.job_type);
                    setJoiningFeeTypeOption(res?.data?.data?.joining_fee_type);
                    setNoOfEmployeeOption(res?.data?.data?.number_of_employees);
                    setPayTypeOption(res?.data?.data?.pay_type);
                    setRoleDataOption(res?.data?.data?.role_data);
                    setToken(res?.data?.data?.token);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0 })
    })

    return (
        <>
            <SEOData keyword="Employer" />
            <Layout >
                <div className="bg-homepage1" />
                <section
                    className="section-box"
                    style={{ width: "100%", height: "100%", backgroundColor: "#f5f3f6" }}>
                    <div className="banner-hero hero-1">
                        <div className="banner-inner">
                            <div className="row">
                                <div className="col-xl-8 col-lg-12">
                                    <div className="block-banner">
                                        <h1
                                            style={{ lineHeight: 1.3 }}
                                            className="heading-banner wow animate__animated animate__fadeInUp">
                                            <span className="font-xxl" style={{ fontWeight: 500 }}>
                                                "No Junk Applications
                                            </span>
                                            {/* <br className="d-none d-lg-block" /> */}
                                            <br />{" "}
                                            <span
                                                className="font-xxl"
                                                style={{
                                                    fontWeight: "bolder",
                                                    color: logoRedColor,
                                                }}>
                                                Only on Kind Jobs!"
                                            </span>
                                            <br />
                                        </h1>
                                        <div
                                            className="banner-description mt-20 wow animate__animated animate__fadeInUp"
                                            data-wow-delay=".1s">
                                            Say hello to hassle-free hiring with Kind Jobs! Discover quality candidates, 
                                            pay for what you need, and enjoy peace of mind with
                                            our Moneyback guarantee.
                                            <br />
                                        </div>
                                        {/* <div
                                            style={{ display: "flex", width: "100%" }}
                                            className="form-find mt-40 wow animate__animated animate__fadeIn"
                                            data-wow-delay=".2s">
                                            <div className="box-industry">
                                                <select className="form-input mr-10 select-active input-industry">
                                                    <option value={0}>Industry</option>
                                                    <option value={1}>Software</option>
                                                    <option value={2}>Finance</option>
                                                    <option value={3}>Recruting</option>
                                                    <option value={4}>Management</option>
                                                    <option value={5}>Advertising</option>
                                                    <option value={6}>Development</option>
                                                </select>
                                            </div>
                                            <div className="box-industry">
                                                <select className="form-input mr-10 select-active  input-location">
                                                    <option value>Location</option>
                                                    <option value="AX">Aland Islands</option>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AL">Albania</option>
                                                    <option value="DZ">Algeria</option>
                                                    <option value="AD">Andorra</option>
                                                    <option value="AO">Angola</option>
                                                    <option value="AI">Anguilla</option>
                                                    <option value="AQ">Antarctica</option>
                                                    <option value="AG">Antigua and Barbuda</option>
                                                    <option value="AR">Argentina</option>
                                                    <option value="AM">Armenia</option>
                                                    <option value="AW">Aruba</option>
                                                    <option value="AU">Australia</option>
                                                    <option value="AT">Austria</option>
                                                    <option value="AZ">Azerbaijan</option>
                                                    <option value="BS">Bahamas</option>
                                                    <option value="BH">Bahrain</option>
                                                    <option value="BD">Bangladesh</option>
                                                    <option value="BB">Barbados</option>
                                                    <option value="BY">Belarus</option>
                                                    <option value="PW">Belau</option>
                                                    <option value="BE">Belgium</option>
                                                    <option value="BZ">Belize</option>
                                                    <option value="BJ">Benin</option>
                                                    <option value="BM">Bermuda</option>
                                                    <option value="BT">Bhutan</option>
                                                    <option value="BO">Bolivia</option>
                                                    <option value="BQ">
                                                        Bonaire, Saint Eustatius and Saba
                                                    </option>
                                                    <option value="BA">Bosnia and Herzegovina</option>
                                                    <option value="BW">Botswana</option>
                                                    <option value="BV">Bouvet Island</option>
                                                    <option value="BR">Brazil</option>
                                                    <option value="IO">
                                                        British Indian Ocean Territory
                                                    </option>
                                                    <option value="VG">British Virgin Islands</option>
                                                    <option value="BN">Brunei</option>
                                                    <option value="BG">Bulgaria</option>
                                                    <option value="BF">Burkina Faso</option>
                                                    <option value="BI">Burundi</option>
                                                    <option value="KH">Cambodia</option>
                                                    <option value="CM">Cameroon</option>
                                                    <option value="CA">Canada</option>
                                                    <option value="CV">Cape Verde</option>
                                                    <option value="KY">Cayman Islands</option>
                                                    <option value="CF">Central African Republic</option>
                                                    <option value="TD">Chad</option>
                                                    <option value="CL">Chile</option>
                                                    <option value="CN">China</option>
                                                    <option value="CX">Christmas Island</option>
                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                    <option value="CO">Colombia</option>
                                                    <option value="KM">Comoros</option>
                                                    <option value="CG">Congo (Brazzaville)</option>
                                                    <option value="CD">Congo (Kinshasa)</option>
                                                    <option value="CK">Cook Islands</option>
                                                    <option value="CR">Costa Rica</option>
                                                    <option value="HR">Croatia</option>
                                                    <option value="CU">Cuba</option>
                                                    <option value="CW">CuraÇao</option>
                                                    <option value="CY">Cyprus</option>
                                                    <option value="CZ">Czech Republic</option>
                                                    <option value="DK">Denmark</option>
                                                    <option value="DJ">Djibouti</option>
                                                    <option value="DM">Dominica</option>
                                                    <option value="DO">Dominican Republic</option>
                                                    <option value="EC">Ecuador</option>
                                                    <option value="EG">Egypt</option>
                                                    <option value="SV">El Salvador</option>
                                                    <option value="GQ">Equatorial Guinea</option>
                                                    <option value="ER">Eritrea</option>
                                                    <option value="EE">Estonia</option>
                                                    <option value="ET">Ethiopia</option>
                                                    <option value="FK">Falkland Islands</option>
                                                    <option value="FO">Faroe Islands</option>
                                                    <option value="FJ">Fiji</option>
                                                    <option value="FI">Finland</option>
                                                    <option value="FR">France</option>
                                                    <option value="GF">French Guiana</option>
                                                    <option value="PF">French Polynesia</option>
                                                    <option value="TF">
                                                        French Southern Territories
                                                    </option>
                                                    <option value="GA">Gabon</option>
                                                    <option value="GM">Gambia</option>
                                                    <option value="GE">Georgia</option>
                                                    <option value="DE">Germany</option>
                                                    <option value="GH">Ghana</option>
                                                    <option value="GI">Gibraltar</option>
                                                    <option value="GR">Greece</option>
                                                    <option value="GL">Greenland</option>
                                                    <option value="GD">Grenada</option>
                                                    <option value="GP">Guadeloupe</option>
                                                    <option value="GT">Guatemala</option>
                                                    <option value="GG">Guernsey</option>
                                                    <option value="GN">Guinea</option>
                                                    <option value="GW">Guinea-Bissau</option>
                                                    <option value="GY">Guyana</option>
                                                    <option value="HT">Haiti</option>
                                                    <option value="HM">
                                                        Heard Island and McDonald Islands
                                                    </option>
                                                    <option value="HN">Honduras</option>
                                                    <option value="HK">Hong Kong</option>
                                                    <option value="HU">Hungary</option>
                                                    <option value="IS">Iceland</option>
                                                    <option value="IN">India</option>
                                                    <option value="ID">Indonesia</option>
                                                    <option value="IR">Iran</option>
                                                    <option value="IQ">Iraq</option>
                                                    <option value="IM">Isle of Man</option>
                                                    <option value="IL">Israel</option>
                                                    <option value="IT">Italy</option>
                                                    <option value="CI">Ivory Coast</option>
                                                    <option value="JM">Jamaica</option>
                                                    <option value="JP">Japan</option>
                                                    <option value="JE">Jersey</option>
                                                    <option value="JO">Jordan</option>
                                                    <option value="KZ">Kazakhstan</option>
                                                    <option value="KE">Kenya</option>
                                                    <option value="KI">Kiribati</option>
                                                    <option value="KW">Kuwait</option>
                                                    <option value="KG">Kyrgyzstan</option>
                                                    <option value="LA">Laos</option>
                                                    <option value="LV">Latvia</option>
                                                    <option value="LB">Lebanon</option>
                                                    <option value="LS">Lesotho</option>
                                                    <option value="LR">Liberia</option>
                                                    <option value="LY">Libya</option>
                                                    <option value="LI">Liechtenstein</option>
                                                    <option value="LT">Lithuania</option>
                                                    <option value="LU">Luxembourg</option>
                                                    <option value="MO">Macao S.A.R., China</option>
                                                    <option value="MK">Macedonia</option>
                                                    <option value="MG">Madagascar</option>
                                                    <option value="MW">Malawi</option>
                                                    <option value="MY">Malaysia</option>
                                                    <option value="MV">Maldives</option>
                                                    <option value="ML">Mali</option>
                                                    <option value="MT">Malta</option>
                                                    <option value="MH">Marshall Islands</option>
                                                    <option value="MQ">Martinique</option>
                                                    <option value="MR">Mauritania</option>
                                                    <option value="MU">Mauritius</option>
                                                    <option value="YT">Mayotte</option>
                                                    <option value="MX">Mexico</option>
                                                    <option value="FM">Micronesia</option>
                                                    <option value="MD">Moldova</option>
                                                    <option value="MC">Monaco</option>
                                                    <option value="MN">Mongolia</option>
                                                    <option value="ME">Montenegro</option>
                                                    <option value="MS">Montserrat</option>
                                                    <option value="MA">Morocco</option>
                                                    <option value="MZ">Mozambique</option>
                                                    <option value="MM">Myanmar</option>
                                                    <option value="NA">Namibia</option>
                                                    <option value="NR">Nauru</option>
                                                    <option value="NP">Nepal</option>
                                                    <option value="NL">Netherlands</option>
                                                    <option value="AN">Netherlands Antilles</option>
                                                    <option value="NC">New Caledonia</option>
                                                    <option value="NZ">New Zealand</option>
                                                    <option value="NI">Nicaragua</option>
                                                    <option value="NE">Niger</option>
                                                    <option value="NG">Nigeria</option>
                                                    <option value="NU">Niue</option>
                                                    <option value="NF">Norfolk Island</option>
                                                    <option value="KP">North Korea</option>
                                                    <option value="NO">Norway</option>
                                                    <option value="OM">Oman</option>
                                                    <option value="PK">Pakistan</option>
                                                    <option value="PS">Palestinian Territory</option>
                                                    <option value="PA">Panama</option>
                                                    <option value="PG">Papua New Guinea</option>
                                                    <option value="PY">Paraguay</option>
                                                    <option value="PE">Peru</option>
                                                    <option value="PH">Philippines</option>
                                                    <option value="PN">Pitcairn</option>
                                                    <option value="PL">Poland</option>
                                                    <option value="PT">Portugal</option>
                                                    <option value="QA">Qatar</option>
                                                    <option value="IE">Republic of Ireland</option>
                                                    <option value="RE">Reunion</option>
                                                    <option value="RO">Romania</option>
                                                    <option value="RU">Russia</option>
                                                    <option value="RW">Rwanda</option>
                                                    <option value="ST">São Tomé and Príncipe</option>
                                                    <option value="BL">Saint Barthélemy</option>
                                                    <option value="SH">Saint Helena</option>
                                                    <option value="KN">Saint Kitts and Nevis</option>
                                                    <option value="LC">Saint Lucia</option>
                                                    <option value="SX">Saint Martin (Dutch part)</option>
                                                    <option value="MF">Saint Martin (French part)</option>
                                                    <option value="PM">Saint Pierre and Miquelon</option>
                                                    <option value="VC">
                                                        Saint Vincent and the Grenadines
                                                    </option>
                                                    <option value="SM">San Marino</option>
                                                    <option value="SA">Saudi Arabia</option>
                                                    <option value="SN">Senegal</option>
                                                    <option value="RS">Serbia</option>
                                                    <option value="SC">Seychelles</option>
                                                    <option value="SL">Sierra Leone</option>
                                                    <option value="SG">Singapore</option>
                                                    <option value="SK">Slovakia</option>
                                                    <option value="SI">Slovenia</option>
                                                    <option value="SB">Solomon Islands</option>
                                                    <option value="SO">Somalia</option>
                                                    <option value="ZA">South Africa</option>
                                                    <option value="GS">
                                                        South Georgia/Sandwich Islands
                                                    </option>
                                                    <option value="KR">South Korea</option>
                                                    <option value="SS">South Sudan</option>
                                                    <option value="ES">Spain</option>
                                                    <option value="LK">Sri Lanka</option>
                                                    <option value="SD">Sudan</option>
                                                    <option value="SR">Suriname</option>
                                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                                    <option value="SZ">Swaziland</option>
                                                    <option value="SE">Sweden</option>
                                                    <option value="CH">Switzerland</option>
                                                    <option value="SY">Syria</option>
                                                    <option value="TW">Taiwan</option>
                                                    <option value="TJ">Tajikistan</option>
                                                    <option value="TZ">Tanzania</option>
                                                    <option value="TH">Thailand</option>
                                                    <option value="TL">Timor-Leste</option>
                                                    <option value="TG">Togo</option>
                                                    <option value="TK">Tokelau</option>
                                                    <option value="TO">Tonga</option>
                                                    <option value="TT">Trinidad and Tobago</option>
                                                    <option value="TN">Tunisia</option>
                                                    <option value="TR">Turkey</option>
                                                    <option value="TM">Turkmenistan</option>
                                                    <option value="TC">Turks and Caicos Islands</option>
                                                    <option value="TV">Tuvalu</option>
                                                    <option value="UG">Uganda</option>
                                                    <option value="UA">Ukraine</option>
                                                    <option value="AE">United Arab Emirates</option>
                                                    <option value="GB">United Kingdom (UK)</option>
                                                    <option value="US">USA (US)</option>
                                                    <option value="UY">Uruguay</option>
                                                    <option value="UZ">Uzbekistan</option>
                                                    <option value="VU">Vanuatu</option>
                                                    <option value="VA">Vatican</option>
                                                    <option value="VE">Venezuela</option>
                                                    <option value="VN">Vietnam</option>
                                                    <option value="WF">Wallis and Futuna</option>
                                                    <option value="EH">Western Sahara</option>
                                                    <option value="WS">Western Samoa</option>
                                                    <option value="YE">Yemen</option>
                                                    <option value="ZM">Zambia</option>
                                                    <option value="ZW">Zimbabwe</option>
                                                </select>
                                            </div>
                                            <input
                                                className="form-input input-keysearch mr-10"
                                                type="text"
                                                placeholder="Your keyword... "
                                            />
                                            <button
                                                // onClick={handleEmployerLogin}
                                                className="buttonCustom hover-up btn-find font-sm">
                                                Search
                                            </button>
                                        </div>
                                        <div
                                            className="list-tags-banner mt-60 wow animate__animated animate__fadeInUp"
                                            data-wow-delay=".3s">
                                            <strong style={{ color: "#4945D1" }}>
                                                Popular Searches:
                                            </strong>
                                            <Link legacyBehavior href="#">
                                                <a style={{ color: logoRedColor }}>Tele-caller, </a>
                                            </Link>
                                            <Link legacyBehavior href="#">
                                                <a style={{ color: logoRedColor }}> Accountant, </a>
                                            </Link>
                                            <Link legacyBehavior href="#">
                                                <a style={{ color: logoRedColor }}>Receptionist, </a>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
                                    <div className="banner-imgs">
                                        <div className="block-1">
                                            <img style={{maxWidth:"90%"}}
                                                className="img-responsive"
                                                alt="kindjob"
                                                src={home1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-box" style={{ marginTop: "30px" }}>
                    <div className="banner-hero hero-1">
                        <div className="banner-inner">
                            <div className="row">
                                <div className="col-xl-8 col-lg-12">
                                    <div className="block-banner">
                                        <h1
                                            style={{ lineHeight: 1.3 }}
                                            className="heading-banner wow animate__animated animate__fadeInUp">
                                            <span className="font-xxl" style={{ fontWeight: "500" }}>
                                                HOW TO FIND YOUR 
                                            </span>
                                            {/* <br className="d-none d-lg-block" /> */}
                                            <br />{" "}
                                            <span
                                                className="font-xxl"
                                                style={{
                                                    fontWeight: "bolder",
                                                    color: logoRedColor,
                                                }}>
                                                STAR EMPLOYEE ON KIND JOBS !
                                            </span>
                                            <br />
                                        </h1>
                                        <div
                                            className="banner-description mt-20 wow animate__animated animate__fadeInUp"
                                            data-wow-delay=".1s">
                                            Find your dream team with just a few clicks. You are only 3 
                                            <br className="d-none d-lg-block" />
                                            steps away - that's it !
                                            <br />
                                        </div>
                                        <div className="employer-home-section2">
                                            <div
                                                className="item-logo"
                                                style={{
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    borderRadius: "30px",
                                                    padding: "10px",
                                                    wordWrap: "wrap",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    backgroundColor: logoRedColor,
                                                    maxWidth: "200px",
                                                    width: "200px",
                                                    height: "100px",
                                                }}>
                                                <div
                                                    className="image-left"
                                                    style={{ height: "60%", width: "60%" }}>
                                                    <img
                                                        alt="kindjob"
                                                        style={{ width: "100%", height: "100%" }}
                                                        src={userHome}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}>
                                                    <h6 style={{ color: "white" }}>
                                                        Create your Account
                                                    </h6>
                                                    {/* <p className="font-xs">
                                                {item?.job_count}
                                                <span> Jobs Available</span>
                                            </p> */}
                                                </div>
                                            </div>
                                            <div>
                                                <Icon
                                                    style={{
                                                        fontSize: "14px",
                                                        width: "50px",
                                                        height: "50px",
                                                    }}>
                                                    <ArrowForwardIosIcon
                                                        className="employer-home-arrow-icon"
                                                        style={{ width: "100%", height: "100%" }}
                                                    />
                                                </Icon>
                                            </div>
                                            <div
                                                className="item-logo"
                                                style={{
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    borderRadius: "30px",
                                                    padding: "10px",
                                                    wordWrap: "wrap",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    backgroundColor: logoRedColor,
                                                    maxWidth: "200px",
                                                    width: "200px",
                                                    height: "100px",
                                                }}>
                                                <div
                                                    className="image-left"
                                                    style={{ height: "60%", width: "60%" }}>
                                                    <img
                                                        alt="kindjob"
                                                        style={{ width: "100%", height: "100%" }}
                                                        src={searchHome}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}>
                                                    <h6 style={{ color: "white" }}>Post your Job</h6>
                                                    {/* <p className="font-xs">
                                                {item?.job_count}
                                                <span> Jobs Available</span>
                                            </p> */}
                                                </div>
                                            </div>
                                            <div>
                                                <Icon
                                                    style={{
                                                        fontSize: "14px",
                                                        width: "50px",
                                                        height: "50px",
                                                    }}>
                                                    <ArrowForwardIosIcon
                                                        className="employer-home-arrow-icon"
                                                        style={{ width: "100%", height: "100%" }}
                                                    />
                                                </Icon>
                                            </div>
                                            <div
                                                className="item-logo"
                                                style={{
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    borderRadius: "30px",
                                                    padding: "10px",
                                                    wordWrap: "wrap",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    backgroundColor: logoRedColor,
                                                    maxWidth: "200px",
                                                    width: "200px",
                                                    height: "100px",
                                                }}>
                                                <div
                                                    className="image-left"
                                                    style={{ height: "60%", width: "60%" }}>
                                                    <img
                                                        alt="kindjob"
                                                        style={{ width: "100%", height: "100%" }}
                                                        src={callHome}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}>
                                                    <h6 style={{ color: "white", fontSize: "16px" }}>
                                                        Start getting Applications
                                                    </h6>
                                                    {/* <p className="font-xs">
                                                {item?.job_count}
                                                <span> Jobs Available</span>
                                            </p> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div
                                            className="banner-description mt-20 wow animate__animated animate__fadeInUp"
                                            data-wow-delay=".1s">
                                            <div className="heading-banner wow animate__animated animate__fadeInUp">
                                                <span className="font-xl" style={{ fontWeight: 700 }}>
                                                    Apply on the go
                                                </span>
                                            </div>
                                            Get real-time job update on our App
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                                marginTop: "20px",
                                            }}>
                                            <img
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleDownloadApp(
                                                        "https://play.google.com/store/apps/details?id=com.kindjobs"
                                                    )
                                                }
                                                className="app-btn"
                                                src={googleplay}
                                                alt="googlePlay"
                                            />
                                            <img
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleDownloadApp(
                                                    "https://apps.apple.com/in/app/kind-jobs/id6448302467"
                                                )}
                                                className="app-btn"
                                                src={apple}
                                                alt="applestore"
                                            />
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
                                    {/* <div className="banner-imgs"> */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            height: "100%",
                                        }}>
                                        <img
                                            style={{ height: "100%" }}
                                            className="img-responsive"
                                            alt="kindjob"
                                            src={home2}
                                        />
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="section-box home-category">
                    <div className="section-box wow animate__animated animate__fadeIn">
                        <div className="container">
                            <div className="text-center">
                                <h2
                                    style={{ fontWeight: "bold" }}
                                    className="font-xxl mb-15 wow animate__animated animate__fadeInUp">
                                    Browse by Category
                                </h2>
                                <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                    Explore the job opportunities across several categories and
                                    find the perfect job for you!
                                </p>
                            </div>
                            <div className="box-swiper mt-50">
                                <CategorySlider />
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="section-box overflow-visible mt-50 mb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="text-center">
                                    <h1 className="color-brand-2" style={{ fontWeight: "bold", fontSize: "56px" }}>
                                        <span className="count">2</span>
                                        <span>K+</span>
                                    </h1>
                                    <h5 style={{ fontWeight: "bold" }}>Completed Cases</h5>
                                    <p className="font-sm color-text-paragraph mt-10">
                                        We always provide people a{" "}
                                        <br className="d-none d-lg-block" />
                                        complete solution upon focused of
                                        <br className="d-none d-lg-block" /> any business
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="text-center">
                                    <h1 className="color-brand-2" style={{ fontWeight: "bold", fontSize: "56px" }}>
                                        <span className="count">17</span>
                                        <span> +</span>
                                    </h1>
                                    <h5 style={{ fontWeight: "bold" }}>Our Office</h5>
                                    <p className="font-sm color-text-paragraph mt-10">
                                        We always provide people a{" "}
                                        <br className="d-none d-lg-block" />
                                        complete solution upon focused of{" "}
                                        <br className="d-none d-lg-block" />
                                        any business
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="text-center">
                                    <h1 className="color-brand-2" style={{ fontWeight: "bold", fontSize: "56px" }}>
                                        <span className="count">86</span>
                                        <span> +</span>
                                    </h1>
                                    <h5 style={{ fontWeight: "bold" }}>Skilled People</h5>
                                    <p className="font-sm color-text-paragraph mt-10">
                                        We always provide people a{" "}
                                        <br className="d-none d-lg-block" />
                                        complete solution upon focused of{" "}
                                        <br className="d-none d-lg-block" />
                                        any business
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="text-center">
                                    <h1 className="color-brand-2" style={{ fontWeight: "bold", fontSize: "56px" }}>
                                        <span className="count">28</span>
                                        <span> +</span>
                                    </h1>
                                    <h5 style={{ fontWeight: "bold" }}>Happy Clients</h5>
                                    <p className="font-sm color-text-paragraph mt-10">
                                        We always provide people a{" "}
                                        <br className="d-none d-lg-block" />
                                        complete solution upon focused of{" "}
                                        <br className="d-none d-lg-block" />
                                        any business
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <SubscribeEmail />
            </Layout>
        </>
    )
}

export default EmployerHomePage