import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import PersonIcon from "@mui/icons-material/Person";
import TollIcon from "@mui/icons-material/Toll";
import WorkIcon from "@mui/icons-material/Work";
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Context } from "../../Context/context";
import { getListCoinTransaction } from "../../api/home";
import Sidebar from "../../assets/imgs/Sidebar.jpg";
import logo from "../../assets/imgs/logo.png";
import { logoRedColor } from "../Header";
import PlanSelectModal from "./PlanSelectModal";


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



export const nFormatter = (num, digits = 2) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

const JobPostSideBar = () => {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [openAccount, setOpenAccount] = React.useState(false);

  const navigate = useNavigate();
  const {
    employerTab,
    setEmployerTab,
    setAlertMessage,
    setTotalCoins,
    totalCoins,
    setOpenPlanDialog,
    openPlanDialog
  } = useContext(Context);

  useEffect(() => {
    setFirstName(localStorage.getItem("userFirstName"));
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleLogout = () => {
    // setEmployerTab("logout");
    // localStorage.clear();
    // navigate("/");
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to logout?",
      showCancelButton: true,
      confirmButtonColor: logoRedColor,
      confirmButtonText: "Logout",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result?.isConfirmed) {
        // setEmployerTab("logout");
        localStorage.clear();
        setAlertMessage({
          message: "Logout Successfully",
          isOpen: true,
          type: "success",
        });
        navigate("/");
      }
    });
  };

  useEffect(() => {
    const body = {
      search: "",
      page_no: 1,
      page_record: 10,
    };
    getListCoinTransaction(body)
      .then((res) => {
        setCount(res?.data?.data?.total_coin);
        setTotalCoins(res?.data?.data?.total_coin);
        localStorage.setItem("employerToken", res?.data?.data?.token);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    < >
      <Drawer variant="permanent" open={open}>
        <img src={Sidebar} alt="sidebar" style={{
          width: "100%", height: "100%",
          boxShadow: 'inset 0 0 0 1000px rgba(44, 44, 42, 0.8)',
          position: "absolute",
          zIndex: -2,
          objectFit: "cover",
          transform: 'scaleY(-1)',
        }} />
        {/* <div style={{
          //overlay on image
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundColor: "rgba(0,0,0,0.3)"
        }}></div> */}
        <DrawerHeader>
          <div
            onClick={() => {
              navigate("/employer");
            }}
            style={{
              display: "flex",
              padding: "10px",
              height: "100%",
              // backgroundColor: "#fcedee",
              cursor: "pointer",
            }}>
            <img alt="logo" width="auto" src={logo} />
          </div>
          <IconButton >
            {!open ?
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>
              : <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            }
          </IconButton>
        </DrawerHeader>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              backgroundColor: logoRedColor,
              color: "white"
            }}>
            {
              !open ? (
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>   <Tooltip placement="right-start" title="Coins" >{`${nFormatter(
                  totalCoins ? totalCoins : 0,
                  2
                )}`}</Tooltip></div>
              ) : (
                <>
                  <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                    {`${nFormatter(
                      totalCoins ? totalCoins : 0,
                      2
                    )} Coins`}</div>
                  <Button
                    onClick={() => {
                      setOpenPlanDialog(true);
                    }}
                    varaint="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: 600,
                      '&:hover': {
                        backgroundColor: "#d3d3d3",
                        color: "black",
                      }

                    }}>
                    Buy Coins
                  </Button>
                </>
              )
            }
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}>
            <List
              sx={{
                width: "100%", maxWidth: 360,
                fontWeight: "bold",

                // bold font for sidebar
                "& .MuiTypography-root": {
                  fontWeight: "bold",
                },
              }}
              component="nav"
              aria-labelledby="nested-list-subheader">
              <ListItemButton
                style={{
                  // backgroundColor: employerTab === "dashboard" ? logoRedColor : "",
                  color: employerTab === "dashboard" ? logoRedColor : "",
                }}
                onClick={() => {
                  setEmployerTab("dashboard");
                }}>
                <Tooltip placement="right-start" title="Dashboard" >
                  <ListItemIcon
                    style={{

                      color: employerTab === "dashboard" ? logoRedColor : "",
                    }}>
                    <DashboardIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
              <ListItemButton
                style={{
                  // backgroundColor: employerTab === "jobs" ? logoRedColor : "",
                  color: employerTab === "jobs" ? logoRedColor : "",
                }}
                onClick={() => {
                  setEmployerTab("jobs");
                }}>
                <Tooltip placement="right-start" title="Job List">
                  <ListItemIcon
                    style={{
                      color: employerTab === "jobs" ? logoRedColor : "",
                    }}>
                    <WorkIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Jobs" />
              </ListItemButton>
              <ListItemButton
                style={{
                  // backgroundColor: employerTab === "postJob" ? logoRedColor : "",
                  color: employerTab === "postJob" ? logoRedColor : "",
                }}
                onClick={() => {
                  setEmployerTab("postJob");
                }}>
                <Tooltip placement="right-start" title="Post a job">
                  <ListItemIcon
                    style={{
                      color: employerTab === "postJob" ? logoRedColor : "",
                    }}>
                    <MarkAsUnreadIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Post a job" />
              </ListItemButton>
              <ListItemButton onClick={handleClickAccount}>
                <Tooltip placement="right-start" title="My Account">
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="My Account" />
                {openAccount ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openAccount} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    style={{
                      // backgroundColor:
                      //   employerTab === "profile" ? logoRedColor : "",
                      color: employerTab === "profile" ? logoRedColor : "",
                    }}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      setEmployerTab("profile");
                    }}>
                    <Tooltip placement="right-start" title="Profile">
                      <ListItemIcon
                        style={{
                          color: employerTab === "profile" ? logoRedColor : "",
                        }}>
                        <AccountBoxIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Profile" />
                  </ListItemButton>
                  <ListItemButton
                    style={{
                      // backgroundColor:
                      //   employerTab === "manageCoins" ? logoRedColor : "",
                      color: employerTab === "manageCoins" ? logoRedColor : "",
                    }}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      setEmployerTab("manageCoins");
                    }}>
                    <Tooltip placement="right-start" title="Transactions">
                      <ListItemIcon
                        style={{
                          color: employerTab === "manageCoins" ? logoRedColor : "",
                        }}>
                        <TollIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Transactions" />
                  </ListItemButton>
                  {/* <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="D" />
              </ListItemButton> */}
                </List>
              </Collapse>
              {/* <ListItemButton
                style={{
                  backgroundColor: employerTab === "report" ? logoRedColor : "",
                  color: employerTab === "report" ? logoRedColor : "",
                }}
                onClick={() => {
                  setEmployerTab("report");
                }}>
                <ListItemIcon
                  style={{
                    color: employerTab === "report" ? logoRedColor : "",
                  }}>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Report" />
              </ListItemButton> */}
              <ListItemButton
                onClick={handleLogout}
                style={{
                  // backgroundColor: employerTab === "logout" ? logoRedColor : "",
                  color: employerTab === "logout" ? logoRedColor : "",
                }}>
                <Tooltip placement="right-start" title="Logout">
                  <ListItemIcon
                    style={{
                      color: employerTab === "logout" ? logoRedColor : "",
                    }}>
                    <LogoutIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </div>
        </div>
      </Drawer >
      {
        openPlanDialog && (
          <PlanSelectModal />
        )
      }
    </>
  );
};

export default JobPostSideBar;
