/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Hidden, Paper } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../Context/context";
import { employerSetupData, employerSetupData2 } from "../api/home";
import JobPostInfo from "./Jobpost/JobPostInfo";
import JobPostSideBar from "./Jobpost/JobPostSideBar";
import CandidateProfile from "./Profile";
import SEOData from "./SEOData";


export const backgroundLightColor = "#f8faff";

const PostJob = () => {
    const {
        setAdditionalPerksOption,
        setDepartmentDataOption,
        setIndustryDataOption,
        setJobRoleDataOption,
        setJobTypeDataOption,
        setJoiningFeeTypeOption,
        setNoOfEmployeeOption,
        setPayTypeOption,
        setRoleDataOption,
        setToken,
        setAssestsOption,
        setLanguageOption,
        setEducationOption,
        setEnglishLevelOption,
        setSkillOption,
        activeStep,
        employerTab
    } = useContext(Context);

    const navigate = useNavigate();
    const location = useLocation();


    const isBrowser = () => typeof window !== "undefined"; //The approach recommended by Next.js

    function scrollToTop() {
        window.scrollTo({ top: 0 });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        scrollToTop();
    }, [activeStep]);



    const renderComponenets = () => {
        if (location.pathname === "/profile") {
            return <CandidateProfile />;
        } else if (location.pathname === "/manage-coins") {
            return;
        } else {
            return (
                <Paper
                    style={{
                        background: employerTab === "dashboard" ? "#F6F6F5" : "white",
                        minHeight: "-webkit-fill-available",
                        margin: "8px",
                        padding: "10px",
                        boxShadow: employerTab === "dashboard" ? "none" : "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    }}>
                    <JobPostInfo />
                </Paper>
            );
        }
    };

    useEffect(() => {
        employerSetupData2()
            .then((res) => {
                setAssestsOption(res?.data?.data?.asset);
                setLanguageOption(res?.data?.data?.language_data);
                setEducationOption(res?.data?.data?.education_data);
                setEnglishLevelOption(res?.data?.data?.english_speaking_level_data);
                setSkillOption(res?.data?.data?.skill_data);
                setToken(res?.data?.data?.token);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        employerSetupData()
            .then((res) => {
                setDepartmentDataOption(res?.data?.data?.department_data);
                setAdditionalPerksOption(res?.data?.data?.additional_perks);
                setIndustryDataOption(res?.data?.data?.industry_data);
                setJobRoleDataOption(res?.data?.data?.job_role_data);
                setJobTypeDataOption(res?.data?.data?.job_type);
                setJoiningFeeTypeOption(res?.data?.data?.joining_fee_type);
                setNoOfEmployeeOption(res?.data?.data?.number_of_employees);
                setPayTypeOption(res?.data?.data?.pay_type);
                setRoleDataOption(res?.data?.data?.role_data);
                setToken(res?.data?.data?.token);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (localStorage.getItem("employerToken") === null) {
            navigate("/employer/login");
        }
        // if (
        //   JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === false &&
        //   JSON.parse(localStorage.getItem("is_profile_created")) === 0
        // ) {
        //   navigate("/employer/login");
        //   setLogin({
        //     ...login,
        //     detailsPage: false,
        //     setPhone: true,
        //   });
        // } else {
        //   if (
        //     JSON.parse(localStorage.getItem("is_profile_created")) === 0 &&
        //     JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true
        //   ) {
        //     navigate("/employer/login");
        //     setLogin({
        //       ...login,
        //       detailsPage: true,
        //       setPhone: false,
        //     });
        //   }
        // }
    }, []);

    return (
        <>
            <SEOData keyword="Job Post" />
            <Box
                style={{
                    display: "flex",
                    overflow: "hidden",
                    height: "100%",
                    color: "white",
                    gap: "1px",
                    backgroundColor: "#F1F1F1",
                }}>
                <Hidden mdDown>
                    <Box>
                        <Paper style={{
                            height: "100%",

                        }}>
                            <JobPostSideBar />
                        </Paper>
                    </Box>
                </Hidden>
                <Box

                    style={{ height: "100vh", flexGrow: 1, overflow: "auto", width: "100%" }}>
                    {renderComponenets()}
                </Box>
            </Box>
        </>
    );
};

export default PostJob;
