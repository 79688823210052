import { Button, Drawer, IconButton, List, ListItemButton } from '@mui/material';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleDownloadApp, logoRedColor } from './Header';
import { Context } from '../Context/context';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const SidebarMain = ({ open, setOpenSidebar }) => {
    const navigate = useNavigate()
    const location = useLocation();


    const handleDrawerClose = () => {
        setOpenSidebar(false)
    }

    const {
        setIsLoginPageOpen,
        login,
        setLogin,
    } = React.useContext(Context);


    const handleEmployerLogin = () => {
        let token = localStorage.getItem("employerToken") ? true : false;
        if (
            (JSON.parse(localStorage.getItem("is_profile_created")) === 1 ||
                JSON.parse(localStorage.getItem("userData")) === true) &&
            JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true &&
            token
        ) {
            localStorage.setItem("welcomeMessage", true);
            navigate("/postjob");
        } else {
            if (JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true) {
                setLogin({
                    ...login,
                    detailsPage: true,
                    setPhone: false,
                });
                navigate("/employer/login");
            } else {
                setLogin({
                    ...login,
                    detailsPage: false,
                    setPhone: true,
                });
                navigate("/employer/login");
                setIsLoginPageOpen(true);
            }
        }
    };
    return (
        <Drawer open={open} anchor="right" sx={{ width: "70%" }}>
            <DrawerHeader>
                {open &&
                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon />
                    </IconButton>
                }
            </DrawerHeader>
            <List
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader">
                <ListItemButton>
                    {location.pathname === "/employer" && (
                        <div className="block-signin" style={{ display: "flex" }}>
                            <button
                                onClick={handleEmployerLogin}
                                style={{
                                    width: "100%", border: "none",
                                    color: "white",
                                    backgroundColor: logoRedColor,
                                    borderColor: logoRedColor,
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    padding: "10px 20px"
                                }}
                                className=" hover-up"
                            >
                                Employer Login
                            </button>
                        </div>
                    )}

                    {
                        location.pathname === "/" && (
                            <button variant="contained" onClick={() => {
                                handleDownloadApp(
                                    "https://play.google.com/store/apps/details?id=com.kindjobs"
                                )
                                setOpenSidebar(false)
                            }

                            } className="buttonCustom" style={{
                                fontSize: "20px", margin: "5px 15px", width: "200px"
                            }}>
                                Find a Job
                            </button>
                        )
                    }

                </ListItemButton>
                <ListItemButton>
                    {
                        location.pathname === "/" && (
                            <button onClick={() => {
                                navigate("/employer");
                                setOpenSidebar(false)
                            }} variant="contained" className="buttonCustom" style={{
                                fontSize: "20px", margin: "5px 15px", width: "200px"
                            }}>
                                Find a Talent
                            </button>
                        )
                    }

                </ListItemButton>
                <ListItemButton
                    style={{
                        margin: "5px 15px",
                        fontSize: "19px",
                        fontWeight: "bold"
                    }}
                    onClick={() => {
                        navigate("/about")
                        setOpenSidebar(false)
                    }}>
                    About Us
                </ListItemButton>
                <ListItemButton
                    style={{
                        margin: "5px 15px",
                        fontSize: "19px",
                        fontWeight: "bold"
                    }}
                    onClick={() => {
                        navigate("/contact")
                        setOpenSidebar(false)
                    }}>
                    Contact Us
                </ListItemButton>
            </List>
        </Drawer>
    )
}

export default SidebarMain