import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { getSEOData, getStatusData } from "../api/home";
import { Context } from "../Context/context";

const SEOData = ({ keyword }) => {
  const { seoData, setSeoData } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem("employerToken")) {
      getStatusData()
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const body = {
      page_slug: keyword,
    };
    getSEOData(body)
      .then((res) => {
        setSeoData(res?.data?.data?.seo_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={seoData?.description} />
        <meta name="keywords" content={seoData?.keyword} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-D55SFJ7E4E"></script>

        <script id="google-analytics">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-D55SFJ7E4E');
        `}
        </script>
      </Helmet>
    </>
  );
};

export default SEOData;
