/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/context";
import { getMainJobsApplyEmployeeDetails } from "../../api/home";
import { logoBlueColor } from "../Header";
import NoDataFound from "../NoDataFound";
import { errorAlert } from "./BasicDetailsForm";
import user from "../../assets/imgs/user.png"


export const getGenderFromId = (genderId) => {
  switch (genderId) {
    case 1:
      return "Female";
    case 2:
      return "Male";
    case 3:
      return "Both";
    default:
      return "None";
  }
};

const CandidateDetails = ({
  isOpenSelectedCandidate,
  setIsOpenSelectedCandidate,
  selectedCandidate,
}) => {
  const [candidateDetails, setCandidateDetails] = useState({});
  const { setAlertMessage, setLogin, login } =
    useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    getMainJobsApplyEmployeeDetails(selectedCandidate?.user?.user_id)
      .then((res) => {
        console.log(res);
        setCandidateDetails(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        errorAlert(setAlertMessage, err, navigate, setLogin, login);
      });
  }, []);

  // useEffect(() => {
  //   return () =>{
  //     setViewCandidateList(false)
  //   }
  // } ,[])

  const printValue = (value) => {
    return value === "" || value === "null" || value === null ? "-" : value;
  };

  //9904157241

  return (
    <Dialog
      open={isOpenSelectedCandidate}
      scroll="paper"
      maxWidth="xl"
      fullWidth={true}
      onClose={() => setIsOpenSelectedCandidate(false)}>
      <DialogContent sx={{ position: "relative" }}>
        <IconButton
          style={{ position: "absolute", right: "10px", top: "10px" }}
          edge="start"
          color="inherit"
          onClick={() => setIsOpenSelectedCandidate(false)}
          aria-label="close">
          <CloseIcon />
        </IconButton>
        <div className="row">
          <h5 className="border-bottom pb-15 mb-30">Employee Information</h5>
          <div className="col-md-12">
            <div className="job-overview" style={{ marginBottom: "25px" }}>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justify: "center",
                }}>
                <div
                  className="col-md-3"
                  style={{ width: "15%", marginRight: "30px" }}>
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = user;
                    }}
                    src={
                      candidateDetails?.user_data?.profile_image
                        ? candidateDetails?.user_data?.profile_image
                        : user
                    }
                    alt="user"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="sidebar-text-info ">
                        <span className="text_description_for_candidate_details industry-icon ">
                          Full Name
                        </span>
                        <strong className="small-heading">{`${printValue(
                          candidateDetails?.user_data?.first_name
                        )} ${printValue(
                          candidateDetails?.user_data?.last_name
                        )}`}</strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details joblevel-icon ">
                          {getGenderFromId(candidateDetails?.user_data?.gender)}
                        </span>
                        <strong className="small-heading">Male</strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info ">
                        <span className="text_description_for_candidate_details salary-icon ">
                          Date of Birth
                        </span>
                        <strong className="small-heading">
                          {printValue(
                            moment(candidateDetails?.user_data?.dob).format(
                              "MM/DD/YYYY"
                            )
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="sidebar-text-info ">
                        <span className="text_description_for_candidate_details experience-icon ">
                          Email
                        </span>
                        <strong className="small-heading">
                          {printValue(candidateDetails?.user_data?.email)}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details ">
                          Address
                        </span>
                        <strong className="small-heading">
                          {printValue(candidateDetails?.user_data?.address)}
                        </strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info">
                        <span className=" text_description_for_candidate_details ">
                          Skill
                        </span>
                        <strong
                          className="small-heading"
                          style={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}>
                          {candidateDetails?.user_preference_data?.skills
                            ?.length > 0
                            ? candidateDetails?.user_preference_data?.skills?.map(
                              (skill) => {
                                return (
                                  <a className="btn-tags btn-grey-small">
                                    {skill}
                                  </a>
                                );
                              }
                            )
                            : "-"}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details ">
                          English Speaking Level
                        </span>
                        <strong className="small-heading">
                          {printValue(
                            candidateDetails?.user_preference_data
                              ?.english_speaking_level?.title
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details ">
                          Language
                        </span>
                        <strong
                          className="small-heading"
                          style={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}>
                          {candidateDetails?.user_preference_data?.language
                            ?.length > 0
                            ? candidateDetails?.user_preference_data?.language?.map(
                              (language) => {
                                return (
                                  <a className="btn-tags btn-grey-small">
                                    {language}
                                  </a>
                                );
                              }
                            )
                            : "-"}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details ">
                          Employment Type
                        </span>
                        <strong className="small-heading">
                          {candidateDetails?.user_preference_data
                            ?.preferred_employment_type?.length > 0
                            ? candidateDetails?.user_preference_data?.preferred_employment_type?.map(
                              (language) => {
                                return (
                                  <a className="btn-tags btn-grey-small">
                                    {language}
                                  </a>
                                );
                              }
                            )
                            : "-"}
                        </strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details ">
                          Work Place
                        </span>
                        <strong className="small-heading">
                          {candidateDetails?.user_preference_data
                            ?.preferred_work_place?.length > 0
                            ? candidateDetails?.user_preference_data?.preferred_work_place?.map(
                              (language) => {
                                return (
                                  <a className="btn-tags btn-grey-small">
                                    {language}
                                  </a>
                                );
                              }
                            )
                            : "-"}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-6 d-flex ">
                      <div className="sidebar-text-info">
                        <span className="text_description_for_candidate_details ">
                          Shift
                        </span>
                        <strong className="small-heading">
                          {candidateDetails?.user_preference_data
                            ?.preferred_shift?.length > 0
                            ? candidateDetails?.user_preference_data?.preferred_shift?.map(
                              (language) => {
                                return (
                                  <a className="btn-tags btn-grey-small">
                                    {language}
                                  </a>
                                );
                              }
                            )
                            : "-"}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className="border-bottom pb-15 mb-30">Education</h5>
            {candidateDetails?.user_education_data?.length > 0 ? (
              candidateDetails?.user_education_data?.map((education, index) => {
                return (
                  <div className="col-md-12" key={index}>
                    <div
                      className="job-overview"
                      style={{
                        marginBottom: "25px",
                        padding: "10px 20px 20px 20px",
                      }}>
                      <div className="row  mt-10">
                        <div className="col-md-6">
                          <div className="sidebar-text-info">
                            <span className="text_description_for_candidate_details ">
                              Education
                            </span>
                            <strong className="small-heading">
                              {printValue(education?.education_title)}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="sidebar-text-info">
                            <span className="text_description_for_candidate_details ">
                              University
                            </span>
                            <strong className="small-heading">
                              {printValue(education?.collage_name)}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row  mt-10">
                        <div className="col-md-6">
                          <div className="sidebar-text-info">
                            <span className="text_description_for_candidate_details ">
                              Education Type
                            </span>
                            <strong className="small-heading">
                              {printValue(education?.education_type)}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="sidebar-text-info">
                            <span className="text_description_for_candidate_details ">
                              Specialization
                            </span>
                            <strong className="small-heading">
                              {printValue(education?.specialization_title)}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row  mt-10">
                        <div className="col-md-6">
                          <div className="sidebar-text-info">
                            <span className="text_description_for_candidate_details ">
                              Degree
                            </span>
                            <strong className="small-heading">
                              {printValue(education?.degree_title)}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="sidebar-text-info">
                            <span className="text_description_for_candidate_details ">
                              Completion Date
                            </span>
                            <strong className="small-heading">
                              {printValue(
                                moment(
                                  education?.completion_expected_date
                                ).format("MM/DD/YYYY")
                              )}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoDataFound noDataMessage="No Data Found" />
            )}
          </div>
          <div>
            <h5 className="border-bottom pb-15 mb-30">Experience</h5>
            {candidateDetails?.user_work_experiance_data?.length > 0 ? (
              candidateDetails?.user_work_experiance_data?.map(
                (work, index) => {
                  return (
                    <div className="col-md-12" key={index}>
                      <div
                        className="job-overview"
                        style={{
                          marginBottom: "25px",
                          padding: "10px 20px 20px 20px",
                        }}>
                        <div className="row  mt-10">
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Company Name
                              </span>
                              <strong className="small-heading">
                                {printValue(work?.company_name)}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Designation
                              </span>
                              <strong className="small-heading">
                                {printValue(work?.job_title)}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="row  mt-10">
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Department Name
                              </span>
                              <strong className="small-heading">
                                {printValue(work?.department_name)}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Department Title
                              </span>
                              <strong className="small-heading">
                                {printValue(work?.department_title)}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="row  mt-10">
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Industry Name
                              </span>
                              <strong className="small-heading">
                                {printValue(work?.industry_title)}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Employment Type
                              </span>
                              <strong className="small-heading">
                                {printValue(work?.employment_type)}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="row  mt-10">
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                Start Date
                              </span>
                              <strong className="small-heading">
                                {printValue(
                                  moment(work?.start_date).format("MM/DD/YYYY")
                                )}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sidebar-text-info">
                              <span className="text_description_for_candidate_details ">
                                End Date
                              </span>
                              <strong className="small-heading">
                                {printValue(
                                  moment(work?.end_date).format("MM/DD/YYYY")
                                )}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <NoDataFound noDataMessage="No Data Found" />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="btn btn-apply-now"
              style={{ backgroundColor: logoBlueColor, color: "white" }}
              onClick={() => {
                setIsOpenSelectedCandidate(false);
              }}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CandidateDetails;
