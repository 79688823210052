import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import About from "./components/AboutUs";
import EmployerLogin from "./components/Auth/login";
import EmployerHomePage from "./components/EmployerHomePage";
import Home from "./components/Home";
import PostJob from "./components/PostJob";
import TermsAndConditions from "./components/TermsAndConditions";
import TermsAndService from "./components/TermsAndService";
import Contact from "./components/contactUs";
import PrivacyPolicy from "./components/privacy";
import Pricing from "./components/Pricing";
import RefundPolicy from "./components/RefundPolicy";

const Routess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Check for trailing slash and redirect if needed
  useEffect(() => {
    if (location.pathname.endsWith("/") && location.pathname !== "/") {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/termsService" element={<TermsAndService />} />
      <Route path="/employer" element={<EmployerHomePage />} />
      <Route path="/postjob" element={<PostJob />} />
      <Route path="/employer/login" element={<EmployerLogin />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
};

export default Routess;
