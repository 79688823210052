import React, { useEffect } from "react";
import Layout from "./Layout";
import SEOData from "./SEOData";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <Layout>
            <SEOData keyword="Privacy Policy" />
            <section className="section-box">
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h2 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Privacy Policy
                        </h2>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            The following is the Privacy Policy for Kind Jobs (KJ), a
                            commercial app developed by Searchline Database Private Limited
                            ("SDPL"). This page aims to inform users about the collection,
                            use, and disclosure of Personal Information when using Kind Jobs
                            Platform. By using the app and website of Kind Jobs, users agree
                            to the terms of this Privacy Policy and the Terms of Service.
                            Users are advised to keep themselves updated with any changes made
                            to this policy.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Personal Identification Information
                        </h4>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Personal Identification Information collected through Kind Jobs is
                            used to provide and improve the service, and will not be shared
                            with third parties. The information provided on users' public
                            profiles, including their name, location, gender, profile picture,
                            education and professional information, is publicly accessible and
                            may be associated with them on the internet. Kind Jobs uses this
                            information to help connect users with job opportunities and their
                            friends and family.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Personal Identification Information may be collected from various
                            sources, including Truecaller, Facebook, LinkedIn, Twitter, and
                            Google, to identify users for communication and personalization of
                            services. Non-personal identification information, such as the
                            type of mobile phone and technical information about users, may
                            also be collected.
                        </p>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Usage and technical information, such as IP address, geographical
                            location, browser type, referral source, length of visit, pages
                            viewed, and items clicked, are also collected to understand how
                            users interact with Kind Jobs.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Non-personal Identification Information
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We may collect non-personal identification information from users
                            when they use our website, such as their phone type, operating
                            system, and Internet service provider, including their IP address.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Usage and Technical Information
                        </h4>
                        <p className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We also collect information about how users interact with our
                            Service, such as their geographic location, browser type, referral
                            source, length of visit, pages viewed, and items clicked.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Information Collection
                        </h4>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            To ensure a better user experience, we require users to provide
                            certain personally identifiable information when creating a Public
                            Profile. This information includes identity information (such as
                            first name, last name, gender, date of birth, and username),
                            contact information (such as phone number, email address, and
                            postal address), professional information (such as education, work
                            experience, skills, salary, photo, city, area, and other relevant
                            details), feedback and correspondence, usage information
                            (including how users interact with the Service), and marketing
                            information (including preferences for receiving marketing
                            communications and engagement details).
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            It's important to note that users should be cautious when adding
                            personal data to their resume, as it may become publicly
                            available. Additionally, when users use the services of our
                            customers and partners (such as employers or applicant tracking
                            systems), we share their Public Profile (including job title and
                            company name) to enable them to get job interviews. As always, we
                            are committed to being transparent about the data we collect, how
                            it is used, and with whom it is shared. Kind Jobs Platform’s
                            mission is to connect talent to job opportunities and employers to
                            quality talent. We are committed to be transparent about the data
                            we collect about you, how it is used and with whom it is shared.
                        </p>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            When you use the services of our customers and partners, such as
                            employers or prospective employers and applicant tracking systems,
                            we share your Public Profile (e.g., your job title and name of the
                            company where you work) with prospective employers to enable you
                            to get job interviews.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Information we collect
                        </h4>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We collect several types of information from users when they use
                            our Services. If users choose to sync their contacts with our
                            Services, we will import and store their contacts list on our
                            servers. However, users have the option to deny us access to their
                            contacts list. Additionally, we may receive personal data
                            (including contact information) about users when others import or
                            sync their contacts with our Services.
                        </p>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Users can give us permission to access location information, which
                            we derive from their GPS. We may use third-party cookies and
                            similar technologies to collect some of this information. If users
                            opt-in for storage permission, we will access their device storage
                            (such as gallery photos). Granting us camera permission allows us
                            to access photos that users take, which they can display on their
                            resume. Finally, we may collect voice and audio information when
                            users use audio features, such as the microphone. We use this
                            information to enhance the user experience and provide our
                            Services.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            How we use it
                        </h4>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We use the information we collect in various ways to provide users
                            with an enhanced experience on our Services. For example, we use
                            the contacts list to suggest connections for users and their
                            friends, helping them grow their network. The location information
                            is used to provide location-based services, such as finding jobs
                            near the user.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            The storage permission allows users to select a profile picture
                            from their existing photos in the gallery, which can help them
                            stand out among other candidates when applying for jobs.
                            Similarly, the camera permission allows users to click a profile
                            picture that can be used on their resume.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We collect audio information through the microphone to allow users
                            to send audio messages within the app. Finally, we use the call
                            permission to enable the Call HR feature of the app, which helps
                            users connect with employers and hiring managers.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            To effectively introduce new services to users, we collect and use
                            certain information, such as:
                        </p>
                        <ul className="list-unstyled">
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Using email IDs to send updates on job opportunities.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Logging user visits and usage patterns of our Services.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Receiving data from users' devices and networks, including
                                location information.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Requesting and storing additional information, if required.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Monitoring usage or traffic patterns, tracking users' movements
                                around the Services, and gathering demographic data.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Communicating directly with users, including sending information
                                about new products and services.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Using email IDs to send updates on job opportunities.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Using email IDs to send updates on job opportunities.
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Delivering a personalized experience, such as tailored messages
                                or ads based on users' interests and browsing history.
                            </li>
                        </ul>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            To comply with legal and regulatory obligations and internal
                            policies, we may record and monitor user communications with us,
                            including telephone conversations, to the extent permitted by
                            applicable law.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            How we protect your information?
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            The security and protection of your personal information are of
                            utmost importance to us, and we take necessary steps to ensure
                            that your data is kept safe. We use appropriate data collection,
                            storage, and processing practices, as well as security measures,
                            to prevent unauthorized access, alteration, disclosure, or
                            destruction of your personal information, username, password,
                            transaction information, and data stored on our app. We store your
                            data securely on our servers, which can be accessed at{" "}
                            <a
                                style={{ textDecoration: "underline" }}
                                href="https://kindjobs.co"
                                target="_blank" rel="noreferrer">
                                https://kindjobs.co
                            </a>
                            .
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Sharing your personal information
                        </h4>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            The app does not sell, trade or rent your personal information to
                            any third party. However, we may share generic demographic
                            information with our business partners, trusted affiliates and
                            advertisers to help us provide better services to you. We also use
                            third-party services that may collect information that can
                            identify you. We ensure that these services are trustworthy and
                            comply with our privacy policy before sharing any information with
                            them.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Link to privacy policy of third-party service providers used by
                            the app:
                        </p>
                        <ul className="list-unstyled">
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://policies.google.com/privacy"
                                    target="_blank" rel="noreferrer">
                                    Google Play Services
                                </a>
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://firebase.google.com/"
                                    target="_blank" rel="noreferrer">
                                    Firebase Analytics
                                </a>
                            </li>
                            {/* <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://firebase.google.com/support/privacy/"
                  target="_blank"  rel="noreferrer">
                  Fabric
                </a>
              </li> */}
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://mixpanel.com/legal/privacy-policy/"
                                    target="_blank" rel="noreferrer">
                                    Mixpanel
                                </a>
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://clevertap.com/privacy-policy/"
                                    target="_blank" rel="noreferrer">
                                    CleverTap
                                </a>
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://help.uxcam.com/hc/en-us/articles/360004158171-UXCam-Privacy-Statement"
                                    target="_blank" rel="noreferrer">
                                    UXCam
                                </a>
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://branch.io/policies/"
                                    target="_blank" rel="noreferrer">
                                    Branch
                                </a>
                            </li>
                            <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                <a
                                    style={{ textDecoration: "underline" }}
                                    href="https://www.applozic.com/privacy"
                                    target="_blank" rel="noreferrer">
                                    Applozic
                                </a>
                            </li>
                        </ul>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            The personal information that you provide us with is kept
                            confidential and is not shared with any third party unless
                            specified in our Privacy Policy. We may share your personal
                            information with our affiliates and group companies for internal
                            business and administrative purposes. We may also share your
                            information with companies if you have posted jobs on our platform
                            on their behalf, or with candidates who have applied to your job
                            postings if their resume matches your job requirements.
                            Additionally, we may share your information with potential
                            recruiters or job posters if your resume matches their job
                            descriptions.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            In certain circumstances, we may be required to disclose your
                            personal information to enforcement, regulatory, and judicial
                            authorities if we determine that it is necessary to respond to
                            court orders or legal processes, establish or exercise our legal
                            rights, defend against legal claims, investigate illegal
                            activities, prevent fraud or potential threats, or as otherwise
                            required by law.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            In case of a merger, acquisition, financing, or sale of assets, we
                            may disclose your personal information to the parties involved in
                            the negotiation or transfer. We may also engage third-party
                            service providers and marketing partners to provide services on
                            our behalf or communicate with you about offers relating to our
                            products and/or services. We ensure that these third-party service
                            providers are obligated to protect your personal information and
                            are subject to appropriate confidentiality and non-disclosure
                            obligations.
                        </p>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            As a user of Kind Jobs Platform, you are bound by the terms and
                            conditions set forth in the privacy policy. This includes the
                            agreement that you will use any information shared with you
                            through the platform only for the purpose of the Services and not
                            for any personal or other business purposes. Kind Jobs may take
                            action, such as deleting your account and blocking you from
                            accessing the platform, if you are found to be misusing the
                            information shared with you.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Log Data
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Log Data is a standard practice for mobile applications and is
                            used to analyze trends, administer the service, track users'
                            movements around the app, and gather demographic information. We
                            use third-party services such as Google Analytics that collect,
                            monitor and analyze this data to improve our services and user
                            experience. This information may also be used to customize the
                            content and advertisements you see while using our services.
                            However, we do not link this information to any personal
                            information that can identify an individual user.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Cookies
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            "Cookies" are small data files that act as unique identifiers and
                            are commonly used anonymously. They are sent to your device from
                            websites you visit and stored on your internal memory. Our service
                            doesn't explicitly use cookies, but third-party code and libraries
                            we use might. You can choose to accept or reject these cookies,
                            and if you reject them, you may not be able to use certain parts
                            of our service.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Service Providers
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We may enlist the help of third-party entities and individuals for
                            various reasons such as facilitating, providing, and analyzing the
                            usage of our Service or carrying out services related to our
                            Service. Please note that these third parties may have access to
                            your Personal Information solely for the purpose of completing the
                            tasks assigned to them. However, they are required to maintain
                            confidentiality and not disclose or utilize your information for
                            any other purposes.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            One-to-one messages
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            WTo ensure trust and safety within the app, Kind Jobs reserves the
                            right to access and review the one-to-one messages exchanged
                            between users. This data is periodically reviewed for moderation
                            purposes, but it is never shared with any third-party.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Security
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            We understand the importance of safeguarding your Personal
                            Information and we endeavor to use commercially acceptable
                            measures to protect it. Nevertheless, we cannot guarantee that any
                            method of transmission over the Internet or electronic storage is
                            completely secure and reliable, and therefore we cannot guarantee
                            the absolute security of your information.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Links to Other Sites
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            This Service may include links to external websites that are not
                            operated by us. We cannot be held responsible for the privacy
                            practices, content or reliability of any third-party websites that
                            are linked to from our Service. We encourage you to review the
                            privacy policy of any such website you visit. Your use of and
                            reliance on any third-party website is at your own risk.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Children's Privacy
                        </h4>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Our Services are not intended for individuals under the age of 13.
                            We do not knowingly collect any personally identifiable
                            information from children under the age of 13. If we become aware
                            that we have collected personal information from a child under the
                            age of 13 without parental consent, we will take immediate steps
                            to delete it from our servers. If you are a parent or guardian and
                            believe that your child has provided us with personal information,
                            please contact us immediately so that we can take appropriate
                            action.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            No Impersonation or False Information to be Provided
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            To use our Services, you are required to provide your actual name
                            and correct phone number. It is important that you do not
                            impersonate or falsely represent yourself as another person or
                            representative of another person on the Kind Jobs Platform.
                            Additionally, you must not provide false information about
                            yourself for any reason. Please ensure that the information you
                            provide on the Platform is accurate and truthful.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Trust and Safety
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Kind Jobs places a high priority on ensuring the trust and safety
                            of all its users. We have a zero-tolerance policy towards content
                            that promotes abuse, fraud, MLM/network marketing, job openings
                            that charge fees, suicide, self-harm, or content that is intended
                            to shock or disgust users. We take strict action against such
                            content and those who post it on the platform. To maintain a
                            positive user experience, we have established Community Guidelines
                            that all users are expected to follow. These guidelines are
                            periodically updated, and users are notified when changes are
                            made.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Disclaimer
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            Kind Jobs makes it clear that it is not responsible for any
                            incidents, frauds, cheats, or crimes that may happen to any users
                            of the app. It advises users to verify the information of other
                            users before proceeding with any transactions or interactions.
                            Additionally, Kind Jobs advises users not to share their private
                            information in the group to prevent it from being used for unsafe
                            purposes. If a user sees any inappropriate or offending content,
                            they can report it by clicking on the three vertical dots on the
                            top right corner of the post. In case a user comes across content
                            related to suicide or self-injury, they should report the post
                            immediately to alert the admins about it and allow for necessary
                            action to be taken.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Changes to This Privacy Policy
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            If there are any changes made to the Privacy Policy of Kind Jobs,
                            it will be updated and you are advised to review it periodically
                            for any changes. Any changes made to the policy will be posted on
                            this page and will become effective immediately after they are
                            posted. Kind Jobs will also notify you of any changes made to the
                            Privacy Policy.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Contact Us
                        </h4>
                        <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            If you have any questions or suggestions about our Privacy Policy,
                            do not hesitate to contact us at:
                            <br />
                            <br />
                            Searchline Database Private Limited 5th Floor, A/502, Signature 2,
                            Sarkhej Sanand Highway, Sarkhej, Ahmedabad, Maharashtra, 382210
                            <br /> Email: admin@kindjobs.co
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default PrivacyPolicy;
