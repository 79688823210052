/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Grid, Pagination } from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../Context/context';
import { getMainJobs } from '../../api/home';
import { logoRedColor } from '../Header';
import NoDataFound from '../NoDataFound';
import { errorAlert } from './BasicDetailsForm';
import CandidateList from './CandidateList';
import JobDetails from './JobDetails';

const JobList = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [selectedJobId, setSelectedJobId] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);
    const [jobTitle, setJobTitle] = useState("")

    const {
        getsJob,
        setGetsJob,
        setEmployerTab,
        setActiveStep,
        isJobDetailsOpen,
        setIsJobDetailsOpen,
        setViewCandidateList,
        viewCandidateList,
        setAlertMessage,
        setLogin,
        login,
        setPostJobId,
    } = useContext(Context);

    const getMainPostJobs = () => {
        const body = {
            search: search,
            page_no: activePage,
            page_record: 8,
        };
        getMainJobs(body)
            .then((res) => {
                setGetsJob(res?.data?.data?.jobs_data?.rows);
                setCount(res?.data?.data?.jobs_data?.count);
            })
            .catch((err) => {
                console.log(err);
                errorAlert(setAlertMessage, err, navigate, setLogin, login);
            });
    };

    useEffect(() => {
        getMainPostJobs();
    }, [search, activePage]);



    const getSelectedJob = (jobId) => {
        setSelectedJobId(jobId);
        setIsJobDetailsOpen(true);

    };

    useEffect(() => {
        setIsJobDetailsOpen(false);
        setViewCandidateList(false);
    }, []);

    useEffect(() => {
        return () => {
            setIsJobDetailsOpen(false);
        };
    }, []);

    const handleShowCandidate = (job) => {
        setIsJobDetailsOpen(false);
        setViewCandidateList(true);
        getSelectedJob(job?.main_job_id)
        setJobTitle(job?.job_title)
        console.log("candidate")
    }


    const JobListComponents = () => {
        return (
            <Grid container sx={{
                width: "100%", height: "100%",
                padding: "10px"
            }} spacing={getsJob?.length > 0 ? 2 : 0} >
                {
                    getsJob?.length > 0 ?
                        getsJob?.map((job, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Card style={{ backgroundColor: "#F1F1F1", padding: "15px" }} >
                                        <Grid container >
                                            <Grid item xs={8} sm={8} md={10} lg={10} style={{ borderRight: "1px dotted gray" }}>
                                                <Grid container direction={"column"} spacing={1} >
                                                    <Grid item>
                                                        <Grid container spacing={2} alignItems={"center"}>
                                                            <Grid item
                                                            >
                                                                <span style={{
                                                                    fontWeight: "500"

                                                                }}>{job?.job_title}  </span>
                                                            </Grid>
                                                            <Grid item>
                                                                <div
                                                                    onClick={
                                                                        job?.is_active === 0
                                                                            ? () => {
                                                                                setActiveStep(3);
                                                                                setPostJobId(job?.main_job_id);
                                                                                setEmployerTab("postJob");
                                                                            }
                                                                            : null
                                                                    }
                                                                    // className={
                                                                    //     job?.is_active === 1
                                                                    //         ? 
                                                                    //         : "btn btn-pending-small"
                                                                    // }
                                                                    style={{
                                                                        // height: "25px",
                                                                        color: job?.is_active === 1 ? "black" : (job?.is_active === 2) ? "white" : "black",
                                                                        fontWeight: "bold",
                                                                        width: "auto",
                                                                        backgroundColor: job?.is_active === 1 ? "#6ccca5" : (job?.is_active === 2) ? "#17a2b8" : "#ffde00",
                                                                        fontSize: "14px",
                                                                        borderRadius: "30px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        padding: "0px 10px",
                                                                        cursor: job?.is_active !== 1 && "pointer"
                                                                    }}>
                                                                    {job?.is_active === 1
                                                                        ? "Active"
                                                                        : (job?.is_active === 2) ? "In Process" : "Pending"}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container alignItems={"center"} spacing={1} style={{ fontSize: "14px" }}>
                                                            <Grid item >
                                                                {
                                                                    job?.job_location_area ? `${job?.job_location_area} ,` : ""
                                                                }
                                                                {`${job?.job_location_city
                                                                    ? job?.job_location_city
                                                                    : "Anywhere in India"
                                                                    }`}
                                                            </Grid>
                                                            <Grid item>
                                                                {"|"}
                                                            </Grid>
                                                            <Grid item>
                                                                Posted On: {moment(job?.date_time).format("DD-MM-YYYY")}
                                                            </Grid>
                                                            <Grid item style={{ cursor: "pointer" }}>
                                                                <span onClick={() => {
                                                                    setSelectedJobId(job?.main_job_id);
                                                                    setIsJobDetailsOpen(true);
                                                                }} style={{
                                                                    color: logoRedColor,
                                                                    fontSize: "12px", fontWeight: "bold"
                                                                }}>Details</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={4} sm={4} md={2} lg={2}>
                                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                                    <Grid item style={{ cursor: "pointer" }} onClick={() => handleShowCandidate(job)}>
                                                        <Grid container direction={"column"} style={{ marginLeft: "20px" }}>
                                                            <Grid item

                                                                style={{ fontWeight: "bold", fontSize: "19px", }}>
                                                                {
                                                                    job?.job_candidates_counts
                                                                }
                                                            </Grid>
                                                            <Grid item style={{ fontWeight: "500", fontSize: "12px" }}>
                                                                Total Applications
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <Button variant='outlined' className="hover-up" >
                                                            Renew Now
                                                        </Button>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid >
                            )
                        }) :
                        <NoDataFound noDataMessage={"No Jobs Found"} />
                }
                {getsJob?.length > 0 ? (
                    <div
                        className="paginations"
                        style={{
                            bottom: 0,
                            backgroundColor: "white",
                            position: "sticky",
                            padding: "10px",
                            width: "100%"
                        }}>
                        <Pagination
                            count={Math.ceil(count / 8)}
                            page={activePage}
                            onChange={(e, pageNumber) => {
                                setActivePage(pageNumber);
                            }}
                        />
                    </div>
                ) : null}
            </Grid >
        )
    }

    const renderComponents = () => {
        if (viewCandidateList) {
            return <CandidateList selectedJobId={selectedJobId} jobTitle={jobTitle} />;
        } else if (isJobDetailsOpen) {
            return <JobDetails selectedJobId={selectedJobId} />;
        } else {
            return <JobListComponents />
        }
    }

    return (
        <div>
            {renderComponents()}
        </div>

    )
}

export default JobList