import React, { useEffect } from "react";
import Layout from "./Layout";
import SEOData from "./SEOData";

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <Layout>
            <SEOData keyword="Terms Conditions" />
            <section className="section-box">
                <div className="container" style={{ padding: "20px" }}>
                    <div className="text-left">
                        <h2 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
                            Terms & Conditions
                        </h2>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            You must take the time to read and understand the Terms and
                            Conditions before registering for our services. By registering,
                            you accept that you are entering into a contract with us based on
                            these Terms and Conditions. Visitors to KindJobs.com who do not
                            register to become a Member (Employer or Employee) similarly
                            affirm that they are bound by these Terms and Conditions each time
                            they access the KindJobs.com website. If you do not accept the
                            Terms and Conditions stated below, please refrain from using
                            KindJobs.com.
                        </p>
                        <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                            The use of the Website by an Employer or Employee shall be deemed
                            acceptance of and agreement to these terms only.
                        </p>
                        <ul
                            style={{
                                listStyle: "revert",
                                marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                            }}>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                The Website has been established to allow Employers to contact
                                Employees and to view their profiles detailing an Employee's
                                experience.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                We do not issue any experience certificates to our registered
                                members.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Any profile created showing political or illegal material would
                                not be accepted under any circumstances.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                KindJobs.com will take all reasonable precautions to keep the
                                details of Employers and Employees secure but will not be liable
                                for unauthorized access to the information provided by any party
                                whatsoever.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                The Members warrant that their e-mail and other contact
                                addresses are valid and up to date when using the Website.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Members agree not to impersonate any other person or entity or
                                to use a false name or a name that they have no authority to
                                use.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                Members acknowledge that KindJobs.com is not liable for any form
                                of loss or damage that may be suﬀered by a Member through the
                                use of the Website, including loss of data or information or any
                                kind of financial or physical loss or damage.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                KindJobs.com's privacy policy forms part of these Terms and
                                Conditions, and by agreeing to these Terms and Conditions, you
                                also give your consent to the manner in which we may handle your
                                personal data as detailed in that policy.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                The management reserves the right to modify the Terms and
                                Conditions at any time without any prior notification.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                These Terms will be subject to Indian Law and the jurisdiction
                                of Indian Courts. We do not cater to Placement Agencies and
                                consultancies.
                            </li>
                            <li className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                                KindJobs.com is not responsible if any candidate has committed a
                                crime/illegal activity at an employer's premises. The background
                                verification of candidates who are/will be hired is the
                                responsibility of the respective recruiter/recruiter's company.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default TermsAndConditions;
