import { Box } from "@mui/material";
import React from "react";
import noResult from "../assets/imgs/noResult.svg"

const NoDataFound = ({ noDataMessage }) => {
  return (
    <Box
      sx={{
        border: "2px solid #444444",
        borderRadius: "10px",
        height: "150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        color: "#444444",
        width: "100%",
        marginBottom: "20px"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          flexDirection: "column",
          // padding: "20px"#444444,
        }}>
        <Box>
          <img
            src={noResult}
            alt="noResult"
            width="50"
            height="50"
          />
        </Box>
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "12px",
          }}>
          {noDataMessage ? noDataMessage : "No Data Available"}
        </Box>

        {/* {isnoDataSubText && (
            <Box
              sx={{
                fontSize: "16px",
              }}>
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
                onClick={navigateTo}>
                {noDataNavigateText && noDataNavigateText}
              </span>{" "}
              {noDataDescription && noDataDescription}
            </Box>
          )} */}
      </Box>
    </Box>
  );
};

export default NoDataFound;
