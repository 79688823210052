import { Box, Card, CircularProgress, Grid, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/context";
import { getDashboardCounts } from "../api/home";
import image2 from "../assets/imgs/image2.png";
import image4 from "../assets/imgs/image4.png";
import image5 from "../assets/imgs/image5.png";
import image6 from "../assets/imgs/image6.png";
import image7 from "../assets/imgs/image7.png";
import CandidateByAge from "./Dashboard/CandidateByAge";
import CandidateByLocation from "./Dashboard/CandidateByLocation";
import LineChart from "./Dashboard/LineChart";
import MonthlyJobPostChart from "./Dashboard/MonthlyJobPostChart";
import { errorAlert } from "./Jobpost/BasicDetailsForm";

export const TileSmall = ({
  isLoading = false,
  title,
  value,
  time,
  Icon,
  img = true,
  showFighures = true,
  onClickLabel,
  figures,
  onClickCard,
  onClickIcon,
  styles: {
    rootStyles = {},
    content: { titleStyles = {}, figureStyles = {} } = {},
    textBackgroundColor,
    textColor,
  } = {},
}) => {
  // const classes = useStyles({ onClickLabel });
  const theme = useTheme();

  const getTitle = (value) => {
    return <div style={{ color: "white", fontSize: "1rem" }}>{value}</div>;
  };

  const nFormatter = (num, digits = 2) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  return (
    <Card
      className="dashboardCard"
      sx={{
        display: "flex",
        padding: "15px",
        // backgroundColor: "rgb(241,241,241)",
        gap: "13px",
        cursor: onClickCard ? "pointer" : "initial",
        boxShadow: "none",
      }}
      onClick={onClickCard}>
      <div
        style={{
          background: "sky",
        }}>
        {img ? (
          <img
            alt="icon"
            src={Icon}
            style={{ height: "40px", width: "40px" }}
          />
        ) : (
          <Icon style={{ height: "40px", width: "40px" }} />
        )}
      </div>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: 600,
          color: "black",
        }}>
        <Box
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "black",
          }}>
          {title}
        </Box>
        <Tooltip title={value > 1000 ? getTitle(value) : null}>
          <Box sx={{ fontWeight: "bold", fontSize: "16px", color: "#61677A" }}>
            {isLoading ? (
              <CircularProgress
                sx={{
                  color: "#c12f33",
                  height: "5px",
                  width: "5px",
                }}
              />
            ) : value > 1000 ? (
              nFormatter(value, 2)
            ) : (
              value
            )}
          </Box>
        </Tooltip>
      </Box>
    </Card>
  );
};

export default function Dashboard() {
  // const [state, setstate] = useState(null);
  const [loading, setloading] = useState(true);
  // const [reload, setReload] = useState(false);
  const [dashboardCount, setDashboardCount] = useState({});
  const navigate = useNavigate();

  const { setAlertMessage, setLogin, login, setEmployerTab, setToken } =
    useContext(Context);

  const IconBackColors = {
    rooms: "#8ACCFF",
    beds: "#FFC18A",
    patients: "#8AB1FF",
    consultants: "#FF9E8A",
    devices: "#4caf50",
    nfc: "#777777",
    users: "#F9D970",
    logs: "#FB7A7A",
    nurseCall: "#FBB479",
    discharge: "#99FE68",
    er: "#7FE9DE",
  };

  useEffect(() => {
    setloading(true);
    getDashboardCounts()
      .then((res) => {
        setDashboardCount(res.data.data);
        setToken(res.data.token);
        setloading(false);
        // localStorage.setItem("employerToken", res.data.token)
      })
      .catch((err) => {
        setloading(false);
        errorAlert(setAlertMessage, err, navigate, setLogin, login);
      });
  }, []);

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} sm={6} xl={3}>
            <TileSmall
              onClickCard={() => {
                setEmployerTab("jobs");
              }}
              img={true}
              title={"Total Jobs"}
              value={dashboardCount?.total_jobs || 0}
              time="This Week"
              leftTitle="Total Room"
              rightTitle="Active Room"
              Icon={image5}
              leftFigures={100}
              isLoading={loading}
              rightFigures={35}
              styles={{
                textColor: IconBackColors.rooms,
                rootStyles: {
                  height: "150px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sm={6} xl={3}>
            <TileSmall
              onClickCard={() => {
                setEmployerTab("jobs");
              }}
              title={"Active Jobs"}
              value={dashboardCount?.active_jobs || 0}
              time="This Week"
              leftTitle="Total Room"
              rightTitle="Active Room"
              Icon={image7}
              isLoading={loading}
              leftFigures={100}
              rightFigures={35}
              styles={{
                textColor: IconBackColors.rooms,
                rootStyles: {
                  height: "150px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sm={6} xl={3}>
            <TileSmall
              onClickCard={() => {
                setEmployerTab("jobs");
              }}
              title={"Expired Jobs"}
              value={dashboardCount?.expire_jobs || 0}
              time="This Week"
              leftTitle="Total Room"
              rightTitle="Active Room"
              Icon={image6}
              isLoading={loading}
              leftFigures={100}
              rightFigures={35}
              styles={{
                textColor: IconBackColors.rooms,
                rootStyles: {
                  height: "150px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sm={6} xl={3}>
            <TileSmall
              onClickCard={() => {
                setEmployerTab("manageCoins");
              }}
              title={"Total Coins"}
              value={dashboardCount?.total_coins || 0}
              time="This Week"
              leftTitle="Total Room"
              rightTitle="Active Room"
              Icon={image4}
              img={true}
              isLoading={loading}
              leftFigures={100}
              rightFigures={35}
              styles={{
                textColor: IconBackColors.rooms,
                rootStyles: {
                  height: "150px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sm={6} xl={3}>
            <TileSmall
              onClickCard={() => {
                setEmployerTab("manageCoins");
              }}
              title={"Total Payments"}
              value={dashboardCount?.total_payment || 0}
              time="This Week"
              leftTitle="Total Room"
              rightTitle="Active Room"
              Icon={image2}
              img={true}
              isLoading={loading}
              leftFigures={100}
              rightFigures={35}
              styles={{
                textColor: IconBackColors.rooms,
                rootStyles: {
                  height: "150px",
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <LineChart
              styles={{
                rootStyles: {
                  height: "150px",
                  width: "100%",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MonthlyJobPostChart
              styles={{
                rootStyles: {
                  height: "150px",
                  width: "100%",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CandidateByLocation
              styles={{
                height: "400px",
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <CandidateByAge
              styles={{
                rootStyles: {
                  height: "150px",
                  width: "100%",
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
