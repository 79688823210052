
import { Card } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import 'chart.js/auto';
import Chart from "react-apexcharts";
import { logoBlueColor, logoRedColor } from "../Header";


const MonthlyJobPostChart = ({
    styles: {
        rootStyles = {},
        content: { titleStyles = {}, figureStyles = {} } = {},
        textBackgroundColor,
        textColor,
    } = {},
}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const series = [{
        name: "New",
        data: [],

    },
    {
        name: "Quit",
        data: []
    }]

    const options = {
        colors: [logoRedColor, "gray"],
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
            offsetY: -10,
            offsetX: -15
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 6,
            colors: [logoRedColor, "gray"], // Marker color
            strokeWidth: 0,
            hover: {
                size: 8,
            },
        },
        stroke: {
            curve: 'straight',
            width: 3
        },
        title: {
            text: 'Monthly Jobs Posted',
            align: 'left',
            style: {
                fontSize: '18px',   // Set the desired font size
                fontFamily: 'Montserrat', // Set the desired font family
                fontWeight: 'bold',  // Set the desired font weight
                color: '#333',       // Set the desired font color
            },
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22'],
        }


    };

    return (
        <Card sx={{ ...rootStyles, backgroundColor: "white", width: "100%", height: "400px", padding: "20px", boxShadow: "none" }}>
            <Chart options={options} series={series} type="line" height={350} />
        </Card>
    );
};

export default MonthlyJobPostChart;
