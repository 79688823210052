import React, { useEffect } from "react";
import Layout from "./Layout";

const TermsAndService = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      {/* <SEOData keyword="Terms Conditions" /> */}
      <section className="section-box">
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h2 className="aboutUsTitle mb-25 wow animate__animated animate__fadeInUp">
              Employer Terms of Service
            </h2>
            <ul
              style={{
                listStyle: "none",
                marginLeft: window.innerWidth < 500 ? "0px" : "20px",
                paddingLeft: window.innerWidth < 500 ? "0px" : "20px",
              }}>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Usage of Kind Jobs</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The Kind Jobs platform (including any mobile-based applications,
                website, and web applications) is provided by Kind Jobs Inc.
                ("Kind Jobs") either directly or through its affiliates. By
                using the Kind Jobs platform, you consent to the terms of the
                Terms of Service as well as our Privacy Policy.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                As a Job Poster accessing the Kind Jobs platform, you are bound
                by these Terms of Service, as well as all other rules,
                regulations, and terms of use provided by Kind Jobs in relation
                to any services provided through the Kind Jobs platform ("Kind
                Jobs Services").
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs reserves the right to modify these Terms of Service,
                rules, regulations, and terms of use at any time by posting them
                on the Kind Jobs platform. Your use of the Kind Jobs platform
                constitutes your acceptance of any modified Terms of Service.
                Kind Jobs may also notify you of any changes or modifications to
                these terms by email or notifications in your account. If you do
                not accept the modified terms, you have the option to indicate
                your non- acceptance within the specified timeframe. If no such
                indication is made, you will be deemed to have accepted the
                modified terms.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Certain Kind Jobs Services may be subject to additional rules
                and regulations. In case of any inconsistency between these
                Terms of Service and the additional conditions, the additional
                conditions shall prevail. Kind Jobs may, at its sole discretion:
              </p>
              <ul
                style={{
                  listStyle: "revert",
                  marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                }}>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Restrict, suspend, or terminate a Job Poster's access to all
                  or part of the Kind Jobs platform or Kind Jobs Services
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Change, suspend, or discontinue all or part of the Kind Jobs
                  platform or Kind Jobs Services
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Reject, move, or remove any material submitted by a Job Poster
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Move or remove any content available on the Kind Jobs platform
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Deactivate or delete a Job Poster's account and all related
                  information and files
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Establish general practices and limits for the use of the Kind
                  Jobs platform
                </li>
                <li
                  style={{ marginBottom: "10px" }}
                  className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Assign its rights and liabilities under these Terms of Service
                  to any entity (with intimation to the Job Posters via email)
                </li>
              </ul>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If a Job Poster breaches these Terms of Service or is suspected
                of illegal or improper use of the Kind Jobs platform or Kind
                Jobs Services, Kind Jobs may, at its sole discretion and without
                notice, restrict, suspend, or terminate the Job Poster's access,
                deactivate or delete their account and related information,
                delete any posted content, and take necessary technical and
                legal steps.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If Kind Jobs charges a platform fee for any Kind Jobs Services,
                it shall promptly repay such fee in the event of suspension or
                removal of the Job Poster's account or Kind Jobs Services due to
                negligence or deficiency on the part of Kind Jobs, except in
                cases where the suspension or removal is due to the Job Poster's
                breach or inadequate performance, or circumstances beyond the
                reasonable control of Kind Jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                By accepting these Terms of Service, Job Posters consent to
                receiving communications such as announcements, administrative
                messages, and advertisements from Kind Jobs or its partners,
                licensors, or associates.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Participation</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                As a Job Poster, you can post jobs on the Kind Jobs platform,
                access and participate in Kind Jobs Services, view profiles of
                suitable candidates, and schedule interviews with potential
                candidates/employees.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                To post a job, you are required to provide accurate information
                about the job, including the role, part-time or full-time
                status, workdays, timings, salary range, number of openings,
                minimum education level, and required experience. While Kind
                Jobs does not tolerate discrimination, you have the option to
                specify if a job is gender-specific. By agreeing to these Terms
                of Service and posting a job, you undertake that all information
                provided is accurate, not misleading, and that you do not engage
                in discriminatory practices. Kind Jobs reserves the right to
                remove job postings or suspend your account in case of incorrect
                information or misrepresentations.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job applications by candidates on the Kind Jobs platform remain
                active for 60 days from the date of application. After 60 days,
                the applications are archived, and Job Posters no longer have
                access to the list of candidates or the archived applications.
                Candidates, however, may reapply to a job post (if still active)
                after the expiration of 60 days.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters are required to adhere to the Code of Conduct while
                posting jobs and using Kind Jobs Services.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Intellectual Property</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The intellectual property rights ("Intellectual Property
                Rights") in all software, content, and materials published on
                the Kind Jobs platform, including software, advertisements,
                photographs, graphics, images, logos, trademarks, copyrights,
                and other intellectual and proprietary rights, are owned by Kind
                Jobs, its affiliates, partners, licensors, and/or associates.
                Job Posters are not allowed to modify, publish, transmit,
                reproduce, create derivative works, distribute, publicly
                perform, publicly display, or exploit any of the materials or
                content on the Kind Jobs platform without express written
                permission from Kind Jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters are solely responsible for all the materials
                (including Job Posters' Content) they upload, post, or transmit
                through the Kind Jobs platform. Job Posters represent and
                warrant that they own all Intellectual Property Rights in their
                Job Posters' Content and that it does not infringe any
                third-party rights. Job Posters agree not to display or use the
                names, logos, trademarks, or other intellectual and proprietary
                rights of any third party on the Kind Jobs platform without
                written authorization. Job Posters indemnify and hold Kind Jobs,
                its directors, employees, affiliates, and assigns harmless
                against any claims, costs, damages, or litigation arising from
                third-party claims related to the display or use of such
                third-party intellectual property rights on the Kind Jobs
                platform.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                By posting Job Posters' Content on the Kind Jobs platform, Job
                Posters grant Kind Jobs and its affiliates, partners, licensors,
                and associates a worldwide, irrevocable, royalty-free, non-
                exclusive, sub-licensable license to use, reproduce, create
                derivative works, distribute, publicly perform, publicly
                display, transfer, transmit, and publish the Job Posters'
                Content for purposes such as displaying it on the Kind Jobs
                platform, distributing it to potential candidates, and storing
                it in a remote database accessible to end users for a charge.
                This license applies to all forms, mediums, and technologies.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                All names, logos, trademarks, copyrights, and other intellectual
                and proprietary rights on the Kind Jobs platform belong to their
                respective owners. Any claims or issues related to these rights
                should be directly addressed to the respective parties.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Third-Party Sites, Services, and Products</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The intellectual property rights ("Intellectual Property
                Rights") in all software, content, and materials published on
                the Kind Jobs platform, including software, advertisements,
                photographs, graphics, images, logos, trademarks, copyrights,
                and other intellectual and proprietary rights, are owned by Kind
                Jobs, its affiliates, partners, licensors, and/or associates.
                Job Posters are not allowed to modify, publish, transmit,
                reproduce, create derivative works, distribute, publicly
                perform, publicly display, or exploit any of the materials or
                content on the Kind Jobs platform without express written
                permission from Kind Jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The Kind Jobs platform may contain links to third-party sites or
                mobile applications. Job Posters' use of such sites is subject
                to the terms and conditions posted by those sites, and Kind Jobs
                does not control or endorse the content of those sites.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters' interactions with third parties, including
                potential candidates, payment providers, and verification
                service providers, are solely between the Job Poster and the
                third party. Job Posters are responsible for reviewing and
                complying with the terms and conditions, policies, and service
                terms of those third parties. Kind Jobs is not responsible for
                any loss or damage incurred in connection with such interactions
                or transactions. Any questions, complaints, or claims related to
                third-party products or services should be directed to the
                appropriate vendor.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The Kind Jobs platform contains content created by Kind Jobs as
                well as content provided by third parties, including potential
                candidates. Kind Jobs does not guarantee the accuracy,
                integrity, or quality of the third-party content, and Job
                Posters should not rely on it when utilizing Kind Jobs Services.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Participation</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Information collected from Job Posters, such as registration
                details and payment information, is subject to Kind Jobs'
                Privacy Policy, which can be found website. Kind Jobs does not
                share personal information with other companies/entities without
                permission, and Job Posters agree to use any shared information
                solely for the purpose of the Services. Job Posters are
                responsible for securing the personal information shared with
                them and must not disclose or transfer it to sub-processors
                without ensuring adequate safeguards. Kind Jobs reserves the
                right to delete the account and block future access for any Job
                Poster found misusing the shared information for personal or
                other purposes.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Job Poster Conduct</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree to comply with these Terms of Service and all
                other rules, regulations, and terms of use of the Kind Jobs
                website. Failure to adhere to these terms may result in
                necessary remedial action taken by Kind Jobs, at its sole and
                absolute discretion, including but not limited to:
              </p>
              <ul
                style={{
                  listStyle: "revert",
                  marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                }}>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Restricting, suspending, or terminating a Job Poster's access
                  to all or any part of the Kind Jobs services.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Deactivating or deleting a Job Poster's account and all
                  related information and files on the account. Any remaining
                  unused balance in the Job Poster's account, if applicable,
                  will be subject to a processing fee (if any) and transferred
                  to the Job Poster's designated bank account on record with
                  Kind Jobs.
                </li>
              </ul>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree to provide true, accurate, current, and
                complete information during registration and at all other times
                as required by Kind Jobs. Job Posters further agree to keep
                their registration information and information related to job
                posts up to date.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                A Job Poster is prohibited from registering or operating more
                than one Job Poster account with Kind Jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree to ensure that they can receive all
                communication from Kind Jobs by marking emails or SMS from Kind
                Jobs as not spam or junk. Kind Jobs shall not be held liable if
                any email/SMS remains unread by a Job Poster due to being
                delivered to the Job Poster's spam or junk folder.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters are responsible for maintaining the confidentiality
                of their accounts and passwords. Any password issued by Kind
                Jobs to a Job Poster must not be revealed to anyone else. Job
                Posters may not use anyone else's password. Job Posters agree to
                immediately notify Kind Jobs of any unauthorized use of their
                passwords, accounts, or any other security breach.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree to log out of their accounts at the end of
                each session. Kind Jobs shall not be responsible for any loss or
                damage that may occur if the Job Poster fails to comply with
                this requirement.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree not to use cheats, exploits, automation
                software, bots, hacks, or any unauthorized third-party software
                designed to modify or interfere with the Kind Jobs services or
                experience or assist in such activities.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree not to copy, modify, rent, lease, sell,
                assign, distribute, reverse engineer, grant a security interest
                in, or otherwise transfer any right to the technology or
                software underlying Kind Jobs or its services.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree not to disrupt, overburden, or assist in the
                disruption or overburdening of (a) any computer or server used
                to offer or support Kind Jobs or its services, or (b) the
                enjoyment of Kind Jobs services by any other Job Poster or
                person.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters agree not to engage in any action that threatens the
                unity, integrity, defense, security, or sovereignty of India,
                friendly relations with foreign states, public order, or causes
                incitement to the commission of any cognizable offense or
                prevents the investigation of any offense or is insulting to
                other nations.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If a Job Poster chooses a username that is deemed obscene,
                indecent, abusive, or might subject Kind Jobs to public
                disparagement or scorn, Kind Jobs reserves the right, without
                prior notice, to restrict the usage of such usernames, change
                the username, delete posts from Kind Jobs, deny access to Kind
                Jobs, or use any combination of these options.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Unauthorized access to the Kind Jobs platform is a violation of
                these Terms of Service and the law. Job Posters agree to access
                the Kind Jobs platform only through the provided interface and
                not to use any automated means, including agents, robots,
                scripts, or spiders, to access, monitor, or copy any part of the
                Kind Jobs platform, services, or information available through
                it, except those automated means approved in advance and in
                writing by Kind Jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The use of the Kind Jobs platform is subject to existing laws
                and legal processes. Kind Jobs reserves the right to comply with
                governmental, court, and law enforcement requests or
                requirements relating to Job Posters' use of Kind Jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Only individuals above the age of 18 years and currently
                residing in India are eligible to register and participate as
                Job Posters on the Kind Jobs platform. Job Posters must have a
                valid email address and/or mobile phone number.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Dispute and Dispute Resolution</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If any dispute arises out of or in connection with the Kind Jobs
                services provided by Kind Jobs via the Kind Jobs Platform, the
                construction, validity, interpretation, and enforceability of
                these Terms of Service, or the rights and obligations of the Job
                Poster(s) or Kind Jobs, the disputing parties shall first
                attempt to settle the dispute amicably. If the dispute cannot be
                resolved within 30 (thirty) days from the date of communicating
                the dispute in writing, either party may initiate arbitration.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The dispute shall be referred to and finally resolved by
                arbitration conducted by an Arbitral Tribunal in accordance with
                the Rules of the Delhi International Arbitration Centre ("DIAC
                Rules"), which are deemed to be incorporated by reference in
                this clause. The arbitration shall take place in New Delhi,
                India, and the language of the arbitration shall be English. The
                law governing the arbitration agreement shall be Indian Law.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Notwithstanding the arbitration provision, either party may seek
                interim or permanent equitable or injunctive relief, or both,
                from the competent courts in New Delhi, India, which have
                jurisdiction to grant relief on any disputes. Pursuing equitable
                or injunctive relief shall not waive the duty of the parties to
                pursue any remedy, including monetary damages, through
                arbitration.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The arbitration award shall be final and binding on the parties.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Release and Limitations of Liability</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters voluntarily access the Kind Jobs services provided
                via the Kind Jobs Platform at their own risk. Kind Jobs shall
                not be held responsible or liable for any loss or damage
                sustained by Job Posters or any other person or entity during
                the course of accessing the Kind Jobs Platform.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                By accessing the Kind Jobs Platform and using the services
                provided therein, Job Posters release and agree to indemnify
                Kind Jobs, its directors, employees, partners, associates, and
                licensors from any liability, cost, loss, or expense arising
                from their access to the Kind Jobs Platform and services,
                including but not limited to personal injury and property
                damage, whether direct, indirect, consequential, foreseeable,
                due to negligence, or otherwise.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs accepts no liability, whether jointly or severally,
                for any errors or omissions, whether on its own behalf or on
                behalf of third parties, in relation to the data/information
                provided on the Kind Jobs Platform.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters are solely responsible for the consequences that may
                arise from their access to Kind Jobs services through illegal
                acts or non-compliance with these Terms of Service and other
                rules and regulations. Job Posters agree to indemnify Kind Jobs,
                its officers, directors, employees, and agents on a full
                indemnity basis for any loss or damage suffered by Kind Jobs
                resulting from such acts.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Job Posters shall indemnify, defend, and hold Kind Jobs harmless
                from any claims arising from or related to the Job Poster's
                engagement with the Kind Jobs Platform. Kind Jobs shall not be
                liable to any Job Poster for acts or omissions arising from or
                related to the Job Poster's engagement with the Kind Jobs
                Platform.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                In consideration of Kind Jobs allowing Job Posters to access the
                Kind Jobs Platform, to the maximum extent permitted by law, Job
                Posters waive and release any and all rights or claims, actions,
                causes of actions (present or future), against Kind Jobs, its
                agents, directors, officers, business associates, group
                companies, sponsors, employees, or representatives for any
                injuries, accidents, mishaps (known or unknown), or anticipated
                or unanticipated events arising from the provision of Kind Jobs
                services.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Disclaimers</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                To the extent permitted under law, neither Kind Jobs nor its
                parent/holding company, subsidiaries, affiliates, directors,
                officers, professional advisors, or employees shall be
                responsible for the deletion, failure to store, mis-delivery, or
                untimely delivery of any information or material.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                To the extent permitted under law, Kind Jobs shall not be
                responsible for any harm resulting from downloading or accessing
                any information or material, the quality of servers, products,
                Kind Jobs services, or other websites.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Any material accessed, downloaded, or obtained through the Kind
                Jobs Platform is at the Job Poster's discretion and risk, and
                the Job Poster is solely responsible for any potential damage to
                their computer system or loss of data resulting from such
                actions.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs makes best efforts to ensure that the Kind Jobs
                Platform is error-free and secure. However, neither Kind Jobs
                nor its partners, licensors, or associates make any warranty
                that:
              </p>
              <ul
                style={{
                  listStyle: "revert",
                  marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                }}>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  The Kind Jobs Platform will meet Job Posters' requirements.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  The Kind Jobs Platform will be uninterrupted, timely, secure,
                  or error-free.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  The results obtained from the use of the Kind Jobs Platform
                  will be accurate or reliable.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  The quality of any products, services, information, or other
                  material obtained through the Kind Jobs Platform will meet Job
                  Posters' expectations.
                </li>
              </ul>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                In case Kind Jobs discovers any errors, Kind Jobs reserves the
                right to rectify the error in a manner it deems fit. Kind Jobs
                will notify the Job Poster of the error and the remedy applied.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                To the extent permitted under law, neither Kind Jobs nor its
                partners, licensors, or associates shall be liable for any
                direct, indirect, incidental, special, or consequential damages
                arising from the use of or inability to use the Kind Jobs
                Platform, even if they have been advised of the possibility of
                such damages.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs reserves the right to cancel or terminate any Kind
                Jobs services hosted or provided on the Kind Jobs Platform if
                the necessary permission or authority from statutory
                authorities, governments, or the board of directors is not
                obtained or denied.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                To the extent permitted under law, in the event of suspension or
                closure of any Kind Jobs services, Job Posters shall not be
                entitled to make any demands or claims of any nature.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Grievance Redressal Mechanism</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If a Job Poster has any complaints or grievances regarding (i)
                any Content that violates these Terms (except for Intellectual
                Property Rights infringement), (ii) their access to the Kind
                Jobs Platform, or (iii) any Content that is obscene, defamatory
                towards the complainant or any person on whose behalf the Job
                Poster is making the complaint, or involves impersonation in an
                electronic form, including artificially morphed images, please
                share the complaint with Kind Jobs by writing to:{" "}
                <a>grievance@kindjobs.co</a>
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The complaint or grievance should include the following
                information:
              </p>
              <ul
                style={{
                  listStyle: "revert",
                  marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                }}>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Name and contact details: name, address, contact number, and
                  email address.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Relation to the subject matter of the complaint: whether the
                  complainant or a person acting on behalf of an affected
                  person.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Name and age of the person aggrieved or affected by the
                  subject matter of the complaint (if applicable), along with a
                  statement that the Job Poster is authorized to act on behalf
                  of such person and provide their personal information to Kind
                  Jobs.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Description of the complaint or grievance with clear
                  identification of the Content in question.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  A statement that the Job Poster believes, in good faith, that
                  the Content violates these Terms and Conditions.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  A statement that the information provided in the complaint or
                  grievance is accurate.
                </li>
              </ul>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs respects the Intellectual Property Rights of others.
                If a Job Poster notices any act of infringement on the Kind Jobs
                Platform, please send a written notice/intimation to Kind Jobs,
                including the following information:
              </p>
              <ul
                style={{
                  listStyle: "revert",
                  marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                }}>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Clear identification of the copyrighted work allegedly
                  infringed.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Clear identification of the allegedly infringing material on
                  the Kind Jobs Platform.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Contact details: name, address, email address, and phone
                  number.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  A statement that the Job Poster believes, in good faith, that
                  the use of the copyrighted material allegedly infringed on the
                  Kind Jobs Platform is not authorized by the Job Poster's agent
                  or the law.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  A statement that the information provided in the notice is
                  accurate and that the signatory is authorized to act on behalf
                  of the owner of an exclusive copyright right that is allegedly
                  infringed.
                </li>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Job Poster's signature or a signature of the Job Poster's
                  authorized agent.
                </li>
              </ul>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Please send the above notices to Kind Jobs by email at
                grievance@kindjobs.co.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Upon receiving a complaint, grievance, or notice, Kind Jobs
                reserves the right to investigate and take appropriate action.
                Kind Jobs may reach out to the Job Poster for further
                clarification or assistance with the investigation. The Job
                Poster acknowledges that timely assistance with the
                investigation would facilitate the redressal process.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Grievance Redressal Officer:
                <br />
                Name: Abhinav Singh
                <br />
                Email: abhinav.singh@kindjobs.co Address: Kind Jobs
                <br />
                Powered by Searchline Database Private Limited (Kind Jobs). All
                right reserved, A-502, Signature 2, Sarkhej Sanand Highway,
                Village – Sarkhej, Taluka – Ahmedabad, Gujarat – 382210
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The Grievance Officer identified above complies with the
                provisions of applicable laws, including the Information
                Technology Act, 2000, the Consumer Protection Act, 2019, and the
                rules enacted under those laws. Kind Jobs reserves the right to
                replace the Grievance Redressal Officer at its discretion, and
                any such replacement will be published on the website, becoming
                effective immediately upon publication.
              </p>
              <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                <strong>Paid Services</strong>
              </li>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                When you subscribe to our paid Services, you agree to adhere to
                the additional terms specific to the Services ("Paid Services")
                related to the Kind jobs platform that you are availing.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Your access to the Kind jobs platform, your ability to post Kind
                jobs and access candidate profiles shall be determined by the
                specific Paid Services that you avail of. Kind jobs reserves the
                right to change the nature and/or manner of delivery of the Paid
                Services at any time without liability to Kind jobs. Kind jobs
                also reserves the right to suspend or discontinue any paid
                services or part(s) thereof, either temporarily or permanently,
                without the requirement for prior intimation or liability to
                Kind jobs.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                By accepting these Terms and Conditions, you specifically waive
                the right to make any claim against Kind jobs for non-delivery
                of the Paid Services after the date on which such Paid Services
                are suspended or discontinued.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind jobs shall, in its sole discretion, determine if any
                refunds are due and payable to you for any non-provision of the
                Paid Services. Any and all refunds shall only be made pro-rata
                to the usage of Paid Services; under no circumstances shall
                refunds be made for the full amount paid by you for the purchase
                of the Paid Services.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                To access the Paid Services for Kind Jobs, you must purchase a
                specific Paid Service. The Paid Services currently being oﬀered
                by Kind Jobs are as follows and shall be delivered according to
                the specific terms outlined below:
              </p>
              <ul
                style={{
                  listStyle: "revert",
                  marginLeft: window.innerWidth < 500 ? "0px" : "30px",
                }}>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Job Posts
                </li>
                <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  For each Job Post that you wish to publish via the Kind Jobs
                  platform, you shall pay a specified fee per Job Post. The
                  validity of the Job Post, as well as the number of leads
                  (i.e., the number of applications from candidates) that you
                  are eligible to receive per Job Post, will be determined based
                  on the amount payable for that Job Post. Kind Jobs reserves
                  the sole right to vary the validity of a Job Post and the
                  number of leads you are eligible to receive per Job Post.
                </p>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Database Views
                </li>
                <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  By paying a specified fee, you will have access to view the
                  database of candidates' profiles (referred to as "Database
                  Views"). The number of Database Views that you are eligible
                  for will be determined based on the amount payable for the
                  service. Kind Jobs reserves the sole right to vary the number
                  of Database Views and the number of leads you are eligible to
                  receive per Job Post.
                </p>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Period-specific subscriptions
                </li>
                <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  By purchasing a subscription ("Subscription Plan") through the
                  Kind Jobs platform, you will receive access to specified Job
                  Posts and/or Database Views. Your access to the Kind Jobs
                  platform will be available for the period defined in the
                  Subscription Plan you purchase. Each Subscription Plan will be
                  valid for a specified length of time ("Subscription
                  Validity"). Every Subscription Plan will entitle you to a
                  specific number of Job Posts, views, and/or downloads of the
                  database of candidates' profiles ("Database Views"). If you
                  exhaust the allotted number of Job Posts or Database Views
                  during the Subscription Validity, you may be required to pay
                  an additional amount to post more Job Posts or gain further
                  Database Views, as the case may be. Kind Jobs reserves the
                  right to allow a top-up of the existing Subscription Plan or
                  may require you to purchase an additional Subscription Plan.
                  The decision regarding whether a top-up is allowed or if a
                  fresh Subscription Plan is to be purchased will be determined
                  by the nature of your initial Subscription Plan and the Paid
                  Services and Subscription Plans oﬀered by Kind Jobs at the
                  time of the additional purchase.
                </p>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Fair usage
                </li>
                <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Regardless of your specific Subscription Plan, Kind Jobs
                  reserves the right to limit the number of Job Posts and/or
                  Database Views to, among other things, ensure the protection
                  of Kind Jobs' and candidates' data, as well as to maintain the
                  efficiency of the Kind Jobs platform. Furthermore, Kind Jobs
                  reserves the right to remove a Job Post, reduce its
                  visibility, or restrict responses to a Job Post if, in Kind
                  Jobs' sole discretion, the Job Post has received a sufficient
                  number of responses. Kind Jobs reserves the right to limit
                  Database Views if, in Kind Jobs' sole discretion, it is
                  determined that you have viewed or downloaded a sufficient
                  number of candidate profiles, either in relation to a specific
                  Job Post or in relation to the overall Services provided by
                  Kind Jobs and your usage of the platform.
                </p>
                <li className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  KJ Coins
                </li>
                <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  For certain Paid Services on the Kind Jobs platform, you may
                  be required to purchase "KJ Coins" that represent the value of
                  the payment made by you. The number of KJ Coins required to
                  utilize the Paid Services will be communicated to you at the
                  time of purchase of KJ Coins and/or when utilizing them for
                  the specified Paid Service(s). Once purchased, KJ Coins cannot
                  be transferred to any other person.
                </p>
              </ul>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs reserves the right to reject any Job Post or
                information/data provided by you without assigning any reason,
                either before uploading or after uploading the Job Post. In such
                an eventuality, any amount paid may be refunded to you on a
                pro-rata basis at the sole discretion of Kind Jobs. By
                posting/uploading a job posting on the Kind Jobs platform, you
                confirm that you have obtained all necessary licenses/permits
                for recruitment and agree to indemnify Kind Jobs against any
                claims or damages arising out of actions or claims related to
                the same. Kind Jobs oﬀers no guarantee or warranties that you
                will receive satisfactory responses or any response at all once
                the Job Post is made live on the platform. Kind Jobs also does
                not guarantee that there will be no overlaps between the
                responses to a Job Post and Database Views, meaning that your
                Database Views might reveal the same candidate profiles that are
                received as responses to your Job Post. Kind Jobs may partner
                with third parties from time to time to provide parts of the
                Paid Services. These partnerships may include access to external
                databases or other services related to Kind Jobs' Paid Services.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Kind Jobs makes no warranty or representation regarding such
                third-party services and shall have no liability in relation to
                the provision of such services by the relevant third-party
                providers. Kind Jobs shall not be held liable for any
                information received by you, and it is your sole responsibility
                to check, authenticate, and verify the information/response
                received at your own cost and expense. The Paid Services will
                commence only upon receipt of payment for the Subscription Plan,
                either directly from you or from any third party authorized, in
                writing, to act on your behalf. Kind Jobs may require you to
                provide an undertaking that the Job Posts relate to genuine jobs
                and that you have the authority to make such Job Posts. You
                further undertake not to require any form of financial
                commitment or payment in kind from candidates who are interested
                in and applying for/replying to the Job Posts made by you on the
                Kind Jobs platform. Your Subscription Plan is not resaleable or
                transferable to any other person, corporate body, firm, or
                individual. You do not have any copyright or other intellectual
                property rights over the data uploaded via a Job Post to the
                Kind Jobs platform.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                All disputes arising out of your use of the Paid Services and
                the Subscription Plan shall be resolved in accordance with the
                laws of India as applicable. Your account on the Kind Jobs
                platform shall be determined according to the manner in which
                you are accessing the Paid Services. If you wish to access the
                Paid Services as an individual, you shall be required to create
                an account on the Kind Jobs platform and either purchase a
                Subscription Plan or KJ Coins, depending on the specific Paid
                Service you wish to avail of. If you are purchasing KJ Coins,
                they shall not be transferable to any other person and are for
                your exclusive use.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If you wish to access the Paid Services as an organization, you
                shall create an Enterprise Account on the Kind Jobs platform
                ("Enterprise Account") to enable your employees, agents, or
                representatives to access, interact with, and avail of the
                Services through the Enterprise Account. You may also specify
                certain employees, agents, or representatives ("Designated
                Users") who shall create individual accounts ("Designated User
                Accounts") to avail the Services on your behalf. Upon receipt of
                payment for a Subscription Plan from you, Kind Jobs will credit
                the Enterprise Account with the necessary number of credits
                corresponding to your specific Subscription Plan.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The number of credits required for Job Posts and Database Views
                will be communicated to you by Kind Jobs from time to time. Kind
                Jobs reserves the right to make changes to the credit
                requirements for posting a job or viewing the database. Kind
                Jobs further reserves the right to have diﬀerential credit
                requirements for diﬀerent types of Job Posts and/or Database
                Views. If Designated Users have previously purchased KJ Coins in
                their individual capacity, upon their addition to the Enterprise
                Account as Designated Users, all KJ Coins held by them will be
                transferred to the Enterprise Account. You shall ensure that
                necessary reimbursements, if required, and consent are obtained
                from the respective Designated User(s) for their addition to the
                Enterprise Account and transfer of their KJ Coins to the
                Enterprise Account.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                You agree to indemnify and hold Kind Jobs harmless from any
                claims made by a Designated User regarding their addition to an
                Enterprise Account and the transfer of their KJ Coins to the
                Enterprise Account. The purchase of KJ Coins may be made by
                either the administrator of an Enterprise Account or Designated
                Users. All invoices shall be raised by Kind Jobs on the
                Enterprise Account, i.e., the invoices will be in the name of
                the organization for which the Enterprise Account has been
                activated. It shall be your sole responsibility to utilize KJ
                Coins only for those Job Categories and geographic areas where
                the Paid Services are meant to be availed.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If you or the Designated Users unintentionally or mistakenly
                publish Paid Job Posts on the Kind Jobs platform for Job
                Categories or geographic areas where the Paid Services are not
                available, Kind Jobs shall have no liability whatsoever to
                refund KJ Coins deducted from your Enterprise Account for such
                Paid Job Post(s). The validity period of a Paid Job Post will
                depend on the number of KJ Coins utilized to create the Paid Job
                Post. The validity period shall start from the date of
                publishing the Paid Job Post on the Kind Jobs platform. No
                refunds for any purpose other than Kind Jobs' inability to
                provide the Services shall be made once KJ Coins are issued.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If Kind Jobs is unable to provide the Services, refunds shall be
                made pro-rata based on the unused KJ Coins. It is hereby
                clarified that no refunds shall be made for KJ Coins that have
                already been exhausted by you. Prior to allowing any Designated
                User to use the Kind Jobs platform, you agree to register each
                Designated User with the platform. Kind Jobs may require you to
                share documentation evidencing the Designated User's affiliation
                with the organization, including but not limited to employee
                identity cards, PAN cards, etc. You represent and warrant that
                you have the right, authority, and have obtained the requisite
                consent from the respective Designated User(s) to share the
                above-mentioned information with Kind Jobs. Subject to the Paid
                Services and/or Subscription Plan availed by you, a Designated
                User might be required to download the Kind Jobs mobile
                application ("Kind Jobs App").
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                If a Designated User is required to download the Kind Jobs App
                and avail the Services through the app, the Designated User may
                be required to enter personal data and verify the provided
                mobile number during the registration process. You shall ensure
                that the personal data and mobile number provided by the
                Designated User are accurate. Kind Jobs shall ensure that any
                personal data entered by an Authorized User is kept confidential
                at all times.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                You acknowledge that certain Designated Users may be suspended
                or banned from using the Kind Jobs platform due to past or
                future violations of the Kind Jobs Terms and Conditions and
                Privacy Policy, and that Kind Jobs shall have no obligation or
                liability related to a Designated User who is unable to obtain
                or maintain access to and utilization of the Services. If
                Designated Users have previously purchased a Subscription Plan
                in their individual capacity, upon their addition to the
                Enterprise Account as Designated Users, their individual
                Subscription Plan may, at Kind Jobs' sole discretion, be merged
                into your Subscription Plan.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                You shall ensure that necessary reimbursements, if required, and
                consent are obtained from the respective Designated User(s) for
                their addition to the Enterprise Account and transfer of their
                Subscription Plan to the Enterprise Account. You agree to
                indemnify and hold Kind Jobs harmless from any claims made by a
                Designated User regarding their addition to an Enterprise
                Account and the transfer of their Subscription Plan to the
                Enterprise Account.
              </p>
              <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                The purchase of a Subscription Plan may be made by either the
                administrator of an Enterprise Account or Designated Users. All
                invoices shall be raised by Kind Jobs on the Enterprise Account,
                i.e., the invoices will be in the name of the organization for
                which the Enterprise Account has been activated. Kind Jobs
                reserves the right to restrict the number of Job Posts per
                Enterprise Account. The decision to restrict the number of Job
                Posts shall be at Kind Jobs' sole discretion.
              </p>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsAndService;
