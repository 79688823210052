import React, { useEffect } from "react";
import Layout from "./Layout";
import SEOData from "./SEOData";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      <SEOData keyword="Refund Policy" />
      <section className="section-box">
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h2 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Kind Jobs Refund Policy
            </h2>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              At Kind Jobs, we strive to provide an exceptional job posting
              experience for our clients. We understand that the relevance of
              job applications is crucial to your satisfaction. Therefore, we
              offer a Money-Back Guarantee under specific circumstances.
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Money-Back Guarantee:
            </h4>
            <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              If you find that the applications received through our platform
              are consistently irrelevant to the job requirements you have
              posted, you may be eligible for a refund. Please note the
              following conditions and steps for initiating a refund:
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Eligibility Criteria:
            </h4>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              The job posting must have been active for at least 30 days.
            </p>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              The client must have used our recommended criteria and filters
              during the application review process.
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Refund Request Process:
            </h4>
            <p className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Send an email to{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="mailto:support@kindjobs.co
              ">
                support@kindjobs.co
              </a>{" "}
              within 7 days after the job posting expires.
            </p>
            <p className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Include the job posting details, a brief explanation of the issue,
              and any supporting evidence of the lack of relevance in
              applications.
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Evaluation Process:
            </h4>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Our team will review the provided information and assess the
              relevance of the applications received.
            </p>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              The evaluation process may take up to 14 business days.
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Refund Decision:
            </h4>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              If the applications are deemed consistently irrelevant, Kind Jobs
              will issue a full refund of the job posting fee.
            </p>
            <p className="font-about color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              The refund will be processed using the original payment method.
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <h4 className="aboutUsTitle mb-10 wow animate__animated animate__fadeInUp">
              Conditions and Limitations:
            </h4>
            <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Refund requests are only considered for the specific job posting
              in question.
            </p>
            <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Kind Jobs reserves the right to deny refund requests that do not
              meet the eligibility criteria or lack sufficient evidence.
            </p>
            <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              The Money-Back Guarantee is a one-time offer per client and cannot
              be applied to subsequent job postings.
            </p>
            <p className="font-about pb-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Refunds are not applicable if the lack of relevance is due to
              inaccurate or unclear job descriptions provided by the client.
            </p>
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              By using Kind Jobs services, you agree to abide by this refund
              policy. We are committed to ensuring your satisfaction and the
              success of your job postings. If you have any questions or
              concerns, please contact our customer support team at{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="mailto:support@kindjobs.co
              ">
                support@kindjobs.co.
              </a>
            </p>
          </div>
        </div>
        <div className="container" style={{ padding: "20px" }}>
          <div className="text-left">
            <p className="font-about  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              <strong>Note:</strong> The above policy is a general template, and
              it's advisable to consult with legal professionals to ensure
              compliance with local laws and regulations.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RefundPolicy;
