import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './App.css';
import Routess from './Routess';
import './style.css';

function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routess />
      </BrowserRouter>
    </div>
  );
}

export default App;
