import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CustomAlert from "../CustomAlert";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/context";
import { employerProfileData } from "../../api/auth";
import { employerSetupData } from "../../api/home";
import { statusDropdownColorStyles } from "../Jobpost/BasicDetailsForm";
import { logoBlueColor, logoRedColor } from "../Header";
import CustomReactSelect from "../CustomReactSelect";
import company from "../../assets/imgs/svgs/company.svg"
import client from "../../assets/imgs/svgs/Client.svg"

export const lightLogoColor = "#E0E6F7";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
const EmployerDetails = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const jobRoleOptions = [
    { id: 1, label: "HR", value: "HR" },
    { id: 2, label: "Recruiter", value: "Recruiter" },
    { id: 3, label: "Talent Acquisition", value: "Talent_Acquisition" },
    {
      id: 4,
      label: "Talent Acquisition Manager",
      value: "Talent_Acquisition_Manager",
    },
  ];

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    jobRole: false,
    companyName: false,
    yourIndustry: false,
    companyWebsite: false,
    noOfEmployee: false,
    hireFor: false,
    consultancyName: false,
    consultancyWebsite: false,
    company: false,
    client: false,
  });

  const [selectEmployer, setSelectEmployer] = useState({
    company: false,
    client: false,
  });

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    yourIndustry: "",
    companyWebsite: "",
    noOfEmployee: "",
  });

  const [clientInfo, setClientInfo] = useState({
    hireFor: "",
    consultancyName: "",
    consultancyWebsite: "",
    noOfEmployee: "",
  });

  const [recruiterInfo, setRecruiterInfo] = useState({
    firstName: "",
    lastName: "",
    jobRole: "",
  });

  const {
    setAdditionalPerksOption,
    setDepartmentDataOption,
    industryDataOption,
    setIndustryDataOption,
    setJobRoleDataOption,
    setJobTypeDataOption,
    setJoiningFeeTypeOption,
    noOfEmployeeOption,
    setNoOfEmployeeOption,
    setPayTypeOption,
    roleDataOption,
    setRoleDataOption,
    token,
    setToken,
    setAlertMessage,
    alertMessage,
    setWelcomeMessage,
  } = useContext(Context);

  const steps = ["Recruiter Details", "Company details", "Job post"];

  //make handleChange function for all input
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "company") {
      setSelectEmployer({
        ...selectEmployer,
        company: true,
        client: false,
      });
    } else if (name === "client") {
      setSelectEmployer({
        ...selectEmployer,
        company: false,
        client: true,
      });
    } else if (
      name === "companyName" ||
      name === "yourIndustry" ||
      name === "companyWebsite" ||
      name === "noOfEmployee"
    ) {
      if (companyInfo[name] === "") {
        setError({
          ...error,
          [name]: true,
        });
      } else {
        setError({
          ...error,
          [name]: false,
        });
      }
      setCompanyInfo({
        ...companyInfo,
        [name]: value,
      });
    } else if (
      name === "hireFor" ||
      name === "consultancyName" ||
      name === "consultancyWebsite" ||
      name === "noOfEmployee"
    ) {
      if (clientInfo[name] === "") {
        setError({
          ...error,
          [name]: true,
        });
      } else {
        setError({
          ...error,
          [name]: false,
        });
      }
      setClientInfo({
        ...clientInfo,
        [name]: value,
      });
    } else if (
      name === "firstName" ||
      name === "lastName" ||
      name === "jobRole"
    ) {
      if (recruiterInfo[name] === "") {
        setError({
          ...error,
          [name]: true,
        });
      } else {
        setError({
          ...error,
          [name]: false,
        });
      }
      setRecruiterInfo({
        ...recruiterInfo,
        [name]: value,
      });
    }
  };

  //make array for radio button for no of employee
  const noOfEmployee = [
    { id: 1, value: "1-50" },
    { id: 2, value: "51-100" },
    { id: 3, value: "101-300" },
    { id: 4, value: "301-500" },
    { id: 5, value: "501-1000" },
    { id: 6, value: "1000 above" },
    { id: 7, value: "Not sure" },
  ];

  const handleClientInfo = () => {
    // check if any value is empty
    setError({
      hireFor: clientInfo.hireFor === "" ? true : false,
      consultancyName: clientInfo.consultancyName === "" ? true : false,
      noOfEmployee: clientInfo.noOfEmployee === "" ? true : false,
    });
    if (
      clientInfo.hireFor !== "" &&
      clientInfo.consultancyName !== "" &&
      clientInfo.noOfEmployee !== ""
    ) {
      const body = {
        first_name: recruiterInfo.firstName,
        last_name: recruiterInfo.lastName,
        job_role: recruiterInfo.jobRole?.value,
        hiring_for: 2,
        client: {
          client_firm_name: clientInfo.hireFor,
          consultancy_name: clientInfo.consultancyName,
          number_of_hires: clientInfo.noOfEmployee,
          consultancy_website_url: clientInfo.consultancyWebsite,
          no_of_employee: clientInfo.noOfEmployee,
        },
      };
      employerProfileData(body, token)
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setAlertMessage({
              message: "Profile created successfully",
              isOpen: true,
              type: "success",
            });
            localStorage.setItem("welcomeMessage", true);
            localStorage.setItem("userData", true);
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                company_name: clientInfo.consultancyName,
                is_compnay: 2,
              })
            );
            localStorage.setItem(
              "companyInfo",
              JSON.stringify(body?.client?.client_firm_name)
            );
            navigate("/postjob");
          }
          // navigate("/postjob");
        })
        .catch((err) => {
          console.log(err, "err");
          setAlertMessage({
            message: err?.response?.data?.message,
            isOpen: true,
            type: "danger",
          });
        });
    }
  };

  const handleCompanyInfo = (e) => {
    // check if any value is empty
    setError({
      companyName: companyInfo.companyName === "" ? true : false,
      yourIndustry: companyInfo.yourIndustry === "" ? true : false,
      noOfEmployee: companyInfo.noOfEmployee === "" ? true : false,
    });
    if (
      companyInfo.companyName !== "" &&
      companyInfo.yourIndustry !== "" &&
      companyInfo.noOfEmployee !== ""
    ) {
      const body = {
        first_name: recruiterInfo.firstName,
        last_name: recruiterInfo.lastName,
        job_role: recruiterInfo.jobRole?.value,
        hiring_for: 1,
        company: {
          company_name: companyInfo.companyName,
          company_indstry: companyInfo.yourIndustry?.value,
          company_website_url: companyInfo.companyWebsite,
          no_of_employee: companyInfo.noOfEmployee,
        },
      };
      employerProfileData(body, token)
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setAlertMessage({
              message: "Profile created successfully",
              isOpen: true,
              type: "success",
            });
            localStorage.setItem("welcomeMessage", true);
            localStorage.setItem("userData", true);
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                company_name: body?.company?.company_name,
                is_compnay: 1,
              })
            );
            localStorage.setItem(
              "companyInfo",
              JSON.stringify(body?.company?.company_name)
            );
            navigate("/postjob");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          setAlertMessage({
            message: err?.response?.data?.message,
            isOpen: true,
            type: "danger",
          });
        });
    }
  };

  const handleRecruiterInfo = () => {
    // check if any value is empty
    setError({
      firstName: recruiterInfo.firstName === "" ? true : false,
      lastName: recruiterInfo.lastName === "" ? true : false,
      jobRole:
        recruiterInfo.jobRole === "" || recruiterInfo.jobRole?.value === ""
          ? true
          : false,
    });
    // if all value is not empty then go to next step
    if (
      recruiterInfo.firstName !== "" &&
      recruiterInfo.lastName !== "" &&
      recruiterInfo.jobRole !== ""
    ) {
      localStorage.setItem("userFirstName", recruiterInfo?.firstName);
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  // useEffect(() => {
  //   setLogin({
  //     ...login,
  //     detailsPage: true
  //   })
  // }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  useEffect(() => {
    employerSetupData()
      .then((res) => {
        setDepartmentDataOption(res?.data?.data?.department_data);
        setAdditionalPerksOption(res?.data?.data?.additional_perks);
        setIndustryDataOption(res?.data?.data?.industry_data);
        setJobRoleDataOption(res?.data?.data?.job_role_data);
        setJobTypeDataOption(res?.data?.data?.job_type);
        setJoiningFeeTypeOption(res?.data?.data?.joining_fee_type);
        setNoOfEmployeeOption(res?.data?.data?.number_of_employees);
        setPayTypeOption(res?.data?.data?.pay_type);
        setRoleDataOption(res?.data?.data?.role_data);
        setToken(res?.data?.data?.token);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {alertMessage && alertMessage?.isOpen && (
        <CustomAlert
          show={alertMessage?.isOpen}
          message={alertMessage?.message}
          type={alertMessage?.type}
          setShow={setAlertMessage}
        />
      )}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Grid container justifyContent="center">
        <Grid item xs={11} lg={10}>
          {activeStep === 0 && (
            // <>
            // </>
            <form className="login-register text-start mt-100" action="#">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <div className="form-group ">
                    <label className="form-label" htmlFor="input-1">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      name="firstName"
                      value={recruiterInfo.firstName}
                      onChange={(e) => handleChange(e)}
                      required
                      placeholder="First Name"
                    />
                    {error.firstName && (
                      <div className="text-danger">Please enter first name</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <div className="form-group ">
                    <label className="form-label" htmlFor="input-1">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      required
                      value={recruiterInfo.lastName}
                      onChange={(e) => handleChange(e)}
                      // onChange={(e) => setRecruiterInfo({ ...recruiterInfo, lastName: e.target.value })}
                      name="lastName"
                      placeholder="Last Name"
                    // placeholder="Steven Job"
                    />
                    {error.lastName && (
                      <div className="text-danger">Please enter last name</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-1">
                      Which of the following best describes your job role?{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <CustomReactSelect
                      // className="form-input mr-10 select-active input-location"
                      name="jobRole"
                      styles={statusDropdownColorStyles}
                      style={{ borderRadius: "5px" }}
                      value={recruiterInfo.jobRole.value}
                      options={roleDataOption.map((item) => {
                        return {
                          value: item.role_id,
                          label: item.title,
                        };
                      })}
                      placeholder="Select Job Role"
                      onChange={(e) => {
                        setRecruiterInfo({ ...recruiterInfo, jobRole: e });
                        setError({
                          ...error,
                          jobRole: false,
                        });
                      }}
                    />
                    {error.jobRole && (
                      <div className="text-danger">Please select job role</div>
                    )}
                    {/* <input className="form-control" id="input-1" type="select" required name="fullname" placeholder="Steven Job"
                    /> */}
                  </div>
                </Grid>
                {/* <Grid item xs={6}>
                  <Button
                    style={{ width: "100%", padding: "10px" }}
                    variant="contained">
                    back
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    style={{
                      width: "100%",
                      padding: "10px",
                      backgroundColor: logoRedColor,
                    }}
                    onClick={handleRecruiterInfo}
                    variant="contained">
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {activeStep === 1 && (
            <>
              <Grid container spacing={2} style={{ marginTop: "70px" }}>
                <Grid item sm={6} xs={12}>
                  <label for="company">
                    <Box
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid gray",
                        padding: "10%",
                        borderRadius: "30px",
                        height: "200px",
                        backgroundColor:
                          selectEmployer?.company && lightLogoColor,
                        borderColor: selectEmployer?.company && logoBlueColor,
                      }}>
                      <input
                        id="company"
                        type="radio"
                        name="company"
                        hidden
                        style={{
                          paddingRight: "20px",
                          fontSize: "2rem",
                          width: "10%",
                        }}
                        value={selectEmployer?.company}
                        // onChange={
                        //   () => {
                        //     setSelectEmployer({
                        //       company: true,
                        //       client: false,
                        //     })
                        //   }
                        // }
                        onClick={(e) => {
                          setSelectEmployer({
                            company: true,
                            client: false,
                          });
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",

                        }}>
                        <img
                          src={company}
                          alt="company"
                          style={{ width: "20%", height: "20%" }}
                        />
                        <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                          My Own Company
                        </div>
                        <div style={{ textAlign: "center" }}>
                          I'm an owner/employee of a business or a
                          company/enterprise.
                        </div>
                      </div>
                    </Box>
                  </label>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <label for="client">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid black",
                        padding: "10%",
                        cursor: "pointer",
                        borderRadius: "30px",
                        height: "200px",
                        backgroundColor:
                          selectEmployer?.client && lightLogoColor,
                        borderColor: selectEmployer?.client && logoBlueColor,
                      }}>
                      <input
                        id="client"
                        type="radio"
                        name="client"
                        hidden
                        style={{ paddingRight: "20px", width: "10%" }}
                        value={selectEmployer?.client}
                        // onChange={
                        //   () => {
                        //     setSelectEmployer({
                        //       company: false,
                        //       client: true,
                        //     })
                        //   }
                        // }
                        onClick={(e) => {
                          setSelectEmployer({
                            company: false,
                            client: true,
                          });
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <img
                          src={client}
                          alt="company"
                          style={{ width: "20%", height: "20%" }}
                        />
                        <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                          My Clients
                        </div>
                        <div style={{ textAlign: "center" }}>
                          I am a consultant working for staffing or manpower
                          consultancy.
                        </div>
                      </div>
                    </Box>
                  </label>
                </Grid>
              </Grid>
              {selectEmployer?.company && (
                <form className="login-register text-start mt-30" action="#">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="input-1">
                          Your company name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          value={companyInfo.companyName}
                          onChange={(e) => handleChange(e)}
                          id="input-1"
                          type="text"
                          required
                          name="companyName"
                          placeholder="Company Name"
                        // placeholder="Steven Job"
                        />
                        {error.companyName && (
                          <div className="text-danger">
                            Please enter company name
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="input-1">
                          Your industry <span className="text-danger">*</span>
                        </label>
                        <CustomReactSelect
                          // className="form-control"
                          id="input-1"
                          options={industryDataOption.map((item) => {
                            return {
                              value: item.industries_id,
                              label: item.title,
                            };
                          })}
                          styles={statusDropdownColorStyles}
                          value={companyInfo.yourIndustry?.value}
                          onChange={(e) => {
                            setCompanyInfo({ ...companyInfo, yourIndustry: e });
                            setError({
                              ...error,
                              yourIndustry: false,
                            });
                          }}
                          name="yourIndustry"
                          placeholder="Your Industry"
                        // placeholder="Steven Job"
                        />
                        {error.yourIndustry && (
                          <div className="text-danger">
                            Please enter your industry
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="input-1">
                          Company website
                        </label>
                        <input
                          className="form-control"
                          id="input-1"
                          type="text"
                          value={companyInfo.companyWebsite}
                          onChange={(e) => handleChange(e)}
                          required
                          name="companyWebsite"
                          placeholder="Company Website"
                        // placeholder="Steven Job"
                        />
                        {/* {error.companyWebsite && (
                          <div className="text-danger">
                            Please enter company website
                          </div>
                        )} */}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <label className="form-label" htmlFor="input-1">
                        Number of employees
                      </label>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                          justifyContent: "left",
                          flexWrap: "wrap",
                        }}>
                        {/* map noOfEmployees in radio button input */}
                        {noOfEmployeeOption.map((item, index) => (
                          <label for={`company-${item.id}`}>
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                padding: "10px",
                                width: "100px",
                                border: "1px solid gray",
                                borderRadius: "30px",
                                backgroundColor:
                                  companyInfo.noOfEmployee === item.id &&
                                  lightLogoColor,
                                borderColor:
                                  companyInfo.noOfEmployee === item.id &&
                                  logoBlueColor,
                              }}>
                              <input
                                id={`company-${item.id}`}
                                type="radio"
                                name="noOfEmployees"
                                hidden
                                value={companyInfo.noOfEmployee}
                                onChange={(e) => {
                                  setCompanyInfo({
                                    ...companyInfo,
                                    noOfEmployee: item.id,
                                  });
                                  setError({
                                    ...error,
                                    noOfEmployee: false,
                                  });
                                }}
                              />
                              <label
                                for={`company-${item.id}`}
                                style={{
                                  textAlign: "center",
                                  marginBottom: "0px",
                                  cursor: "pointer",
                                }}>
                                {item.title}
                              </label>
                            </div>
                          </label>
                        ))}
                      </div>
                      {error.noOfEmployee && (
                        <div className="text-danger">
                          Please select no of employees
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleBack}
                        style={{
                          width: "100%",
                          padding: "10px",
                          backgroundColor: "gray",
                        }}
                        variant="contained">
                        back
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={{
                          width: "100%",
                          padding: "10px",
                          backgroundColor: logoRedColor,
                        }}
                        onClick={handleCompanyInfo}
                        variant="contained">
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              {selectEmployer?.client && (
                <form className="login-register text-start mt-30" action="#">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="input-1">
                          Client you normally hire for{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="input-1"
                          type="text"
                          value={clientInfo.hireFor}
                          onChange={(e) => handleChange(e)}
                          required
                          name="hireFor"
                          placeholder="Client you normally hire for"
                        // placeholder="Steven Job"
                        />
                        {error.hireFor && (
                          <div className="text-danger">
                            Please enter client you normally hire for
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="input-1">
                          Your consultancy name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="input-1"
                          type="text"
                          required
                          value={clientInfo.consultancyName}
                          onChange={(e) => handleChange(e)}
                          name="consultancyName"
                          placeholder="Your consultancy name"
                        // placeholder="Steven Job"
                        />
                        {error.consultancyName && (
                          <div className="text-danger">
                            Please enter your consultancy name
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="input-1">
                          Consultancy website{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          className="form-control"
                          id="input-1"
                          type="text"
                          value={clientInfo.consultancyWebsite}
                          onChange={(e) => handleChange(e)}
                          required
                          name="consultancyWebsite"
                          placeholder="Consultancy website"
                        // placeholder="Steven Job"
                        />
                        {/* {error.consultancyWebsite && (
                          <div className="text-danger">
                            Please enter consultancy website
                          </div>
                        )} */}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                          justifyContent: "left",
                          width: "100%",
                          flexWrap: "wrap",
                        }}>
                        {/* map noOfEmployees in radio button input */}
                        {noOfEmployeeOption.map((item, index) => (
                          <label for={`client-${item.id}`}>
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "center",
                                padding: "0% 1% 0% 1%",
                                cursor: "pointer",
                                padding: "10px",
                                width: "100px",
                                borderRadius: "30px",
                                border: "1px solid gray",
                                borderRadius: "30px",
                                backgroundColor:
                                  clientInfo.noOfEmployee === item.id &&
                                  lightLogoColor,
                                borderColor:
                                  clientInfo.noOfEmployee === item.id &&
                                  logoBlueColor,
                              }}>
                              <input
                                id={`client-${item.id}`}
                                type="radio"
                                name="noOfEmployee"
                                value={item.id}
                                hidden
                                onChange={(e) => {
                                  setClientInfo({
                                    ...clientInfo,
                                    noOfEmployee: item.id,
                                  });
                                  setError({
                                    ...error,
                                    noOfEmployee: false,
                                  });
                                }}
                              />
                              <label
                                for={`client-${item.id}`}
                                style={{
                                  textAlign: "center",
                                  marginBottom: "0px",
                                  cursor: "pointer",
                                }}>
                                {item.title}
                              </label>
                            </div>
                          </label>
                        ))}
                      </div>
                      {error.noOfEmployee && (
                        <div className="text-danger">
                          Please select no of employees
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleBack}
                        style={{
                          width: "100%",
                          padding: "10px",
                          backgroundColor: "gray",
                        }}
                        variant="contained">
                        back
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={{
                          width: "100%",
                          padding: "10px",
                          backgroundColor: logoRedColor,
                        }}
                        onClick={() => handleClientInfo()}
                        variant="contained">
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployerDetails;
