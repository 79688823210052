import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "react-bootstrap";
import { setSubscribeEmail } from "../api/home";
import { logoBlueColor } from "./Header";
import home3 from "../assets/imgs/home3.png"

const SubscribeEmail = () => {
    const [email, setEmail] = React.useState("");
    // const [error, setError] = React.useState('')/
    const [save, setSave] = React.useState(false);

    const handleSubmit = () => {
        const body = {
            email_address: email,
        };
        setSubscribeEmail(body)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    setSave(true);
                    setEmail("");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (e) => {
        //check if email is valid
        // let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
        //     //if valid, set email
        setEmail(e.target.value);
        // } else {
        //     //if not valid, set email to empty string
        //     setError('Please enter a valid email address')
        // }
    };
    return (
        // <>
        <section className="section-box send-email-section ">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={save}
                autoHideDuration={1000}
                onClose={() => setSave(false)}>
                <Alert
                    onClose={() => setSave(false)}
                    severity="success"
                    sx={{ width: "100%" }}>
                    Successfully sent
                </Alert>
            </Snackbar>
            <div
                className="container"
                style={{
                    backgroundColor: "#f5f3f6",
                    color: logoBlueColor,
                    borderRadius: "10px",
                    height: "400px",
                }}>
                <div className="box-newsletter mt-0">
                    <div className="row ">
                        <div
                            style={{ position: "relative" }}
                            className="col-xl-5 col-12 d-none d-xl-block">
                            <img
                                src={home3}
                                className="img-responsive"
                                style={{ position: "absolute", top: "-30px" }}
                                alt="joxBox"
                            />
                        </div>
                        <div className="col-lg-12 col-xl-7 col-12 mt-60">
                            <h2
                                className="font-xl text-center"
                                style={{ color: logoBlueColor, fontWeight: "bold" }}>
                                There is Always Something New Coming On The Way!
                                <br /> Subscribe to get regular updates
                            </h2>
                            <div className="box-form-newsletter mt-40">
                                <div className="form-newsletter">
                                    <input
                                        className="input-newsletter"
                                        value={email}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Enter your email here"
                                    />
                                    <button
                                        className="buttonCustom font-heading icon-send-letter hover-up"
                                        onClick={handleSubmit}>
                                        Subscribe
                                    </button>
                                </div>
                                {/* {
                                    error && <p className="text-danger" >{error}</p>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // </>
    );
};

export default SubscribeEmail;
