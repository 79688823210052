/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Hidden,
  Step,
  StepButton,
  Stepper
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/context";
import CustomAlert from "../CustomAlert";
import Dashboard from "../Dashboard";
import ManageCoins from "../ManageCoins";
import CandidateProfile from "../Profile";
import BasicDetailsForm from "./BasicDetailsForm";
import CandidateRequirements from "./CandidateRequirements";
import InterviewerInfo from "./InterviewerInfo";
import PostJobHeader from "./PostJobHeader";
import PostJobSideBar from "./PostJobSideBar";
import SelectPlan from "./SelectPlan";
import JobList from "./JobList";
import moment from "moment";

export const logoBlueColor = "#242265";
export const logoRedColor = "#c12f33";
export const lightRedLogoColor = "#fae1e2";

// light version of the logoBlueColor
export const logoBlueColorLight = "#e6f0ff";

const JobPostInfo = () => {

  const steps = [
    "Basic Details",
    "Candidate Requirements",
    "Interview Information",
    "Select Plan",
  ];

  const {
    isJobDetailsOpen,
    isEditJobPostDetails,
    activeStep,
    setActiveStep,
    employerTab,
    alertMessage,
    setAlertMessage,
    title,
    viewCandidateList,
    setEmployerTab,
  } = useContext(Context);

  const [openClass, setOpenClass] = useState("");
  const [openSideBar, setOpenSideBar] = useState(false);

  const handleOpen = () => {
    document.body.classList.add("mobile-menu-active");
    setOpenClass("sidebar-visible");
  };

  const handleRemove = () => {
    if (openClass === "sidebar-visible") {
      setOpenClass("");
      document.body.classList.remove("mobile-menu-active");
    }
  };

  const renderComponent = (activeStep) => {
    if (employerTab === "dashboard") {
      return <Dashboard />;
    } else if (employerTab === "jobs") {
      return <JobList />;
    } else if (employerTab === "report") {
      // return <Report />;
      return;
    } else if (employerTab === "profile") {
      return <CandidateProfile />;
    } else if (employerTab === "manageCoins") {
      return <ManageCoins />;
    } else if (employerTab === "postJob") {
      switch (activeStep) {
        case 0:
          return (
            <BasicDetailsForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          );
        case 1:
          return (
            <CandidateRequirements
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          );
        case 2:
          return (
            <InterviewerInfo
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          );
        case 3:
          return (
            <SelectPlan activeStep={activeStep} setActiveStep={setActiveStep} />
          );
        default:
          return (
            <BasicDetailsForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          );
      }
    }
  };

  useEffect(() => {
    let message = JSON.parse(localStorage.getItem("welcomeMessage"));
    if (message === true) {
      setAlertMessage({
        message: "Welcome to kindJob",
        isOpen: true,
        type: "success",
      });
      localStorage.setItem("welcomeMessage", false);
    }

    return () => {
      setEmployerTab("dashboard")
    }
  }, []);

  const getTitle = (employerTab) => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    switch (employerTab) {
      case "dashboard":
        return <div>
          {`Welcome, ${userInfo?.company_name ?? ""}`}
          <div style={{
            fontSize: "0.9rem",
          }}>{moment().format("LLLL")}</div>
        </div>;
      case "jobs":
        return viewCandidateList
          ? "Candidates"
          : isJobDetailsOpen
            ? "Job Details"
            : "Jobs";
      case "report":
        return "Report";
      case "profile":
        return "Profile";
      case "manageCoins":
        return "Transaction Logs";
      case "postJob":
        return "Post Job";
      default:
        return "Post Job";
    }
  };

  return (
    //make stepper here
    <>
      <div id="scrollToTop" className="body-overlay-1" onClick={handleRemove} />
      <Hidden mdUp>
        <PostJobSideBar
          handleOpen={handleOpen}
          handleRemove={handleRemove}
          setOpenSideBar={setOpenSideBar}
          openClass={openClass}
        />
        <PostJobHeader
          handleOpen={handleOpen}
          handleRemove={handleRemove}
          setOpenSideBar={setOpenSideBar}
          openSideBar={openSideBar}
          openClass={openClass}
          setEmployerTab={setEmployerTab}
        />
      </Hidden>
      <div className="employer-job-post-section">
        {alertMessage && alertMessage?.isOpen && (
          <CustomAlert
            show={alertMessage?.isOpen}
            message={alertMessage?.message}
            type={alertMessage?.type}
            setShow={setAlertMessage}
          />
        )}
        {isJobDetailsOpen || viewCandidateList || isJobDetailsOpen ? null : (
          <h3 style={{ marginBottom: 20, marginTop: window.innerWidth <= 900 && 100 }}>
            {title ? title : getTitle(employerTab)}
          </h3>
        )}
        {(employerTab === "" || employerTab === "postJob") && (
          <>
            {isEditJobPostDetails && (
              <h2 style={{ marginBottom: "20px" }}>Edit</h2>
            )}
            <Hidden mdUp>
              <Stepper
                orientation="horizontal"
                alternativeLabel={true}
                nonLinear
                activeStep={activeStep}
                style={{ marginBottom: "20px" }}
                className="steeper">
                {steps.map((label, index) => (
                  <Step
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    key={label}
                    completed={index < activeStep}>
                    <StepButton color="inherit">
                      <span
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: index < activeStep ? "bold" : "noraml",
                        }}>
                        {label}
                      </span>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Hidden>

            <Hidden mdDown>
              <Stepper
                orientation="horizontal"
                // alternativeLabel={true}
                nonLinear
                activeStep={activeStep}
                style={{ marginBottom: "20px" }}
                className="steeper">
                {steps.map((label, index) => (
                  <Step
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    key={label}
                    completed={index < activeStep}>
                    <StepButton color="inherit">
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: index < activeStep ? "bold" : "noraml",
                        }}>
                        {label}
                      </span>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Hidden>
          </>
        )}

        <Box style={{ height: "100%", }}>{renderComponent(activeStep)}</Box>
      </div>
    </>
  );
};

export default JobPostInfo;
