import axios from "axios";

export const apiHost = "https://api.kindjob.quickoninfotech.com/api/v1";


export const getTopRecruiters = () => {
    return axios.get(`${apiHost}/topRecruiters`);
};

export const getBrowesByCategory = () => {
    return axios.get(`${apiHost}/browseByCategory`);
};

export const getCategoryJobs = (categoryId) => {
    return axios.get(`${apiHost}/categoryJobs/${categoryId}`);
};

export const setSubscribeEmail = (body) => {
    return axios.post(`${apiHost}/subscriber`, body);
};

export const getSEOData = (body) => {
    return axios.post(`${apiHost}/getPageSEO`, body);
};

export const getStatusData = (body) => {
    if (localStorage.getItem("employerToken")) {
        return axios.post(
            `${apiHost}/activeStatus`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
                },
            }
        );
    }
};

export const employerSetupData = () => {
    return axios.get(`${apiHost}/employerSetupData`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getCollagesList = (body, token) => {
    return axios.post(`${apiHost}/getCollagesList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getDepartmentList = (body, token) => {
    return axios.post(`${apiHost}/getDepartmentList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getIndustriesList = (body, token) => {
    return axios.post(`${apiHost}/getIndustriesList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getSkillsList = (body, token) => {
    return axios.post(`${apiHost}/getSkillsList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getLanguagesList = (body, token) => {
    return axios.post(`${apiHost}/getLanguagesList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getEducationList = (body, token) => {
    return axios.post(`${apiHost}/getEducationList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getDegreeList = (body, token) => {
    return axios.post(`${apiHost}/getDegreeList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getSpecializationList = (body, token) => {
    return axios.post(`${apiHost}/getSpecializationList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};
export const getCountryList = (body, token) => {
    return axios.post(`${apiHost}/getCountryList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};
export const getStateList = (token) => {
    return axios.get(`${apiHost}/getStateList`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getCityData = (token) => {
    return axios.get(`${apiHost}/cityData`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};
export const getRoleList = (body, token) => {
    return axios.post(`${apiHost}/getRoleList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const jobSearchWithMeta = (body, token) => {
    return axios.post(`${apiHost}/jobSearchWithMeta`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getJobList = (body, token) => {
    return axios.post(`${apiHost}/getJobList`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getAreaList = (cityId, token) => {
    return axios.get(`${apiHost}/cityAreaData/${cityId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const employerSetupData2 = () => {
    return axios.get(`${apiHost}/employerSetupData2 `, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getPlansData = (noToken = false) => {
    if (noToken) {
        return axios.get(`${apiHost}/getPlan`);
    } else {
        return axios.get(`${apiHost}/getPlan `, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
            },
        });
    }
};

export const jobPost = (body) => {
    return axios.put(`${apiHost}/jobPost `, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const employerProfileUpdate = (body) => {
    return axios.put(`${apiHost}/updateEmployerProfile `, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getMainJobs = (body) => {
    return axios.post(`${apiHost}/getMainJobs`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getMainJobById = (jobId) => {
    return axios.get(`${apiHost}/getMainJobsById/${jobId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getMainJobsApplyEmployees = (jobId) => {
    return axios.get(`${apiHost}/getMainJobsApplyEmployees/${jobId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getMainJobsApplyEmployeeDetails = (userId) => {
    return axios.get(`${apiHost}/getEmployeeDetailsById/${userId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};


export const getListCoinTransaction = (body) => {
    return axios.post(`${apiHost}/listCoinTransactions`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getEmployerProfile = () => {
    return axios.get(`${apiHost}/getEmployerProfile`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const setJobPostWithPlan = (body) => {
    return axios.post(`${apiHost}/jobPackageConfirmation`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const fileUpload = (body) => {
    return axios.post(`${apiHost}/fileUpload`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};


export const getDashboardCounts = () => {
    return axios.get(`${apiHost}/getDashboardCounts`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const getMonthlyCandidateApplicationList = () => {
    return axios.get(`${apiHost}/getMonthlyCandidateApplicationList`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};
export const getLocationCountByCandidateGender = () => {
    return axios.get(`${apiHost}/getLocationCountByCandidateGender`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};
export const getTotalCandidateByAge = () => {
    return axios.get(`${apiHost}/getTotalCandidateByAge`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const contactUsInfo = (body) => {
    return axios.post(`${apiHost}/contactUsInfo`, body);
};

export const paymentOrders = (body) => {
    return axios.post(`${apiHost}/paymentOrders`, body,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
            }
        },
    );
};

export const orderPaymentsVerify = (body) => {
    return axios.post(`${apiHost}/orderPaymentsVerify`, body,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
            }
        },
    );
};


