import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const [openClass, setOpenClass] = useState("");

  const handleOpen = () => {
    document.body.classList.add("mobile-menu-active");
    setOpenClass("sidebar-visible");
  };

  const handleRemove = () => {
    if (openClass === "sidebar-visible") {
      setOpenClass("");
      document.body.classList.remove("mobile-menu-active");
    }
  };
  return (
    <>
      <div className="body-overlay-1" onClick={handleRemove} />
      {/* <AppBarr /> */}
      <Header />
      {/* <Sidebar openClass={openClass} /> */}
      <main className="main">{children}</main>
      <Footer />
      {/* <BackToTop />  */}
    </>
  );
};

export default Layout;
