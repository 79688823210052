import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider, { Context } from './Context/context';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { logoRedColor } from './components/Header';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    primary: {
      light: "blue",
      main: logoRedColor,
      dark: "blue",
    },
    secondary: {
      light: "pink",
      main: "#11cb5f",
      dark: "pink",
    },
  },
});
root.render(
  <ThemeProvider theme={theme}>
    <AppProvider>
      <App />
    </AppProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
