import { useContext, useEffect, useState } from "react";
import { Context } from "../Context/context";
import { contactUsInfo } from "../api/home";
import CustomAlert from "./CustomAlert";
import Layout from "./Layout";
import logo from "../assets/imgs/logo.svg"
import img from "../assets/imgs/img.png"
import SubscribeEmail from "./SubscribeEmail";
import SEOData from "./SEOData";


export default function Contact() {

    const { setAlertMessage, alertMessage } = useContext(Context)
    const [contactInfo, setContactInfo] = useState({
        name: "",
        companyName: "",
        email: "",
        phoneNo: "",
        message: "",
        tAndC: false,
    });

    const [error, setError] = useState({
        name: false,
        email: false,
        phoneNo: false,
        message: false,
        tAndC: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactInfo({ ...contactInfo, [name]: value });
        setError({ ...error, [name]: false });
    };

    const handleContactUsAPI = () => {
        const body = {
            name: contactInfo?.name,
            company: contactInfo.companyName,
            email: contactInfo.email,
            number: contactInfo.phoneNo,
            message: contactInfo.message,
            is_agree: contactInfo.tAndC
        }

        contactUsInfo(body).then(() => {
            setAlertMessage({
                message: "Message sent successfully",
                isOpen: true,
                type: "success",
            });
            setContactInfo({
                name: "",
                companyName: "",
                email: "",
                phoneNo: "",
                message: "",
                tAndC: false,
            })
        }).catch((err) => {
            console.log(err)
            setAlertMessage({
                message: "Something went wrong",
                isOpen: true,
                type: "danger",
            });
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setError({
            name: !contactInfo.name,
            email: !contactInfo.email,
            phoneNo: !contactInfo.phoneNo,
            tAndC: contactInfo.tAndC === false ? true : false,
        });

        if (
            contactInfo.name &&
            contactInfo.email &&
            contactInfo.phoneNo
        ) {
            handleContactUsAPI()
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <>
            {alertMessage && alertMessage?.isOpen && (
                <CustomAlert
                    show={alertMessage?.isOpen}
                    message={alertMessage?.message}
                    type={alertMessage?.type}
                    setShow={setAlertMessage}
                />
            )}
            <SEOData keyword="Contact" />
            <Layout>
                <div>
                    <section className="section-box">
                        <div className="breacrumb-cover bg-img-about">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2 className="mb-10">Contact Us</h2>
                                        <p className="font-lg color-text-paragraph-2">
                                            Get the latest news, updates and tips
                                        </p>
                                    </div>
                                    <div className="col-lg-6 text-lg-end">
                                        <ul className="breadcrumbs mt-40">
                                            <li>
                                                <a className="home-icon" href="/">
                                                    Home
                                                </a>
                                            </li>
                                            <li>Contact</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="section-box mt-80">
                        <div className="container">
                            <div className="box-info-contact">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                                        <a href="#">
                                            <img
                                                src={logo}
                                                alt="joxBox"
                                            />
                                        </a>
                                        <div className="font-sm color-text-paragraph">
                                            205 North Michigan Avenue, Suite 810 Chicago, 60601, USA
                                            <br /> Phone: (123) 456-7890
                                            <br /> Email: contact@jobbox.com
                                        </div>
                                        <a
                                            className="text-uppercase color-brand-2 link-map"
                                            href="#">
                                            View map
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                                        <h6>London</h6>
                                        <p className="font-sm color-text-paragraph mb-20">
                                            2118 Thornridge Cir. Syracuse,
                                            <br className="d-none d-lg-block" /> Connecticut 35624
                                        </p>
                                        <h6>New York</h6>
                                        <p className="font-sm color-text-paragraph mb-20">
                                            4517 Washington Ave.
                                            <br className="d-none d-lg-block" /> Manchester, Kentucky
                                            39495
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                                        <h6>Chicago</h6>
                                        <p className="font-sm color-text-paragraph mb-20">
                                            3891 Ranchview Dr. Richardson,
                                            <br className="d-none d-lg-block" /> California 62639
                                        </p>
                                        <h6>San Francisco</h6>
                                        <p className="font-sm color-text-paragraph mb-20">
                                            4140 Parker Rd. Allentown,
                                            <br className="d-none d-lg-block" /> New Mexico 31134
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                                        <h6>Sysney</h6>
                                        <p className="font-sm color-text-paragraph mb-20">
                                            3891 Ranchview Dr. Richardson,
                                            <br className="d-none d-lg-block" /> California 62639
                                        </p>
                                        <h6>Singapore</h6>
                                        <p className="font-sm color-text-paragraph mb-20">
                                            4140 Parker Rd. Allentown,
                                            <br className="d-none d-lg-block" /> New Mexico 31134
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section className="section-box mt-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mb-40">
                                    <span className="font-md color-brand-2 mt-20 d-inline-block">
                                        Contact us
                                    </span>
                                    <h2 className="mt-5 mb-10">Get in touch</h2>
                                    <p className="font-md color-text-paragraph-2">
                                        The right move at the right time saves your investment. live
                                        <br className="d-none d-lg-block" /> the dream of expanding
                                        your business.
                                    </p>
                                    <form
                                        className="contact-form-style mt-30"
                                        id="contact-form"
                                        action="#"
                                        method="post">
                                        <div
                                            className="row wow animate__animated animate__fadeInUp"
                                            data-wow-delay=".1s">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input-style mb-20">
                                                    <input
                                                        className="font-sm color-text-paragraph-2"
                                                        name="name"
                                                        value={contactInfo.name}
                                                        onChange={handleChange}
                                                        placeholder="Enter your name"
                                                        type="text"
                                                    />
                                                    {error.name && (
                                                        <p
                                                            className="text-danger"
                                                            style={{ fontSize: "12px" }}>
                                                            Name is required
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input-style mb-20">
                                                    <input
                                                        className="font-sm color-text-paragraph-2"
                                                        name="companyName"
                                                        value={contactInfo.companyName}
                                                        onChange={handleChange}
                                                        placeholder="Comapy (optional)"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input-style mb-20">
                                                    <input
                                                        className="font-sm color-text-paragraph-2"
                                                        name="email"
                                                        value={contactInfo.email}
                                                        onChange={handleChange}
                                                        placeholder="Your email"
                                                        type="email"
                                                    />
                                                    {error.email && (
                                                        <p
                                                            className="text-danger"
                                                            style={{ fontSize: "12px" }}>
                                                            Email is required
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input-style mb-20">
                                                    <input
                                                        className="font-sm color-text-paragraph-2"
                                                        name="phoneNo"
                                                        value={contactInfo.phoneNo}
                                                        onChange={handleChange}
                                                        placeholder="Phone number"
                                                        type="tel"
                                                    />
                                                    {error.phoneNo && (
                                                        <p
                                                            className="text-danger"
                                                            style={{ fontSize: "12px" }}>
                                                            Phone number is required
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="textarea-style mb-30">
                                                    <textarea
                                                        className="font-sm color-text-paragraph-2"
                                                        name="message"
                                                        value={contactInfo.message}
                                                        onChange={handleChange}
                                                        placeholder="Tell us about yourself"
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <label className="mb-20">
                                                    <input
                                                        className="float-start mr-5 mt-6"
                                                        name="tAndC"
                                                        value={contactInfo.tAndC}
                                                        onChange={(e) => {
                                                            setContactInfo({
                                                                ...contactInfo,
                                                                tAndC: e.target.checked,
                                                            });
                                                            setError({ ...error, tAndC: false });
                                                        }}
                                                        type="checkbox"
                                                    />{" "}
                                                    By clicking contact us button, you agree our terms and
                                                    policy,
                                                    {/* {error.tAndC && (
                            <p
                              className="text-danger"
                              style={{ fontSize: "12px" }}>
                              Please agree our terms and policy
                            </p>
                          )} */}
                                                </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <button
                                                    onClick={handleSubmit}
                                                    className=" buttonCustom"
                                                    type="submit">
                                                    Send message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <p className="form-messege" />
                                </div>
                                <div className="col-lg-4 text-center d-none d-lg-block">
                                    <img src={img} alt="joxBox" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <SubscribeEmail />
                </div>
            </Layout>
        </>
    );
}
