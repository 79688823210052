/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/context";
import { getAreaList, getCityData, jobPost } from "../../api/home";
import CustomReactSelect from "../CustomReactSelect";
import CustomReactSelectCreate from "../CustomReactSelectCreate";
import {
  SubHeader,
  statusDropdownColorStyles
} from "./BasicDetailsForm";
import { lightRedLogoColor, logoRedColor } from "./JobPostInfo";

const InterviewerInfo = () => {
  const [loading, setLoading] = useState(false);
  const {
    isEditJobPostDetails,
    interviewerInfo,
    setInterviewerInfo,
    cityDataOption,
    setCityDataOption,
    areaDataOption,
    setAreaDataOption,
    token,
    activeStep,
    setActiveStep,
    basicDetails,
    candidateRequirement,
    setAlertMessage,
    setPostJobId,
    selectedDepartment,
  } = useContext(Context);


  const [interviewerDetailsError, setInterviewerDetailsError] = useState(false);
  const [recruiterNameError, setRecruiterNameError] = useState(false);
  const [HRWhatsappNumberError, setHRWhatsappNumberError] = useState(false);
  const [contactNoError, setContactNoError] = useState(false);
  const [typeOfInterviewError, setTypeOfInterviewError] = useState(false);
  const [interviewerCityError, setInterviewerCityError] = useState(false);
  const [interviewerAreaError, setInterviewerAreaError] = useState(false);
  const [interviewerAddressError, setInterviewerAddressError] = useState(false);

  const [manageCandidateError, setManageCandidateError] = useState(false);
  const [scrollId, setScrollId] = useState("");

  let emptyError = false;

  const scrollToTop = () => {
    try {
      let element = document.getElementById(scrollId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setScrollId("scrollToTop");
    scrollToTop();
  }, [activeStep, scrollId]);

  const radioButtonCss = {
    "&, &.Mui-checked": {
      color: logoRedColor,
    },
  };

  const getAreaData = (e) => {
    getAreaList(e?.value, token)
      .then((res) => {
        setAreaDataOption(res?.data?.data?.area_ata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCityData(token)
      .then((res) => {
        const ids = res?.data?.data?.city_ata.map(({ city_name }) => city_name);
        const filtered = res?.data?.data?.city_ata.filter(({ city_name }, index) =>
          !ids.includes(city_name, index + 1));
        setCityDataOption(filtered);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleJobPost = () => {
    setLoading(true);
    const data = {
      main_job_id: 0,
      is_compnay: basicDetails?.isCompany === 1 ? true : false,
      department_id: basicDetails?.department?.value,
      role_id: basicDetails?.category?.value,
      job_title: basicDetails?.jobTitle,
      job_type_id: basicDetails?.jobType?.value,
      is_night_shift_job: basicDetails?.nightShift,
      location_type: basicDetails?.jobLocation,
      work_from_office: {
        city_id:
          basicDetails?.jobLocation === 1 ? basicDetails?.jobCity?.value : "",
        area_id:
          basicDetails?.jobLocation === 1 ? basicDetails?.jobArea?.value : "",
        company_address:
          basicDetails?.jobLocation === 1 ? basicDetails?.address : "",
        receive_applications_from:
          basicDetails?.jobLocation === 1
            ? Number(basicDetails?.applicationFrom)
            : "",
        // applications_from_india: basicDetails?.receiveCandidateFrom
      },
      work_from_home: {
        receive_applications_from:
          basicDetails?.jobLocation === 2
            ? basicDetails?.wfhReceiveApplication
            : "",
        city_id:
          basicDetails?.jobLocation === 2 ? basicDetails?.jobCity?.value : "",
      },
      field_jobs: {
        field_job_for:
          basicDetails?.jobLocation === 3 ? basicDetails?.fieldJobFor : "",
        city_id:
          basicDetails?.jobLocation === 3 ? basicDetails?.jobCity?.value : "",
        area_id:
          basicDetails?.jobLocation === 3 ? basicDetails?.jobArea?.value : "",
        receive_applications_from:
          basicDetails?.jobLocation === 3 ? basicDetails?.wfhFieldJob : "",
        // applications_from_india: basicDetails?.jobLocation === 3 ? basicDetails?.receiveCandidateFrom : ""
      },
      pay_type: Number(basicDetails?.payType),
      minimum_salary: Number(basicDetails?.minFixedSalary),
      maximum_salary: Number(basicDetails?.maxFixedSalary),
      incentive: basicDetails?.averageIncentive,
      additional_perks_id: basicDetails?.additionalPerks,
      other_additional_perks: basicDetails?.otherAdditionalPerks,
      is_joining_fee_deposit_required:
        basicDetails.deposit === "1" ? true : false,
      joining_fee_deposit: {
        fee_amount:
          basicDetails?.deposit === "1" ? basicDetails?.depositAmount : "",
        joining_fee_type_id:
          basicDetails?.deposit === "1" ? basicDetails?.depositType?.value : "",
        extra:
          basicDetails?.deposit === "1" ? basicDetails?.depositOther : "heke",
        fee_be_paid:
          basicDetails?.deposit === "1" ? basicDetails?.feeBePaidBy : "",
      },
      education: candidateRequirement.education.value,
      degree: candidateRequirement.degreeFreference?.map((item) =>
        Number(item?.value)
      ),
      gender: Number(candidateRequirement.gender),
      age_criteria: candidateRequirement.anyAgeCriteria === 1 ? true : false,
      age_data: {
        minimum_age:
          candidateRequirement.anyAgeCriteria === "1"
            ? candidateRequirement?.ageCriteria?.minAge
            : "",
        maximum_age:
          candidateRequirement.anyAgeCriteria === "1"
            ? candidateRequirement?.ageCriteria?.maxAge
            : "",
      },
      experience: Number(candidateRequirement.experience),
      experience_data: Number(candidateRequirement.minExperience),
      candidate_department:
        candidateRequirement.whichDepartment === "1"
          ? [selectedDepartment?.department_id]
          : [Number(candidateRequirement.whichDepartment)],
      candidates_industry:
        candidateRequirement.candidateFromWhichDepartment.map(
          (item) => item?.value
        ),
      english_level: Number(candidateRequirement.englishLevel),
      skills: candidateRequirement.skills,
      asset: candidateRequirement.assets,
      job_description: candidateRequirement.jobDescription,
      interviewer: interviewerInfo.interviewerDetails === "mySelf" ? 1 : 2,
      interviewer_details: {
        recruiter_name:
          interviewerInfo.interviewerDetails === "other"
            ? interviewerInfo.recruiterName
            : "",
        hr_hatsapp_number:
          interviewerInfo.interviewerDetails === "other"
            ? interviewerInfo.HRWhatsappNumber
            : "",
        contact_email:
          interviewerInfo.interviewerDetails === "other"
            ? interviewerInfo.contactNo
            : "",
      },
      interview_type: Number(interviewerInfo.typeOfInterview),
      interview_location: {
        city_id: interviewerInfo.interviewerCity?.value || "",
        area_id: interviewerInfo.interviewerArea?.value || "",
        address: interviewerInfo.interviewerAddress,
      },
      manage_job: interviewerInfo.manageCandidate,
    };
    jobPost(data)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setActiveStep(activeStep + 1);
          setPostJobId(res?.data?.data?.main_job_id);
        }
        setLoading(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAlertMessage({
          message: err?.response?.data?.message,
          isOpen: true,
          type: "danger",
        });
      });
  };

  const handleContinue = () => {
    if (interviewerInfo?.interviewerDetails === "") {
      setInterviewerDetailsError(true);
      emptyError = true;
    } else {
      setInterviewerDetailsError(false);
    }

    if (interviewerInfo?.interviewerDetails === "other") {
      if (interviewerInfo?.recruiterName === "") {
        setRecruiterNameError(true);
        emptyError = true;
      } else {
        setRecruiterNameError(false);
      }
      if (interviewerInfo?.HRWhatsappNumber === "") {
        setHRWhatsappNumberError(true);
        emptyError = true;
      } else {
        setHRWhatsappNumberError(false);
      }
      if (interviewerInfo?.contactNo === "") {
        setContactNoError(true);
        emptyError = true;
      } else {
        setContactNoError(false);
      }
    }

    if (interviewerInfo?.typeOfInterview === "") {
      setTypeOfInterviewError(true);
      emptyError = true;
    } else {
      setTypeOfInterviewError(false);
    }

    if (interviewerInfo?.typeOfInterview === "1") {
      if (interviewerInfo?.interviewerCity === "") {
        setInterviewerCityError(true);
        emptyError = true;
      } else {
        setInterviewerCityError(false);
      }

      if (interviewerInfo?.interviewerArea === "") {
        setInterviewerAreaError(true);
        emptyError = true;
      } else {
        setInterviewerAreaError(false);
      }

      if (interviewerInfo?.interviewerAddress === "") {
        setInterviewerAddressError(true);
        emptyError = true;
      } else {
        setInterviewerAddressError(false);
      }
    }

    if (interviewerInfo?.manageCandidate === "") {
      setManageCandidateError(true);
      emptyError = true;
    } else {
      setManageCandidateError(false);
    }

    if (emptyError) {
      setAlertMessage({
        message: "Please fill all the required fields",
        isOpen: true,
        type: "danger",
      });
      return;
    } else {
      handleJobPost();
      console.log("done");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [error, setError] = useState({
    interviewerDetails: false,
    recruiterName: false,
    HRWhatsappNumber: false,
    contactNo: false,
    typeOfInterview: false,
    interviewerCity: false,
    interviewerArea: false,
    interviewerAddress: false,
    companyAddressSameAsInterviewerAddress: false,
    companyCity: false,
    companyArea: false,
    companyAddress: false,
    manageCandidate: false,
  });

  const handleSelectChange = (value, name) => {
    setInterviewerInfo({ ...interviewerInfo, [name]: value });
    setError({ ...error, [name]: false });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    //if array then push else set
    if (Array.isArray(interviewerInfo[name])) {
      if (interviewerInfo[name].includes(value)) {
        setInterviewerInfo({
          ...interviewerInfo,
          [name]: interviewerInfo[name].filter((item) => item !== value),
        });
      } else {
        setInterviewerInfo({
          ...interviewerInfo,
          [name]: [...interviewerInfo[name], value],
        });
      }
    } else {
      setInterviewerInfo({
        ...interviewerInfo,
        [name]: value.trimStart(),
      });
    }
    setError({ ...error, [name]: false });
  };

  // const handleContinue = () => {
  //   // setError({
  //   //     interviewerDetails: interviewerInfo.interviewerDetails === "" ? true : false,
  //   //     recruiterName: interviewerInfo.recruiterName === "" ? true : false,
  //   //     HRWhatsappNumber: interviewerInfo.HRWhatsappNumber === "" ? true : false,
  //   //     contactNo: interviewerInfo.contactNo === "" ? true : false,
  //   //     typeOfInterview: interviewerInfo.typeOfInterview === "" ? true : false,

  //   //     interviewerCity: interviewerInfo.interviewerCity === "" ? true : false,
  //   //     interviewerArea: interviewerInfo.interviewerArea === "" ? true : false,
  //   //     interviewerAddress: interviewerInfo.interviewerAddress === "" ? true : false,
  //   //     // companyAddressSameAsInterviewerAddress: interviewerInfo.companyAddressSameAsInterviewerAddress === "" ? true : false,
  //   //     companyCity: interviewerInfo.companyCity === "" ? true : false,
  //   //     companyArea: interviewerInfo.companyArea === "" ? true : false,
  //   //     companyAddress: interviewerInfo.companyAddress === "" ? true : false,
  //   //     manageCandidate: interviewerInfo.manageCandidate === "" ? true : false
  //   // })

  //   setActiveStep(activeStep + 1);
  //   // if (interviewerInfo.interviewerDetails === "" || interviewerInfo.recruiterName === "" || interviewerInfo.HRWhatsappNumber === "" || interviewerInfo.contactNo === "" || interviewerInfo.typeOfInterview === "" || interviewerInfo.interviewerCity === "" || interviewerInfo.interviewerArea === "" || interviewerInfo.interviewerAddress === "" || interviewerInfo.companyCity === "" || interviewerInfo.companyArea === "" || interviewerInfo.companyAddress === "" || interviewerInfo.manageCandidate === ""
  //   // ) {
  //   //     // console.log(data)
  //   //     setActiveStep(activeStep + 1)
  //   // }
  // };

  return (
    <Grid container spacing={3} style={{ height: "100%" }}>
      <Grid item xs={12}>
        <h4>Interviewer Info</h4>
      </Grid>
      <Grid item xs={12}>
        <SubHeader
          title={"Who would be connecting with candidates for this job ?"}
          isRequired={true}
        />
        <RadioGroup
          row
          aria-label="interviewerDetails"
          name="interviewerDetails"
          value={interviewerInfo.interviewerDetails}
          onChange={(e) => {
            handleChange(e);
            setInterviewerDetailsError(false);
          }}>
          <FormControlLabel
            value="mySelf"
            control={<Radio sx={{ ...radioButtonCss }} />}
            label="MySelf"
          />
          <FormControlLabel
            value="other"
            control={<Radio sx={{ ...radioButtonCss }} />}
            label="Other Recruiter"
          />
        </RadioGroup>
        {interviewerDetailsError && (
          <div style={{ color: "red", fontSize: "12px" }}>Please select</div>
        )}
      </Grid>
      {interviewerInfo.interviewerDetails === "other" && (
        <>
          <Grid item md={6} xs={12} className="form-group">
            <SubHeader title={"Recruiter Name"} isRequired={true} />
            <input
              className="form-control"
              name="recruiterName"
              value={interviewerInfo.recruiterName}
              onChange={(e) => {
                handleChange(e);
                setRecruiterNameError(false);
              }}
              placeholder="Recruiter name"
            />
            {recruiterNameError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please enter</div>
            )}
          </Grid>
          <Grid item md={6} xs={12} className="form-group">
            <SubHeader title={"HR whatsapp no."} isRequired={true} />
            <input
              className="form-control"
              type="number"
              name="HRWhatsappNumber"
              value={interviewerInfo.HRWhatsappNumber}
              onChange={
                // its shuld be number only and length should be 10
                (e) => {
                  setHRWhatsappNumberError(false);
                  if (interviewerInfo.HRWhatsappNumber.length < 10) {
                    handleChange(e);
                  }
                }
              }
              placeholder="HR. whatsapp no."
            />
            {HRWhatsappNumberError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please enter valid number</div>
            )}
          </Grid>
          <Grid item xs={12} className="form-group">
            <SubHeader title={"Contact Email."} isRequired={true} />
            <input
              className="form-control"
              name="contactNo"
              value={interviewerInfo.contactNo}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder="contact email"
            />
            {contactNoError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please enter valid email</div>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider style={{ backgroundColor: "black" }} />
      </Grid>
      <Grid item xs={12}>
        <h4>
          Interview method and address
          <div style={{ fontSize: "12px", color: "grey" }}>
            Let the candidates know how interviews will be conducted for this job.
          </div>
        </h4>
      </Grid>

      <Grid item xs={12}>
        <SubHeader title={"Type of Interview"} isRequired={true} />
        <RadioGroup
          row
          aria-label="typeOfInterview"
          name="typeOfInterview"
          value={interviewerInfo.typeOfInterview}
          onChange={(e) => {
            handleChange(e);
            setTypeOfInterviewError(false);
          }}>
          <FormControlLabel
            value={1}
            control={<Radio sx={{ ...radioButtonCss }} />}
            label="In-person interview"
          />
          <FormControlLabel
            value={2}
            control={<Radio sx={{ ...radioButtonCss }} />}
            label="Telephonic/Online interview"
          />
        </RadioGroup>
        {typeOfInterviewError && (
          <div style={{ color: "red", fontSize: "12px" }}>Please select</div>
        )}
      </Grid>
      {interviewerInfo.typeOfInterview === "1" && (
        <>
          <Grid item md={6} xs={12}>
            <SubHeader title={"Interviewer City"} isRequired={true} />

            <CustomReactSelect
              styles={statusDropdownColorStyles}
              id="outlined-select"
              fullWidth
              options={cityDataOption?.map((item) => {
                return {
                  label: item?.city_name,
                  value: item?.city_id,
                };
              })}
              name="interviewerCity"
              value={interviewerInfo.interviewerCity?.value}
              onChange={(e) => {
                setInterviewerCityError(false);
                handleSelectChange(e, "interviewerCity");
                getAreaData(e);
              }}
              label="Job City"
            />
            {interviewerCityError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please select</div>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <SubHeader title={"Interviewer Area"} isRequired={true} />
            <CustomReactSelectCreate
              styles={statusDropdownColorStyles}
              id="outlined-select"
              select
              fullWidth
              options={areaDataOption?.map((item) => {
                return {
                  label: item?.area_name,
                  value: item?.area_id,
                };
              })}
              name="interviewerArea"
              value={interviewerInfo.interviewerArea?.value}
              onChange={(e) => {
                setInterviewerAreaError(false);
                handleSelectChange(e, "interviewerArea");
              }}
              label="Area"
            />
            {interviewerAreaError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please select</div>
            )}
          </Grid>
          <Grid item xs={12} className="form-group">
            <SubHeader title={"Interviewer Address"} isRequired={true} />
            <input
              className="form-control"
              name="interviewerAddress"
              value={interviewerInfo.interviewerAddress}
              onChange={(e) => {
                setInterviewerAddressError(false);
                handleChange(e);
              }}
              placeholder={"Interviewer Address"}
            />
            {interviewerAddressError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please enter valid address</div>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider style={{ backgroundColor: "black" }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={6} sm={6} xs={12}>
            <div
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  interviewerInfo.manageCandidate === 1
                    ? lightRedLogoColor
                    : "white",
                border:
                  interviewerInfo.manageCandidate === 1
                    ? `1px solid ${logoRedColor}`
                    : "1px solid black",
              }}
              onClick={() => {
                setManageCandidateError(false);
                setInterviewerInfo({
                  ...interviewerInfo,
                  manageCandidate: 1,
                });
              }}>
              <Grid conatiner direction="column">
                <Grid
                  item
                  style={{
                    // border: "1px solid black",
                    borderBottom: "none",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}>
                  Calls + Whatsapp
                </Grid>
                <Grid
                  item
                  style={{
                    borderTop: "1px solid black",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                  <div style={{ marginTop: "5px" }}>
                    <DoneIcon style={{ fontSize: "1rem", color: "green" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Call</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Candidate can call you (09:00 am to 06:00 pm)
                    </span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <DoneIcon style={{ fontSize: "1rem", color: "green" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Whatsapp</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Candidates can reach you by sending you message and their resume on WhatsApp.
                    </span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <DoneIcon style={{ fontSize: "1rem", color: "green" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Website</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Manage applications on website
                    </span>
                  </div>
                  {/* <Grid item>
                                        <Button variant="outlined"

                                            style={{
                                                // backgroundColor: logoRedColor, 
                                                color: logoRedColor,
                                                borderColor: logoRedColor,
                                                fontSize: "0.6rem",
                                                borderRadius: "30px", width: "100%",
                                                marginTop: "10px"
                                            }}>
                                            Select
                                        </Button>
                                    </Grid> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <div
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  interviewerInfo.manageCandidate === 2
                    ? lightRedLogoColor
                    : "white",
                border:
                  interviewerInfo.manageCandidate === 2
                    ? `1px solid ${logoRedColor}`
                    : "1px solid black",
              }}
              onClick={() => {
                setManageCandidateError(false);
                setInterviewerInfo({
                  ...interviewerInfo,
                  manageCandidate: 2,
                });
              }}>
              <Grid conatiner direction="column">
                <Grid
                  item
                  style={{
                    // border: "1px solid black",
                    borderBottom: "none",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}>
                  Whatsapp
                </Grid>
                <Grid
                  item
                  style={{
                    borderTop: "1px solid black",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                  <div style={{ marginTop: "5px" }}>
                    <CloseIcon style={{ fontSize: "1rem", color: "red" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Call</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Candidate can call you (09:00 am to 06:00 pm)
                    </span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <DoneIcon style={{ fontSize: "1rem", color: "green" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Whatsapp</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Candidates can reach you by sending you message and their resume on WhatsApp.
                    </span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <DoneIcon style={{ fontSize: "1rem", color: "green" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Website</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Manage applications on website
                    </span>
                  </div>
                  {/* <Grid item>
                                        <Button variant="outlined"
                                            onClick={() => {
                                                setInterviewerInfo({
                                                    ...interviewerInfo,
                                                    manageCandidate: 2
                                                })

                                            }}
                                            style={{
                                                // backgroundColor: logoRedColor, 
                                                color: logoRedColor,
                                                borderColor: logoRedColor,
                                                fontSize: "0.6rem",
                                                borderRadius: "30px", width: "100%",
                                                marginTop: "10px"
                                            }}>
                                            Select
                                        </Button>
                                    </Grid> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* <Grid item lg={4} sm={4} xs={12}>
            <div
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  interviewerInfo.manageCandidate === 3
                    ? lightRedLogoColor
                    : "white",
                border:
                  interviewerInfo.manageCandidate === 3
                    ? `1px solid ${logoRedColor}`
                    : "1px solid black",
              }}
              onClick={() => {
                setManageCandidateError(false);
                setInterviewerInfo({
                  ...interviewerInfo,
                  manageCandidate: 3,
                });
              }}>
              <Grid conatiner direction="column">
                <Grid
                  item
                  style={{
                    borderBottom: "none",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}>
                  Website + Excel Download
                </Grid>
                <Grid
                  item
                  style={{
                    borderTop: "1px solid black",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                  <div style={{ marginTop: "5px" }}>
                    <CloseIcon style={{ fontSize: "1rem", color: "red" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Call</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Candidate can call you (09:00 am to 06:00 pm)
                    </span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <CloseIcon style={{ fontSize: "1rem", color: "red" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Whatsapp</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Kindjob will send all applications in a single WhatsApp
                      chat
                    </span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <DoneIcon style={{ fontSize: "1rem", color: "green" }} />{" "}
                    <span style={{ fontWeight: "600" }}>Website</span>:{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      Manage applications on website
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid> */}
        </Grid>
        {manageCandidateError && (
          <div style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
            Please select an option
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          gap: "20px",
        }}>
        <Button
          variant="contained"
          onClick={handleBack}
          style={{ width: "30%", backgroundColor: "gray" }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleContinue}
          style={{ width: "30%", backgroundColor: "green" }}>
          {loading ? (
            <CircularProgress
              style={{ color: "white", height: "25px", width: "25px" }}
            />
          ) : (
            "Continue"
          )}
        </Button>
      </Grid>
      {isEditJobPostDetails && (
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}>
          <Button
            variant="outlined"
            style={{
              width: "30%",
              borderColor: logoRedColor,
              color: logoRedColor,
            }}>
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default InterviewerInfo;
