/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { logoRedColor } from "./Header";
import apple from "../assets/imgs/apple.JPG";
import googleplay from "../assets/imgs/googleplay.JPG";
import logo from "../assets/imgs/logo.png";
import moment from "moment";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import { Icon } from "@mui/material";

export const handleDownloadApp = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const Footer = () => {
  const navigate = useNavigate();
  const comingSoon = () => {
    Swal.fire({
      icon: "info",
      confirmButtonColor: logoRedColor,
      title: "Coming Soon!",
    });
  };
  return (
    <footer className="footer" style={{ marginTop: "20px" }}>
      <div className="container">
        <div className="row">
          <div className="footer-col-1 col-md-3 col-sm-12">
            <Link legacyBehavior href="/">
              <img alt="kindjob" style={{ width: "200px" }} src={logo} />
            </Link>
            <div className="mb-20 font-xs color-text-paragraph-2">
              Kind Jobs is the only job site in world that works on job - talent match model. Focused on showing
              only relevant jobs to job seekers and providing filtered candidates to the employers, it makes the only website
              that helps the most to both i.e job seekers & employers.
            </div>
            {/* <div className="footer-social">
              <a className="icon-socials icon-facebook" href="#" />
              <a className="icon-socials icon-twitter" href="#" />
              <a className="icon-socials icon-linkedin" href="#" />
            </div> */}
          </div>
          <div className="footer-col-2 col-md-2 col-xs-6">
            <h6 className="mb-20">Resources</h6>
            <ul className="menu-footer" style={{ paddingLeft: "0px" }}>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/about");
                  }}>
                  About us
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/contact");
                  }}>
                  Contact
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/pricing");
                  }}>
                  Pricing
                </a>
              </li>
            </ul>
          </div>

          {/* <div className="footer-col-4 col-md-2 col-xs-6">
                        <h6 className="mb-20">Quick links</h6>
                        <ul className="menu-footer" style={{ paddingLeft: "0px" }}>
                            <li>
                                <a href="#">iOS</a>
                            </li>
                            <li>
                                <a href="#">Android</a>
                            </li>
                        </ul>
                    </div> */}
          <div className="footer-col-3 col-md-2 col-xs-6">
            <h6 className="mb-20">More</h6>
            <ul className="menu-footer" style={{ paddingLeft: "0px" }}>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/privacy");
                  }}>
                  Privacy
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/refund-policy");
                  }}>
                  Refund Policy
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/terms");
                  }}>
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/termsService");
                  }}>
                  Terms & Services
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/employer");
                  }} >FAQ</a>
              </li>
            </ul>
          </div>
          <div className="footer-col-6 col-md-2 col-xs-12">
            <h6 className="mb-20">Contact</h6>
            <ul className="menu-footer" style={{ paddingLeft: "0px" }}>
              <li
                style={{
                  display: "flex",
                  gap: "10px",
                  paddingBottom: "10px",
                }}>
                <Icon>
                  <PlaceIcon />
                </Icon>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#131313",
                  }}>
                  Office No 1103, One World West, Vakil Saheb Bridge, Bopal, <br />
                  Ahmedabad - 380058
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  gap: "10px",
                  paddingBottom: "10px",
                }}>
                <Icon>
                  <CallIcon />
                </Icon>
                <a style={{ cursor: "pointer" }} href="tel:+919979111393">
                  9979111393
                </a>
              </li>
              <li
                style={{
                  display: "flex",
                  gap: "10px",
                }}>
                <Icon>
                  <EmailIcon />
                </Icon>
                <a
                  style={{ cursor: "pointer" }}
                  href="mailto:support@kindjobs.co">
                  support@kindjobs.co
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col-5 col-md-3 col-sm-12">
            <h6 className="mb-20">Download App</h6>
            <p className="color-text-paragraph-2 font-xs">
              {/* Download our Apps and get extra 15% Discount on your first Order…! */}
            </p>
            <div
              className="mt-15"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <img
                onClick={() =>
                  handleDownloadApp(
                    "https://play.google.com/store/apps/details?id=com.kindjobs"
                  )
                }
                className="app-btn"
                src={googleplay}
                alt="android"
                style={{ cursor: "pointer" }}
              />
              <img
                onClick={() =>
                  handleDownloadApp(
                    "https://apps.apple.com/in/app/kind-jobs/id6448302467"
                  )
                }
                className="app-btn"
                src={apple}
                alt="apple"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <span className="font-xs color-text-paragraph">
                Copyright © {moment().year() || 2024}. Powered by Searchline Database Private Limited (Kind jobs). All rights reserved.
              </span>
            </div>
            <div
              className="col-md-6 text-md-end text-start"
              style={{ color: "black" }}>
              <div className="footer-social">
                <a
                  style={{ cursor: "pointer" }}
                  className="font-xs color-text-paragraph"
                  onClick={() => {
                    navigate("/refund-policy");
                  }}>
                  Refund Policy
                </a>
                <a
                  onClick={() => {
                    navigate("/privacy");
                  }}
                  className="font-xs color-text-paragraph  ml-30"
                  style={{ cursor: "pointer" }}>
                  Privacy Policy
                </a>
                <a
                  onClick={() => {
                    navigate("/terms");
                  }}
                  style={{ cursor: "pointer" }}
                  className="font-xs color-text-paragraph ml-30">
                  Terms &amp; Conditions
                </a>
                <a
                  onClick={() => {
                    navigate("/termsService");
                  }}
                  style={{ cursor: "pointer" }}
                  className="font-xs color-text-paragraph mr-30 ml-30">
                  Terms &amp; Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
