/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncyEffect } from "use-debouncy";
import { Context } from "../../Context/context";
import { getAreaList, getCityData, jobSearchWithMeta } from "../../api/home";
import CustomReactSelect from "../CustomReactSelect";
import CustomReactSelectCreate from "../CustomReactSelectCreate";
import { logoRedColor } from "../Header";
import { lightRedLogoColor } from "./JobPostInfo";
import client from "../../assets/imgs/svgs/Client.svg"

//styled components

export const Header = styled("h6")({
  fontSize: "16px",
  fontWeight: "bold",
  marginBottom: "4px",
  color: "#000",
  // marginTop: "15px",
});

export const SubHeader = ({ title, isRequired }) => {
  return (
    <Box style={{ display: "flex" }}>
      {/* <div className="font-sm color-text-mutted mb-10"> */}
      <Header>{title}</Header>
      {/* </div> */}
      {isRequired && <span style={{ color: "red" }}>*</span>}
    </Box>
  );
};

export const errorAlert = (setAlertMessage, err, navigate, setLogin, login) => {
  if (
    err?.response?.data?.message === "Invalid Token." ||
    err?.response?.data?.statusCode === 401
  ) {
    setAlertMessage({
      message: "Session expired",
      isOpen: true,
      type: "danger",
    });
    localStorage.clear();
    navigate("/employer/login");
    setLogin({
      ...login,
      detailsPage: false,
      setPhone: true,
    });
  }
};

export const TextFieldCustom = ({
  variant,
  type = "text",
  value,
  placeholder,
  onChange,
  name,
  required = "false",
  style,
}) => {
  return (
    <TextField
      // classes={{ root: 'MuiOutlined-root' }}
      id="outlined-select"
      // label="Company you are hiring for"
      variant={variant}
      fullWidth
      required={required}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      sx={{
        ...style,
        "& input::placeholder": {
          fontSize: "16px",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#F0F0F0",
          },
          "&:hover fieldset": {
            borderColor: "#F0F0F0",
          },
          "&.Mui-focused fieldset": {
            borderColor: logoRedColor,
          },
        },
      }}
      inputProps={{
        style: {
          padding: "10px",
          height: "25px",
        },
      }}
      InputLabelProps={{ shrink: false }}
      placeholder={placeholder}
    />
  );
};

export const TextFieldCustomProfile = ({
  variant,
  type = "text",
  value,
  placeholder,
  onChange,
  name,
  required = "false",
  style,
}) => {
  return (
    <TextField
      // classes={{ root: 'MuiOutlined-root' }}
      id="outlined-select"
      // label="Company you are hiring for"
      variant={variant}
      fullWidth
      required={required}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      sx={{
        ...style,
        "& input::placeholder": {
          fontSize: "16px",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#F0F0F0",
          },
          "&:hover fieldset": {
            borderColor: "#F0F0F0",
          },
          "&.Mui-focused fieldset": {
            borderColor: logoRedColor,
          },
        },
      }}
      inputProps={{
        style: {
          padding: "10px",
          height: "15px",
        },
      }}
      InputLabelProps={{ shrink: false }}
      placeholder={placeholder}
    />
  );
};

export const statusDropdownColorStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    width: "100%",
    // fontSize: "1rem",
    color: "#7A6666",
    boxShadow: "none",
    border: `${isFocused || isSelected ? "2px" : "1px"} solid ${isFocused || isSelected ? logoRedColor : "#cccccc"
      }`,
    height: "calc(1.5em + 1.3rem + 4px) !important",
    "&:hover": {
      border: `${isFocused || isSelected ? "2px" : "1px"} solid ${isFocused || isSelected ? logoRedColor : "#cccccc"
        }`,
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 1301 }),
  menu: (styles) => ({
    ...styles,
    width: "100%",
    zIndex: 1301,
  }),
  input: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: "black",
    margin: "0px",
    paddingBottom: "0px",
    height: "calc(1.5em + 1.2rem + 2px) !important",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
        ? logoRedColor
        : isFocused
          ? logoRedColor
          : null,
    color: isFocused ? "white" : isSelected ? "white" : "black",
    cursor: isDisabled ? "not-allowed" : "default",

    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled && isSelected && "rgba(0, 51, 102)",
      color: "white",
    },
  }),
};

const BasicDetailsForm = () => {
  const {
    additionalPerksOption,
    departmentDataOption,
    jobTypeDataOption,
    joiningFeeTypeOption,
    payTypeOption,
    roleDataOption,
    token,
    isEditJobPostDetails,
    basicDetails,
    setBasicDetails,
    cityDataOption,
    setCityDataOption,
    areaDataOption,
    setAreaDataOption,
    activeStep,
    setActiveStep,
    setAlertMessage,
    setLogin,
    login,
    setSelectedDepartment,
    jobRoleData,
    setJobRoleData,
  } = useContext(Context);

  const [jobRoleOpen, setJobRoleOpen] = React.useState(false);
  const [jobTitleOption, setJobTitleOption] = React.useState([]);
  const [departmentData, setDepartmentData] = React.useState([]);
  const navigate = useNavigate();

  const radioButtonCss = {
    "&, &.Mui-checked": {
      color: logoRedColor,
    },
  };

  const jobLocationRadio = [
    {
      value: 1,
      label: "Work Form Office",
      icon: client,
      desc: "Candidate would be required to work from a fixed location",
    },
    {
      value: 2,
      label: "Work From Home",
      icon: client,
      desc: "Candidate would be required to work from home",
    },
    {
      value: 3,
      label: "Field jobs",
      icon: client,
      desc: "Candidate would be required to work in the field, with minimal time spent in the office ",
    },
  ];

  const [companyNameError, setCompanyNameError] = React.useState(false);
  const [jobTitleError, setJobTitleError] = React.useState(false);
  const [departmentError, setDepartmentError] = React.useState(false);
  const [categoryError, setCategoryError] = React.useState(false);
  const [jobTypeError, setJobTypeError] = React.useState(false);
  const [noOfOpeningsError, setNoOfOpeningsError] = React.useState(false);
  const [jobLocationError, setJobLocationError] = React.useState(false);
  const [jobCityError, setJobCityError] = React.useState(false);
  const [jobAreaError, setJobAreaError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);
  const [applicationFromError, setApplicationFromError] = React.useState(false);
  // const [receiveApplicationFromError, setReceiveApplicationFromError] =
  //   React.useState(false);
  // const [receiveCandidateFromError, setReceiveCandidateFromError] =
  //   React.useState(false);
  const [payTypeError, setPayTypeError] = React.useState(false);
  const [depositError, setDepositError] = React.useState(false);
  const [wfhReceiveApplicationError, setWfhReceiveApplicationError] =
    React.useState(false);
  const [averageIncentiveError, setAverageIncentiveError] =
    React.useState(false);
  const [depositAmountError, setDepositAmountError] = React.useState(false);
  const [depositTypeError, setDepositTypeError] = React.useState(false);
  const [minSalaryError, setMinSalaryError] = React.useState(false);
  const [maxSalaryError, setMaxSalaryError] = React.useState(false);
  const [additionalPerksError, setAdditionalPerksError] = React.useState(false);
  // const [depositOtherError, setDepositOtherError] = React.useState(false);
  const [feeBePaidByError, setFeeBePaidByError] = React.useState(false);
  const [wfhFieldJobError, setWfhFieldJobError] = React.useState(false);
  const [applicationFromEverywhereError, setApplicationFromEverywhereError] =
    React.useState(false);
  const [scrollId, setScrollId] = React.useState("");

  let emptyError = false;

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  const handleBasicDetailsChange = (event) => {
    const { name, value } = event.target;

    //if array the push else set
    if (Array.isArray(basicDetails[name])) {
      if (basicDetails[name].includes(value)) {
        setBasicDetails({
          ...basicDetails,
          [name]: basicDetails[name].filter((item) => item !== value),
        });
      } else {
        setBasicDetails({
          ...basicDetails,
          [name]: [...basicDetails[name], value],
        });
      }
    } else {
      setBasicDetails({ ...basicDetails, [name]: value });
    }
  };

  const handleSelectChange = (value, name) => {
    setBasicDetails({ ...basicDetails, [name]: value });
  };

  useEffect(() => {
    let companyInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
    setBasicDetails({
      ...basicDetails,
      companyName: companyInfo?.company_name,
      isCompany: companyInfo?.is_compnay,
    });
  }, []);

  const basicDetailsContinue = () => {
    if (basicDetails.companyName === "") {
      emptyError = true;
      setCompanyNameError(true);
    } else {
      setCompanyNameError(false);
    }

    if (basicDetails.jobTitle === "") {
      emptyError = true;
      setJobTitleError(true);
    } else {
      setJobTitleError(false);
    }

    if (
      basicDetails.department.value === "" ||
      basicDetails.department === ""
    ) {
      emptyError = true;
      setDepartmentError(true);
    } else {
      setDepartmentError(false);
    }

    if (basicDetails.category.value === "" || basicDetails.category === "") {
      emptyError = true;
      setCategoryError(true);
    } else {
      setCategoryError(false);
    }

    if (basicDetails.jobType === "") {
      emptyError = true;
      setJobTypeError(true);
    } else {
      setJobTypeError(false);
    }

    if (basicDetails.noOfOpenings === "") {
      emptyError = true;
      setNoOfOpeningsError(true);
    } else {
      setNoOfOpeningsError(false);
    }

    if (basicDetails.jobLocation === "") {
      emptyError = true;
      setJobLocationError(true);
    } else {
      setJobLocationError(false);
    }

    if (basicDetails?.payType === "") {
      emptyError = true;
      setPayTypeError(true);
    } else {
      setPayTypeError(false);
    }

    if (basicDetails?.additionalPerks.length <= 0) {
      setAdditionalPerksError(true);
    } else {
      setAdditionalPerksError(false);
    }

    if (basicDetails?.deposit === "") {
      emptyError = true;
      setDepositError(true);
    } else {
      setDepositError(false);
    }

    if (basicDetails?.jobLocation === 1) {
      if (basicDetails?.jobCity === "") {
        emptyError = true;
        setJobCityError(true);
      } else {
        setJobCityError(false);
      }

      if (basicDetails?.jobArea === "") {
        emptyError = true;
        setJobAreaError(true);
      } else {
        setJobAreaError(false);
      }

      if (basicDetails?.address === "") {
        emptyError = true;
        setAddressError(true);
      } else {
        setAddressError(false);
      }
      if (basicDetails?.applicationFrom === "3") {
        if (basicDetails?.applicationFromAnywhere === "") {
          emptyError = true;
          setApplicationFromEverywhereError(true);
        } else {
          setApplicationFromEverywhereError(false);
        }
      }

      if (basicDetails?.applicationFrom === "") {
        emptyError = true;
        setApplicationFromError(true);
      } else {
        setApplicationFromError(false);
      }
    } else if (basicDetails?.jobLocation === 2) {
      if (basicDetails?.wfhReceiveApplication === "") {
        emptyError = true;
        setWfhReceiveApplicationError(true);
      } else {
        setWfhReceiveApplicationError(false);
      }

      if (basicDetails?.wfhReceiveApplication === "1") {
        if (basicDetails?.jobCity === "") {
          emptyError = true;
          setJobCityError(true);
        } else {
          setJobCityError(false);
        }
      }
    } else if (basicDetails?.jobLocation === 3) {
      if (basicDetails?.wfhFieldJob === "") {
        emptyError = true;
        setWfhFieldJobError(true);
      } else {
        setWfhFieldJobError(false);
      }

      if (basicDetails?.wfhFieldJob === "1") {
        if (basicDetails?.jobCity === "") {
          emptyError = true;
          setJobCityError(true);
        } else {
          setJobCityError(false);
        }

        if (basicDetails?.jobArea === "") {
          emptyError = true;
          setJobAreaError(true);
        } else {
          setJobAreaError(false);
        }
      }

      if (basicDetails?.wfhFieldJob === "2") {
        if (basicDetails?.jobCity === "") {
          emptyError = true;
          setJobCityError(true);
        } else {
          setJobCityError(false);
        }
      }
    }

    if (basicDetails?.payType === "1") {
      if (basicDetails?.minFixedSalary === "") {
        emptyError = true;
        setMinSalaryError(true);
      } else {
        setMinSalaryError(false);
      }

      if (basicDetails?.maxFixedSalary === "") {
        emptyError = true;
        setMaxSalaryError(true);
      } else {
        setMaxSalaryError(false);
      }
    } else if (basicDetails?.payType === "2") {
      if (basicDetails?.minFixedSalary === "") {
        emptyError = true;
        setMinSalaryError(true);
      } else {
        setMinSalaryError(false);
      }

      if (basicDetails?.maxFixedSalary === "") {
        emptyError = true;
        setMaxSalaryError(true);
      } else {
        setMaxSalaryError(false);
      }
      if (basicDetails?.averageIncentive === "") {
        emptyError = true;
        setAverageIncentiveError(true);
      } else {
        setAverageIncentiveError(false);
      }
    } else if (basicDetails?.payType === "3") {
      if (basicDetails?.averageIncentive === "") {
        emptyError = true;
        setAverageIncentiveError(true);
      } else {
        setAverageIncentiveError(false);
      }
    }

    if (basicDetails?.deposit === "1") {
      if (basicDetails?.depositAmount === "") {
        emptyError = true;
        setDepositAmountError(true);
      } else {
        setDepositAmountError(false);
      }

      if (basicDetails?.depositType === "") {
        emptyError = true;
        setDepositTypeError(true);
      } else {
        setDepositTypeError(false);
      }

      if (basicDetails?.feeBePaidBy === "") {
        emptyError = true;
        setFeeBePaidByError(true);
      } else {
        setFeeBePaidByError(false);
      }
    }

    clickEvent();
  };

  const clickEvent = () => {
    if (emptyError === true) {
      setAlertMessage({
        message: "Please fill all the fields",
        isOpen: true,
        type: "danger",
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  useDebouncyEffect(
    () => {
      let department = [];
      const body = {
        search: basicDetails?.jobTitle,
      };
      jobSearchWithMeta(body, token)
        .then((res) => {
          setJobTitleOption(res?.data?.data?.role_data?.rows);
          for (let i = 0; i < res?.data?.data?.role_data?.rows.length; i++) {
            if (
              res?.data?.data?.role_data?.rows[i].title ===
              basicDetails?.jobTitle
            ) {
              setDepartmentData(
                departmentDataOption.filter(
                  (item) =>
                    item.department_id ===
                    res?.data?.data?.role_data?.rows[i].job_departments[0]
                      ?.department_id
                )
              );
              department = JSON.parse(
                JSON.stringify(
                  departmentDataOption.filter(
                    (item) =>
                      item.department_id ===
                      res?.data?.data?.role_data?.rows[i].job_departments[0]
                        ?.department_id
                  )
                )
              );
            }

            for (let j = 0; j < department?.length; j++) {
              if (
                department[j].department_id === basicDetails?.department.value
              ) {
                setJobRoleData(
                  roleDataOption.filter((item) =>
                    department[j].department_roles.some(
                      ({ role_id: id2 }) => id2 === item.role_id
                    )
                  )
                );
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    1000,
    [basicDetails?.jobTitle]
  );
  // console.log(jobRoleData, "jobRoleData");
  // useEffect(() => {
  //     employerSetupData().then((res) => {
  //         setDepartmentDataOption(res?.data?.data?.department_data)
  //         setAdditionalPerksOption(res?.data?.data?.additional_perks)
  //         setIndustryDataOption(res?.data?.data?.industry_data)
  //         setJobRoleDataOption(res?.data?.data?.job_role_data)
  //         setJobTypeDataOption(res?.data?.data?.job_type)
  //         setJoiningFeeTypeOption(res?.data?.data?.joining_fee_type)
  //         setNoOfEmployeeOption(res?.data?.data?.number_of_employees)
  //         setPayTypeOption(res?.data?.data?.pay_type)
  //         setRoleDataOption(res?.data?.data?.role_data)
  //         setToken(res?.data?.data?.token)
  //     }).catch((err) => {
  //         console.log(err)
  //     })
  // }, [])


  const getCityAllData = () => {
    getCityData(token)
      .then((res) => {
        const ids = res?.data?.data?.city_ata.map(({ city_name }) => city_name);
        const filtered = res?.data?.data?.city_ata.filter(({ city_name }, index) =>
          !ids.includes(city_name, index + 1));
        setCityDataOption(filtered);
        // setCityDataOption(res?.data?.data?.city_ata);
      })
      .catch((err) => {
        console.log(err);
        errorAlert(setAlertMessage, err, navigate, setLogin, login);
      });
  }

  useEffect(() => {
    getCityAllData()
  }, []);

  const getAreaData = (e) => {
    getAreaList(e?.value, token)
      .then((res) => {
        setAreaDataOption(res?.data?.data?.area_ata);
      })
      .catch((err) => {
        console.log(err);
        errorAlert(setAlertMessage, err, navigate, setLogin, login);
      });
  };

  const scrollToTop = () => {
    try {
      let element = document.getElementById(scrollId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setScrollId("scrollToTop");
    scrollToTop();
  }, [activeStep, scrollId]);

  return (
    <Grid container style={{ color: "black" }} direction="column" spacing={4}>
      <Grid item xs={12}>
        <h4>Job details</h4>
        <div style={{ fontSize: "14px", color: "gray" }}>
          We use this information to find the best candidate for the job.
        </div>
        <Grid container spacing={3} style={{ marginTop: "5px" }}>
          <Grid item xs={12} className="form-group">
            <SubHeader title={"Company you are hiring for"} isRequired={true} />
            <input
              className="form-control"
              required={true}
              name="companyName"
              value={basicDetails.companyName}
              onChange={(e) => {
                handleBasicDetailsChange(e);
                setCompanyNameError(false);
              }}
              type="text"
              placeholder="Company name"
            />
            {companyNameError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please enter company name
              </div>
            )}
          </Grid>
          <Grid
            item
            className="form-group"
            xs={12}
            style={{ position: "relative" }}>
            <SubHeader title={"Job Title"} isRequired={true} />
            <input
              className="form-control"
              required={true}
              name="jobTitle"
              value={basicDetails.jobTitle}
              onChange={(e) => {
                setJobRoleOpen(true);
                setBasicDetails({
                  ...basicDetails,
                  jobTitle: e.target.value.trimStart(),
                  department: {
                    label: "",
                    value: "",
                  },
                  category: {
                    label: "",
                    value: "",
                  },
                });
                setJobTitleError(false);
                setDepartmentData([]);
              }}
              type="text"
              placeholder="Job title"
            />
            {/* make one compoenents to show the list */}
            <Grid item xs={12}>
              {basicDetails?.jobTitle && jobRoleOpen ? (
                <Paper
                  style={{
                    position: "absolute",
                    zIndex: "999",
                    backgroundColor: "white",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                    width: "100%",
                    maxHeight: "300px",
                    overflow: "auto",
                  }}>
                  {jobTitleOption?.length > 0 ? (
                    jobTitleOption.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setJobRoleOpen(false);
                            setBasicDetails({
                              ...basicDetails,
                              jobTitle: item?.title,
                            });
                            setDepartmentData([]);
                          }}
                          style={{
                            padding: "10px",
                            cursor: "pointer",
                            backgroundColor: "white",
                            "&:hover": {
                              backgroundColor: lightRedLogoColor,
                            },
                          }}>
                          {item?.title}
                        </div>
                      );
                    })
                  ) : (
                    <Paper
                      style={{
                        position: "absolute",
                        zIndex: "999",
                        backgroundColor: "white",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                        width: "100%",
                        maxHeight: "300px",
                        overflow: "auto",
                      }}>
                      <div
                        onClick={() => {
                          setBasicDetails({
                            ...basicDetails,
                            jobTitle: basicDetails.jobTitle,
                          });
                          setDepartmentData([]);
                          setJobRoleOpen(false);
                        }}
                        style={{
                          padding: "10px",
                          backgroundColor: "white",
                          "&:hover": {
                            backgroundColor: lightRedLogoColor,
                          },
                        }}>
                        {`Add + ${basicDetails.jobTitle}`}
                      </div>
                    </Paper>
                  )}
                </Paper>
              ) : null}
            </Grid>
            {jobTitleError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please enter job title
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <SubHeader title={"Department / Function"} isRequired={true} />
            <CustomReactSelect
              isClearable
              classNamePrefix="reactselect-select"
              options={
                departmentData?.length <= 0
                  ? departmentDataOption.map((item) => {
                    return {
                      label: item.department_name,
                      value: item.department_id,
                    };
                  })
                  : departmentData.map((item) => {
                    return {
                      label: item.department_name,
                      value: item.department_id,
                    };
                  })
              }
              name="department"
              styles={statusDropdownColorStyles}
              value={basicDetails?.department?.value}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  category: {
                    label: "",
                    value: "",
                  },
                  department: e,
                });
                setDepartmentError(false);

                let arr = departmentDataOption.filter((item) => {
                  return item?.department_id === e?.value;
                });
                setSelectedDepartment(arr?.[0]);

                let roleData = roleDataOption.filter((item) => {
                  return arr?.[0]?.department_roles?.some((role) => {
                    return role.role_id === item.role_id;
                  });
                });
                setJobRoleData(roleData);
              }}
              label="Department / Function"
            />
            {departmentError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select department
              </div>
            )}
          </Grid>

          <Grid item sm={6} xs={12}>
            <SubHeader title={"Category / Role"} isRequired={true} />
            <CustomReactSelect
              isClearable
              classNamePrefix="reactselect-select"
              styles={statusDropdownColorStyles}
              id="outlined-select"
              select
              fullWidth
              name="category"
              required
              options={jobRoleData.map((item) => {
                return {
                  label: item?.title,
                  value: item?.role_id,
                };
              })}
              value={basicDetails?.category?.value}
              onChange={(e) => {
                setCategoryError(false);
                handleSelectChange(e, "category");
              }}
              label="Category / Role"
            />
            {categoryError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select category
              </div>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <SubHeader title={"Job Type"} isRequired={true} />
            <CustomReactSelect
              styles={statusDropdownColorStyles}
              id="outlined-select"
              select
              fullWidth
              name="jobType"
              required
              options={jobTypeDataOption?.map((item) => {
                return {
                  label: item?.title,
                  value: item?.job_type_id,
                };
              })}
              value={basicDetails.jobType?.value}
              onChange={(e) => {
                setJobTypeError(false);
                handleSelectChange(e, "jobType");
              }}
              label="Job Type"
              placeholder="Type of job"
            />
            {jobTypeError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select job type
              </div>
            )}
          </Grid>
          <Grid item md={6} xs={12} className="form-group">
            <SubHeader title={"No. of openings"} isRequired={true} />
            <input
              className="form-control"
              name="noOfOpenings"
              required={true}
              value={basicDetails.noOfOpenings}
              onChange={(e) => {
                handleBasicDetailsChange(e);
                setNoOfOpeningsError(false);
              }}
              placeholder="No. of openings"
            />
            {noOfOpeningsError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please enter no. of openings
              </div>
            )}
          </Grid>
          <Grid item xs={12} style={{ color: "black" }}>
            <FormControlLabel
              name="nightShift"
              value={basicDetails.nightShift}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  nightShift: e.target.checked,
                });
              }}
              control={<Checkbox sx={{ ...radioButtonCss }} />}
              label="This is a night shift job"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h4>Location</h4>
            <div style={{ fontSize: "14px", color: "gray" }}>
              Let candidate know where they will be working from.
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <SubHeader
                  title={" What is the job location for your candidate?"}
                  isRequired={true}
                />
                <Grid container spacing={3}>
                  {jobLocationRadio.map((item, index) => {
                    return (
                      <Grid key={index} item md={4} sm={6} xs={12}>
                        <label htmlFor={`${item.value}`}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid gray",
                              padding: "2%",
                              cursor: "pointer",
                              height: "220px",
                              borderRadius: "30px",
                              backgroundColor:
                                basicDetails.jobLocation === item.value &&
                                lightRedLogoColor,
                              borderColor:
                                basicDetails.jobLocation === item.value &&
                                logoRedColor,
                            }}>
                            <input
                              id={`${item.value}`}
                              type="radio"
                              name="jobLocation"
                              value={item.value}
                              hidden
                              checked={basicDetails.jobLocation === item.value}
                              onChange={(e) => {
                                // handleBasicDetailsChange(e)
                                setJobLocationError(false);
                                setBasicDetails({
                                  ...basicDetails,
                                  jobLocation: item.value,
                                });
                              }}
                              style={{ paddingRight: "20px", width: "15%" }}
                            // onChange={
                            //   () => {
                            //     setSelectEmployer({
                            //       company: false,
                            //       client: true,
                            //     })
                            //   }
                            // }
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              <img
                                src={item?.icon}
                                alt="company"
                                style={{ width: "20%", height: "20%" }}
                              />
                              <div
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}>
                                {item.label}
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "rgb(23, 43, 77)",
                                }}>
                                {item.desc}
                              </div>
                            </div>
                          </Box>
                        </label>
                      </Grid>
                    );
                  })}
                  {jobLocationError && (
                    <div
                      style={{
                        marginLeft: "22px",
                        color: "red",
                        fontSize: "12px",
                      }}>
                      Please select job location
                    </div>
                  )}
                </Grid>
              </Grid>

              {basicDetails?.jobLocation === 1 && (
                <>
                  <Grid item xs={12}>
                    {/* <h4>Job Location</h4> */}
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <SubHeader title={"Job City"} isRequired={true} />
                        <CustomReactSelect
                          styles={statusDropdownColorStyles}
                          id="outlined-select"
                          fullWidth
                          options={cityDataOption?.map((item) => {
                            return {
                              label: item?.city_name,
                              value: item?.city_id,
                            };
                          })}
                          name="jobCity"
                          value={basicDetails.jobCity?.value}
                          onChange={(e) => {
                            handleSelectChange(e, "jobCity");
                            setJobCityError(false);
                            getAreaData(e);
                          }}
                          label="Job City"
                        />
                        {jobCityError && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            Please select job city
                          </div>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SubHeader title={"Area"} isRequired={true} />
                        <CustomReactSelectCreate
                          styles={statusDropdownColorStyles}
                          id="outlined-select"
                          select
                          fullWidth
                          options={areaDataOption?.map((item) => {
                            return {
                              label: item?.area_name,
                              value: item?.area_id,
                            };
                          })}
                          name="jobArea"
                          value={basicDetails.jobArea?.value}
                          onChange={(e) => {
                            handleSelectChange(e, "jobArea");
                            setJobAreaError(false);
                          }}
                          label="Area"
                        />
                        {jobAreaError && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            Please select Area
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} className="form-group">
                        <SubHeader title={"Address"} isRequired={true} />
                        <input
                          className="form-control"
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          fullWidth
                          placeholder="Address"
                          name="address"
                          value={basicDetails.address}
                          onChange={(e) => {
                            handleBasicDetailsChange(e);
                            setAddressError(false);
                          }}
                          inputProps={{
                            style: {
                              padding: "10px",
                              height: "35px",
                            },
                          }}
                        />
                        {addressError && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            Please write address
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <SubHeader
                      title={"Receive application from"}
                      isRequired={true}
                    />
                    <Grid container>
                      <Grid item>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="applicationFrom"
                          value={basicDetails.applicationFrom}
                          onChange={(e) => {
                            setApplicationFromError(false);
                            handleBasicDetailsChange(e);
                          }}>
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              value={1}
                              control={<Radio sx={{ ...radioButtonCss }} />}
                              label="Within 10 KM"
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio sx={{ ...radioButtonCss }} />}
                              label="Within 25 KM"
                            />
                            <FormControlLabel
                              value={3}
                              control={<Radio sx={{ ...radioButtonCss }} />}
                              label="Anywhere"
                            />
                          </div>
                        </RadioGroup>
                        {applicationFromError && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            Please select application from
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {basicDetails.applicationFrom === "3" && (
                    <Grid item xs={12}>
                      <SubHeader
                        title={
                          "Would you also like to recive candidate application from anywhere?"
                        }
                        isRequired={true}
                      />

                      <Grid container>
                        <Grid item>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="applicationFromAnywhere"
                            value={basicDetails.applicationFromAnywhere}
                            onChange={(e) => {
                              handleBasicDetailsChange(e);
                              setApplicationFromEverywhereError(false);
                            }}>
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                value={1}
                                control={<Radio sx={{ ...radioButtonCss }} />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={2}
                                control={<Radio sx={{ ...radioButtonCss }} />}
                                label="No"
                              />
                            </div>
                          </RadioGroup>
                          {applicationFromEverywhereError && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              Please select application from anywhere
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {basicDetails?.jobLocation === 2 && (
                <>
                  <Grid item xs={12}>
                    <SubHeader
                      title={"Receive application from"}
                      isRequired={true}
                    />
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="wfhReceiveApplication"
                      value={basicDetails?.wfhReceiveApplication}
                      onChange={(e) => {
                        setWfhReceiveApplicationError(false);
                        handleBasicDetailsChange(e);
                      }}>
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={1}
                          control={<Radio sx={{ ...radioButtonCss }} />}
                          label="Specific city"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio sx={{ ...radioButtonCss }} />}
                          label="Anywhere in india"
                        />
                      </div>
                    </RadioGroup>
                    {wfhReceiveApplicationError && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        Please select receive application from
                      </div>
                    )}
                  </Grid>
                  {basicDetails.wfhReceiveApplication === "1" && (
                    <Grid item md={6} xs={12}>
                      <SubHeader title={"Job City"} isRequired={true} />
                      <CustomReactSelect
                        styles={statusDropdownColorStyles}
                        id="outlined-select"
                        fullWidth
                        options={cityDataOption?.map((item) => {
                          return {
                            label: item?.city_name,
                            value: item?.city_id,
                          };
                        })}
                        name="jobCity"
                        value={basicDetails.jobCity?.value}
                        onChange={(e) => {
                          handleSelectChange(e, "jobCity");
                          setJobCityError(false);
                          getAreaData(e);
                        }}
                        label="Job City"
                      />
                      {jobCityError && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please select job city
                        </div>
                      )}
                    </Grid>
                  )}
                </>
              )}
              {basicDetails?.jobLocation === 3 && (
                <>
                  <Grid item xs={12}>
                    <SubHeader
                      title={"Receive application from "}
                      isRequired={true}
                    />
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="wfhFieldJob"
                      value={basicDetails?.wfhFieldJob}
                      onChange={(e) => {
                        handleBasicDetailsChange(e);
                        setWfhFieldJobError(false);
                      }}>
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={1}
                          control={<Radio sx={{ ...radioButtonCss }} />}
                          label="One specific area"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio sx={{ ...radioButtonCss }} />}
                          label="Anywhere in the city"
                        />
                      </div>
                    </RadioGroup>
                    {wfhFieldJobError && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        Please select receive application from
                      </div>
                    )}
                  </Grid>
                  {basicDetails.wfhFieldJob === "1" && (
                    <>
                      <Grid item md={6} xs={12}>
                        <SubHeader title={"Job City"} isRequired={true} />
                        <CustomReactSelect
                          styles={statusDropdownColorStyles}
                          id="outlined-select"
                          fullWidth
                          options={cityDataOption?.map((item) => {
                            return {
                              label: item?.city_name,
                              value: item?.city_id,
                            };
                          })}
                          name="jobCity"
                          value={basicDetails.jobCity?.value}
                          onChange={(e) => {
                            handleSelectChange(e, "jobCity");
                            setJobCityError(false);
                            getAreaData(e);
                          }}
                          label="Job City"
                        />
                        {jobCityError && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            Please select job city
                          </div>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SubHeader title={"Area"} isRequired={true} />
                        <CustomReactSelectCreate
                          styles={statusDropdownColorStyles}
                          id="outlined-select"
                          select
                          fullWidth
                          options={areaDataOption?.map((item) => {
                            return {
                              label: item?.area_name,
                              value: item?.area_id,
                            };
                          })}
                          name="jobArea"
                          value={basicDetails.jobArea?.value}
                          onChange={(e) => {
                            handleSelectChange(e, "jobArea");
                            setJobAreaError(false);
                          }}
                          label="Area"
                        />
                        {jobAreaError && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            Please select Area
                          </div>
                        )}
                      </Grid>
                    </>
                  )}
                  {basicDetails.wfhFieldJob === "2" && (
                    <Grid item md={6} xs={12}>
                      <SubHeader title={"Job City"} isRequired={true} />
                      <CustomReactSelect
                        styles={statusDropdownColorStyles}
                        id="outlined-select"
                        fullWidth
                        options={cityDataOption?.map((item) => {
                          return {
                            label: item?.city_name,
                            value: item?.city_id,
                          };
                        })}
                        name="jobCity"
                        value={basicDetails.jobCity?.value}
                        onChange={(e) => {
                          handleSelectChange(e, "jobCity");
                          setJobCityError(false);
                          getAreaData(e);
                        }}
                        label="Job City"
                      />
                      {jobCityError && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please select job city
                        </div>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h4>Compensation</h4>
            <div style={{ fontSize: "14px", color: "gray" }}>
              Job posting with right salary & incentive will help you find the
              right candidate.
            </div>
          </Grid>
          <Grid item xs={12}>
            <SubHeader title={" What is the pay type"} isRequired={true} />

            <Grid container spacing={3}>
              <Grid item>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="payType"
                  value={basicDetails.payType}
                  onChange={(e) => {
                    setPayTypeError(false);
                    handleBasicDetailsChange(e);
                  }}>
                  <div style={{ display: "flex" }}>
                    {payTypeOption?.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item.pay_type_id}
                          control={<Radio sx={{ ...radioButtonCss }} />}
                          label={item.title}
                        />
                      );
                    })}
                  </div>
                </RadioGroup>
                {payTypeError && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Please select pay type
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}>
              {basicDetails.payType === `3` && (
                <Grid item className="form-group">
                  <SubHeader
                    class="input-group"
                    title={"Average incentive / month"}
                    isRequired={true}
                  />
                  <div style={{
                    display: "flex"
                  }}>
                    <span class="input-group-addon">&#8377;</span>
                    <input
                      style={{
                        height: "34px"

                      }}
                      className="form-control form-control-salary"
                      name="averageIncentive"
                      value={basicDetails.averageIncentive}
                      onChange={(e) => {
                        setAverageIncentiveError(false);
                        handleBasicDetailsChange(e);
                      }}
                      placeholder="Average incentive / month"
                      required={true}
                    />
                  </div>
                  {averageIncentiveError && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      Please enter average incentive
                    </div>
                  )}
                </Grid>
              )}
              {(basicDetails.payType === `1` ||
                basicDetails.payType === `2`) && (
                  <Grid item>
                    <SubHeader title={"Fixed salary / month"} isRequired={true} />

                    <Grid container>
                      <Grid
                        class="input-group"
                        item
                        className="form-group"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}>
                        <div style={{
                          display: "flex",
                        }}>
                          <span class="input-group-addon">&#8377;</span>
                          <input
                            className="form-control form-control-salary"
                            name="minFixedSalary"
                            value={basicDetails.minFixedSalary}
                            onChange={(e) => {
                              setMinSalaryError(false);
                              handleBasicDetailsChange(e);
                            }}
                            required={true}
                            placeholder="Minimum fixed salary"
                          />
                        </div>

                        <div>TO</div>
                        <div style={{
                          display: "flex",
                        }}>
                          <span class="input-group-addon">&#8377;</span>
                          <input
                            className="form-control form-control-salary"
                            name="maxFixedSalary"
                            value={basicDetails.maxFixedSalary}
                            onChange={(e) => {
                              setMaxSalaryError(false);
                              handleBasicDetailsChange(e);
                            }}
                            placeholder="Maximum fixed salary"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    {(minSalaryError || maxSalaryError) && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        Please enter salary
                      </div>
                    )}
                  </Grid>
                )}

              {basicDetails.payType === `2` && (
                <>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      height: "50%",
                      marginTop: "2%",
                      fontSize: "2rem",
                    }}>
                    <div> + </div>
                  </Grid>
                  <Grid item className="form-group">
                    <SubHeader
                      title={"Average incentive / month"}
                      isRequired={true}
                    />

                    <div class="input-group" style={{
                      display: "flex",

                    }}>
                      <span class="input-group-addon">&#8377;</span>
                      <input
                        style={{
                          height: "34px"
                        }}
                        className="form-control form-control-salary"
                        name="averageIncentive"
                        value={basicDetails.averageIncentive}
                        onChange={(e) => {
                          setAverageIncentiveError(false);
                          handleBasicDetailsChange(e);
                        }}
                        placeholder="Average incentive / month"
                      />
                    </div>
                    {averageIncentiveError && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        Please enter average incentive
                      </div>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SubHeader
              title={"Do you offer any additional perks?"}
              isRequired={true}
            />

            <Grid container spacing={2}>
              {additionalPerksOption
                .filter((item) => item?.title !== "Other")
                .map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <div>
                        <input
                          id={`employee-${item?.additional_perks_id}`}
                          type="checkbox"
                          hidden
                          name="additionalPerks"
                          value={item?.additional_perks_id}
                          onChange={(e) => {
                            setAdditionalPerksError(false);
                            if (
                              basicDetails.additionalPerks.includes(
                                item.additional_perks_id
                              )
                            ) {
                              setBasicDetails({
                                ...basicDetails,
                                additionalPerks:
                                  basicDetails.additionalPerks.filter(
                                    (asset) =>
                                      asset !== item.additional_perks_id
                                  ),
                              });
                            } else {
                              // when its selected then add selected field in selected object
                              setBasicDetails({
                                ...basicDetails,
                                additionalPerks: [
                                  ...basicDetails.additionalPerks,
                                  item.additional_perks_id,
                                ],
                              });
                            }
                          }}
                          style={{ width: "20%" }}
                        />
                        <Chip
                          label={item?.title}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            backgroundColor:
                              basicDetails.additionalPerks.includes(
                                item.additional_perks_id
                              )
                                ? lightRedLogoColor
                                : "white",
                            color: basicDetails.additionalPerks.includes(
                              item.additional_perks_id
                            )
                              ? logoRedColor
                              : "black",
                            fontWeight: basicDetails.additionalPerks.includes(
                              item.additional_perks_id
                            )
                              ? "bold"
                              : "normal",
                            border: basicDetails.additionalPerks.includes(
                              item.additional_perks_id
                            )
                              ? `1px solid ${logoRedColor}`
                              : "1px solid black",
                          }}
                          onClick={() => {
                            setAdditionalPerksError(false);
                            if (
                              basicDetails.additionalPerks.includes(
                                item.additional_perks_id
                              )
                            ) {
                              setBasicDetails({
                                ...basicDetails,
                                additionalPerks:
                                  basicDetails.additionalPerks.filter(
                                    (asset) =>
                                      asset !== item.additional_perks_id
                                  ),
                              });
                            } else {
                              setBasicDetails({
                                ...basicDetails,
                                additionalPerks: [
                                  ...basicDetails.additionalPerks,
                                  item.additional_perks_id,
                                ],
                              });
                            }
                          }}
                          onDelete={
                            basicDetails.additionalPerks.includes(
                              item.additional_perks_id
                            )
                              ? () => {
                                setBasicDetails({
                                  ...basicDetails,
                                  additionalPerks:
                                    basicDetails.additionalPerks.filter(
                                      (assest) =>
                                        assest !== item.additional_perks_id
                                    ),
                                });
                              }
                              : () => {
                                if (
                                  basicDetails.additionalPerks.includes(
                                    item.additional_perks_id
                                  )
                                ) {
                                  setAdditionalPerksError(false);
                                  setBasicDetails({
                                    ...basicDetails,
                                    additionalPerks:
                                      basicDetails.additionalPerks.filter(
                                        (asset) =>
                                          asset !== item.additional_perks_id
                                      ),
                                  });
                                } else {
                                  setAdditionalPerksError(false);
                                  setBasicDetails({
                                    ...basicDetails,
                                    additionalPerks: [
                                      ...basicDetails.additionalPerks,
                                      item.additional_perks_id,
                                    ],
                                  });
                                }
                              }
                          }
                          deleteIcon={
                            basicDetails.additionalPerks.includes(
                              item.additional_perks_id
                            ) ? (
                              <CloseIcon style={{ color: logoRedColor }} />
                            ) : (
                              <AddIcon style={{ color: "black" }} />
                            )
                          }
                        />
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            {additionalPerksError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select additional perks
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <SubHeader
              title={
                " Is there any joining fee or deposit required from the candidate?"
              }
              isRequired={true}
            />

            <Grid container spacing={2}>
              <Grid item>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="deposit"
                  name="deposit"
                  value={basicDetails.deposit}
                  onChange={(e) => {
                    setDepositError(false);
                    handleBasicDetailsChange(e);
                  }}>
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      value={1}
                      control={<Radio sx={{ ...radioButtonCss }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio sx={{ ...radioButtonCss }} />}
                      label="No"
                    />
                  </div>
                </RadioGroup>
                {depositError && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Please select joining fee
                  </div>
                )}
              </Grid>
              {basicDetails.deposit === "1" && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className="form-group">
                      <SubHeader title={"Amount"} isRequired={true} />
                      <input
                        className="form-control"
                        required={true}
                        name="depositAmount"
                        value={basicDetails.depositAmount}
                        onChange={(e) => {
                          setDepositAmountError(false);
                          handleBasicDetailsChange(e);
                        }}
                        placeholder="Amount"
                      />
                      {depositAmountError && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please enter amount
                        </div>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12} className="form-group">
                      <SubHeader title={"Deposit Type"} isRequired={true} />
                      <CustomReactSelect
                        styles={statusDropdownColorStyles}
                        id="outlined-select"
                        fullWidth
                        options={joiningFeeTypeOption?.map((item) => {
                          return {
                            label: item?.title,
                            value: item?.joining_fee_type_id,
                          };
                        })}
                        name="depositType"
                        value={basicDetails.depositType?.value}
                        onChange={(e) => {
                          setDepositTypeError(false);
                          handleSelectChange(e, "depositType");
                        }}
                        label="Job City"
                      />
                      {depositTypeError && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please select type
                        </div>
                      )}
                    </Grid>
                    {basicDetails.depositType?.value === 1 ||
                      basicDetails.depositType?.value === 3 ||
                      (basicDetails.depositType?.value === 5 && (
                        <Grid item xs={12} className="form-group">
                          <SubHeader title={"Other"} isRequired={true} />
                          <input
                            className="form-control"
                            name="depositOther"
                            value={basicDetails.depositOther}
                            onChange={(e) => {
                              // setDepositOtherError(false);
                              handleBasicDetailsChange(e);
                            }}
                            placeholder="Other"
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {basicDetails.deposit === "1" && (
            <Grid item xs={12}>
              <SubHeader
                title={"When should the fee be paid?"}
                isRequired={true}
              />

              <Grid item>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="feeBePaidBy"
                  required
                  value={basicDetails.feeBePaidBy}
                  onChange={(e) => {
                    setFeeBePaidByError(false);
                    handleBasicDetailsChange(e);
                  }}>
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      value={1}
                      control={<Radio sx={{ ...radioButtonCss }} />}
                      label="Before the interview"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio sx={{ ...radioButtonCss }} />}
                      label="After job confirmation"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio sx={{ ...radioButtonCss }} />}
                      label="Deducted from salary"
                    />
                  </div>
                </RadioGroup>
                {feeBePaidByError && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Please select fee paid time
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Button
          variant="contained"
          onClick={basicDetailsContinue}
          style={{ width: "30%", backgroundColor: "green" }}>
          Continue
        </Button>
      </Grid>
      {isEditJobPostDetails && (
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button
            variant="outlined"
            style={{
              width: "30%",
              borderColor: logoRedColor,
              color: logoRedColor,
            }}>
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default BasicDetailsForm;
