import styled from "@emotion/styled";
import { Box, Button, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Context } from "../Context/context";
import { employerProfileUpdate, fileUpload, getAreaList, getCityData, getEmployerProfile } from "../api/home";
import user from "../assets/imgs/user.png";
import CustomPreview from "./CustomPreview";
import { logoRedColor } from "./Header";


export const changeHandlerImageImproved = (e, callback, props = {}) => {
    const { files } = e.target;
    if (files[0]) {
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = (e) => {
            callback({
                file: props.shouldSetFileArray ? files : files[0],
                url: reader.result,
            });
        };
    }
};

export const Header = styled("h6")({
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "4px",
    color: "#000",
    // marginTop: "15px",
});

export const SubHeader = ({ title, isRequired }) => {
    return (
        <Box style={{ display: "flex", marginTop: "8px", }}>
            {/* <div className="font-sm color-text-mutted mb-10"> */}
            <Header>{title}</Header>
            {/* </div> */}
            {isRequired && <span style={{ color: "red" }}>*</span>}
        </Box>
    );
};

export default function CandidateProfile() {
    const [activeIndex, setActiveIndex] = useState(1);
    const [namedata, setNameData] = useState("");

    // const handleOnClick = (index) => {
    //     setActiveIndex(index); // remove the curly braces
    // };

    const { setAlertMessage,
        cityDataOption,
        setCityDataOption,
        token,
        setAreaDataOption } = React.useContext(Context);
    const [isMediaType, setIsMediaType] = React.useState(true);

    const [profile, setProfile] = React.useState({
        name: "",
        email: "",
        contactNo: "",
        gstNo: "",
        profileImage: {
            url: "",
            file: null,
        },
        bio: "",
        website_url: "",
        state: "",
        city: "",
        zipCode: ""
    });

    /*
      {
        "full_name":"",
        "email":"",
        "gst_no":"",
        "profile_image":"",
        "bio":"",
        "website_url":"",
        "country_id":0,
        "state_id":0,
        "city_id":0,
        "zipcode":""
    }
    */

    const [error, setError] = React.useState({
        name: false,
        contactNo: false,
    });

    const [nameError, setNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    let errorr = false;

    const uploadImage = async (e) => {
        let formData = new FormData()
        if (e.target.files) {
            formData.append('file', e.target.files[0]);
            fileUpload(formData).then((res) => {
                if (res?.data?.statusCode === 200) {
                    setProfile({
                        ...profile,
                        profileImage: {
                            url: res?.data?.data?.link
                        }
                    })
                    setAlertMessage({
                        message: "Image Uploaded Successfully",
                        isOpen: true,
                        type: "success",
                    })
                }
            }).catch((err) => {
                setAlertMessage({
                    message: err?.response?.data?.message,
                    isOpen: true,
                    type: "danger",
                });
            })
        } else {
            //if no file selected the show alert
            alert('Please Select File first');
        }
    };

    const [isEditing, setIsEditing] = React.useState(false);

    const handleChnage = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value,
        });
        // setError({
        //     ...error,
        //     [e.target.name]: false,
        // });
    };

    const handleValidate = () => {
        if (profile.name === "") {
            setNameError(true);
            errorr = true;
        } else {
            setNameError(false);
        }

        if (profile.email === "") {
            setEmailError(true);
            errorr = true;
        } else {
            setEmailError(false);
        }

        if (!errorr) {
            handleSubmit();
        } else {
            setAlertMessage({
                message: "Please fill all the required fields",
                isOpen: true,
                type: "danger",
            });
        }

    }

    const handleSubmit = () => {
        // e.preventDefault();
        setError({
            name: profile.name === "" ? true : false,
            contactNo: profile.contactNo === "" ? true : false,
        });
        setIsEditing(false);
        const body = {
            full_name: `${profile?.name}`,
            // phone_number: profile?.contactNo,
            gst_no: profile?.gstNo,
            email: profile?.email,
            profile_image: profile?.profileImage.url,
            bio: profile?.bio,
            website_url: profile?.website_url,
            country_id: 1,
            state_id: profile?.state,
            city_id: profile?.city,
            zipcode: profile?.zipCode
        };
        employerProfileUpdate(body)
            .then((res) => {
                if (res?.data?.statusCode === 200) {
                    setAlertMessage({
                        message: "Profile Updated Successfully",
                        isOpen: true,
                        type: "success",
                    });
                }
                getData()
            })
            .catch((err) => {
                console.log(err);
                setAlertMessage({
                    message: err?.response?.data?.message,
                    isOpen: true,
                    type: "danger",
                });
            });
    };

    const handleVerifyGstNo = () => {
        console.log("verify gst no");
    };

    const getData = () => {
        getEmployerProfile()
            .then((res) => {
                setProfile({
                    name: `${res?.data?.data?.user_data?.first_name} ${res?.data?.data?.user_data?.last_name}`,
                    email: res?.data?.data?.user_data?.email,
                    contactNo: res?.data?.data?.user_data?.phone_number?.substring(2),
                    gstNo: res?.data?.data?.user_data?.gst_no,
                    profileImage: {
                        url: res?.data?.data?.user_data?.profile_image
                    },
                    bio: res?.data?.data?.user_data?.bio,
                    website_url: res?.data?.data?.user_data?.website_url,
                    state: res?.data?.data?.user_data?.state_id,
                    city: res?.data?.data?.user_data?.city_id,
                    zipCode: res?.data?.data?.user_data?.zipcode

                });
                setNameData(
                    `${res?.data?.data?.user_data?.first_name.charAt(0)}${res?.data?.data?.user_data?.last_name.charAt(0)}`
                )
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getCityData(token)
            .then((res) => {
                const ids = res?.data?.data?.city_ata.map(({ city_name }) => city_name);
                const filtered = res?.data?.data?.city_ata.filter(({ city_name }, index) =>
                    !ids.includes(city_name, index + 1));
                filtered.sort((item1, item2) => item1.city_name.localeCompare(item2.city_name));
                filtered.unshift({ city_name: "", city_id: 0 });
                setCityDataOption(filtered);
            })
            .catch((err) => {
                console.log(err);
                // errorAlert(setAlertMessage, err, router, setLogin, login);
            });
    }, []);

    const getAreaData = (e) => {
        getAreaList(e, token)
            .then((res) => {
                setAreaDataOption(res?.data?.data?.area_ata);
            })
            .catch((err) => {
                console.log(err);
                // errorAlert(setAlertMessage, err, router, setLogin, login);
            });
    };


    return (
        <>
            <div>
                <section className="section-box mt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12">
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-50">
                                <div className="content-single">
                                    <div className="tab-content">
                                        <div
                                            className={`tab-pane fade ${activeIndex === 1 && "show active"
                                                }`}>
                                            <h3 className="mt-0 mb-15 color-brand-1">My Account</h3>
                                            {/* <Link legacyBehavior href="#">
                                                <a className="font-md color-text-paragraph-2">
                                                    Update your profile
                                                </a>
                                            </Link> */}

                                            <div className="mt-35 mb-40 box-info-profie">
                                                <div className="image-profile" style={{ maxWidth: "130px" }}>
                                                    {!profile.profileImage?.url ? (
                                                        <CustomPreview
                                                            fileAccept="image/*"
                                                            editTooltipText="Update Profile Picture"
                                                            showDeleteButton={false}
                                                            showEditButton={isEditing}
                                                            deleteTooltipText="Delete Profile Picture"
                                                            deleteHandler={() =>
                                                                setProfile({
                                                                    ...profile,
                                                                    profileImage: {
                                                                        url: "",
                                                                        file: null,
                                                                    },
                                                                })
                                                            }
                                                            editHandler={(e) =>
                                                                changeHandlerImageImproved(e, ({ file, url }) => {
                                                                    if (file?.type?.includes("image")) {
                                                                        setIsMediaType(true);
                                                                        uploadImage(e)
                                                                        setProfile({
                                                                            ...profile,
                                                                            profileImage: {
                                                                                url,
                                                                                file,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        setIsMediaType(false);
                                                                    }
                                                                })
                                                            }
                                                            styles={{
                                                                rootStyles: {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "150px",
                                                                },
                                                                childrenStyles: {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "150px",
                                                                },
                                                            }}>
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "130px",
                                                                height: "130px",
                                                                maxHeight: "150px",
                                                                backgroundColor: "#f0f0f0",
                                                                borderRadius: "50%",
                                                                color: "#000",
                                                                fontSize: "70px",
                                                                fontWeight: "bold"
                                                            }}>
                                                                {namedata}
                                                            </div>
                                                        </CustomPreview>
                                                    ) : (
                                                        <CustomPreview
                                                            fileAccept="image/*"
                                                            editTooltipText="Update Profile Picture"
                                                            showDeleteButton={isEditing}
                                                            showEditButton={isEditing}
                                                            deleteTooltipText="Delete Profile Picture"
                                                            deleteHandler={() =>
                                                                setProfile({
                                                                    ...profile,
                                                                    profileImage: {
                                                                        url: "",
                                                                        file: null,
                                                                    },
                                                                })
                                                            }
                                                            editHandler={(e) =>
                                                                changeHandlerImageImproved(e, ({ file, url }) => {
                                                                    if (file?.type?.includes("image")) {
                                                                        setIsMediaType(true);
                                                                        uploadImage(e)
                                                                        setProfile({
                                                                            ...profile,
                                                                            profileImage: {
                                                                                url: url,
                                                                                file: file,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        setIsMediaType(false);
                                                                    }
                                                                })
                                                            }
                                                            styles={{
                                                                rootStyles: {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "150px",
                                                                },
                                                                childrenStyles: {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "150px",
                                                                },
                                                            }}>
                                                            <img
                                                                src={profile.profileImage?.url}
                                                                style={{
                                                                    width: "200px",
                                                                    height: "200px",
                                                                    objectFit: "contain",
                                                                    borderRadius: "50%",
                                                                    maxHeight: "150px",
                                                                }}
                                                                alt="Candidate Profile"
                                                            />
                                                        </CustomPreview>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row form-contact">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <SubHeader title={"Full Name"} isRequired={true} />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={profile?.name}
                                                            disabled={!isEditing}
                                                            onChange={(e) => {
                                                                setProfile({
                                                                    ...profile,
                                                                    name: e.target.value
                                                                })
                                                                setNameError(false)
                                                            }}
                                                            placeholder="Full name"
                                                        />
                                                        {
                                                            nameError && <span style={{
                                                                color: "red",
                                                                fontSize: "12px"
                                                            }}>Name is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <label className="font-sm color-text-mutted">
                                                            Email
                                                        </label> */}
                                                        <SubHeader title={"Email"} isRequired={true} />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={profile?.email}
                                                            disabled={!isEditing}
                                                            onChange={(e) => {
                                                                setProfile({
                                                                    ...profile,
                                                                    email: e.target.value
                                                                })
                                                                setEmailError(false)
                                                            }}
                                                            placeholder="email"
                                                        />
                                                        {
                                                            emailError && <span style={{
                                                                color: "red",
                                                                fontSize: "12px"
                                                            }}>Email is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <SubHeader title={"GST No."} isRequired={false} />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={profile?.gstNo}
                                                            disabled={!isEditing}
                                                            onChange={(e) => {
                                                                setProfile({
                                                                    ...profile,
                                                                    gstNo: e.target.value
                                                                })
                                                            }}
                                                            placeholder="gst no."
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <SubHeader title={"Contact No."} isRequired={true} />
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            disabled={true}
                                                            value={profile?.contactNo}
                                                            onChange={(e) => {
                                                                setProfile({
                                                                    ...profile,
                                                                    contactNo: e.target.value
                                                                })
                                                            }}
                                                            placeholder="contact no."
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <SubHeader title={"Bio"} isRequired={false} />
                                                        <textarea
                                                            className="form-control"
                                                            rows={4}
                                                            value={profile?.bio}
                                                            disabled={!isEditing}
                                                            onChange={(e) => {
                                                                setProfile({
                                                                    ...profile,
                                                                    bio: e.target.value
                                                                })
                                                            }}
                                                            placeholder="bio"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <SubHeader title={"Personal website"} isRequired={false} />
                                                        <input
                                                            className="form-control"
                                                            type="url"
                                                            value={profile?.website}
                                                            disabled={!isEditing}
                                                            onChange={(e) => {
                                                                setProfile({
                                                                    ...profile,
                                                                    website: e.target.value
                                                                })
                                                            }}
                                                            placeholder="website"
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <SubHeader title={"City"} isRequired={false} />
                                                                {/* <CustomReactSelect
                                                                    styles={statusDropdownColorStyles}
                                                                    id="outlined-select"
                                                                    fullWidth
                                                                    disabled={!isEditing}
                                                                    options={cityDataOption && cityDataOption?.map((item) => {
                                                                        return {
                                                                            label: item?.city_name,
                                                                            value: item?.city_id,
                                                                        };
                                                                    })}
                                                                    name="city"
                                                                    value={profile?.country}
                                                                    onChange={(e) => {
                                                                        setProfile({
                                                                            ...profile,
                                                                            city: e
                                                                        })
                                                                        getAreaData(e)
                                                                    }}
                                                                    label="Select City"
                                                                /> */}
                                                                <select
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={profile?.city}
                                                                    disabled={!isEditing}
                                                                    onChange={(e) => {
                                                                        setProfile({
                                                                            ...profile,
                                                                            city: e.target.value
                                                                        })
                                                                        getAreaData(e.target.value)
                                                                    }}
                                                                    placeholder="Select City"
                                                                >
                                                                    {
                                                                        cityDataOption && cityDataOption.map((item, index) => {
                                                                            return <option key={item?.city_id} value={item?.city_id}>{item.city_name}</option>
                                                                        }
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <SubHeader title={"Zip code"} isRequired={false} />
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={profile?.zipCode}
                                                                    disabled={!isEditing}
                                                                    onChange={(e) => {
                                                                        setProfile({
                                                                            ...profile,
                                                                            zipCode: e.target.value
                                                                        })
                                                                    }}
                                                                    placeholder="zipcode"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="box-button mt-15">
                                                        {isEditing ? (
                                                            <Button
                                                                variant="contained"
                                                                style={{ backgroundColor: logoRedColor }}
                                                                onClick={handleValidate}>
                                                                Save
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                style={{ backgroundColor: logoRedColor }}
                                                                onClick={() => setIsEditing(true)}>
                                                                Edit
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
