import axios from "axios";
import { apiHost } from "./home";

export const userLogin = (body) => {
    return axios.post(`${apiHost}/employerLogin`, body);
};

export const verifyOtp = (body, token) => {
    return axios.post(`${apiHost}/employerVerifyOtp`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const userLogout = () => {
    return axios.get(`${apiHost}/employerLogout`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};

export const employerProfileData = (body, token) => {
    return axios.put(`${apiHost}/employerProfileSetup`, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("employerToken")}`,
        },
    });
};