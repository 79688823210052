import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/logo.png"

const PostJobHeader = ({
  setOpenSideBar,
  openClass,
  handleOpen,
  handleRemove,
}) => {
  return (
    <>
      <header
        style={{
          WebkitAnimation:
            "700ms ease-in-out 0s normal none 1 running fadeInDown",
          animation: "700ms ease-in-out 0s normal none 1 running fadeInDown",
          WebkitBoxShadow: "0 8px 20px 0 rgba(0, 0, 0, 0.05)",
          boxShadow: "0 8px 20px 0 rgba(0, 0, 0, 0.05)",
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "999",
          left: "0",
          WebkitTransition: "all 0.3s ease 0s",
          transition: "all 0.3s ease 0s",
          borderBottom: "1px solid #e0e6f6",
          background: "#fff",
        }}
        className={"header"}>
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "90px",
            }}>
            {/* <div className="header-left"> */}
            <div
              className="header-logo"
              style={{
                height: "150px",
                marginTop: "10px",
                width: "250px",
                display: "flex",
                alignItems: "center",
              }}>
              <Link legacyBehavior href="/">
                <img alt="logo" src={logo} />
              </Link>
            </div>
            {/* </div> */}
            <div className="header-nav">
              <div
                style={{
                  marginLeft: "20px",
                }}
                className={`burger-icon burger-icon-white ${openClass && "burger-close"
                  }`}
                onClick={() => {
                  handleOpen();
                  handleRemove();
                }}>
                <span className="burger-icon-top" />
                <span className="burger-icon-mid" />
                <span className="burger-icon-bottom" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default PostJobHeader;
