import { Card } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import "chart.js/auto";
import Chart from "react-apexcharts";
import { logoBlueColor, logoRedColor } from "../Header";
import { useEffect, useState } from "react";
import { getMonthlyCandidateApplicationList } from "../../api/home";

const LineChart = ({
  styles: {
    rootStyles = {},
    content: { titleStyles = {}, figureStyles = {} } = {},
    textBackgroundColor,
    textColor,
  } = {},
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    getMonthlyCandidateApplicationList()
      .then((res) => {
        setJobData(res?.data?.data?.chart_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const series = [
    {
      name: "Count",
      data: jobData?.map((item) => item?.count),
    },
  ];

  const options = {
    colors: ["lightgreen", logoRedColor],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    title: {
      text: "Monthly Candidate Applications",
      align: "left",
      style: {
        fontSize: "18px", // Set the desired font size
        fontFamily: "Montserrat", // Set the desired font family
        fontWeight: "bold", // Set the desired font weight
        color: "#333", // Set the desired font color
      },
    },
    yaxis: {
      axisBorder: {
        borderColor: "black", // Color of the Y-axis line
      },
    },
    grid: {
      borderColor: "#949ca8",
      strokeDashArray: 4,
      // row: {
      //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //     opacity: 0.5
      // },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        // stops: [20, 100, 100, 100]
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      // categories: jobData?.map((item) => item?.title),
    },
  };

  return (
    <Card
      sx={{
        ...rootStyles,
        backgroundColor: "white",
        width: "100%",
        height: "400px",
        padding: "20px",
        boxShadow: "none",
      }}>
      <Chart options={options} series={series} type="area" height={350} />
    </Card>
  );
};

export default LineChart;
