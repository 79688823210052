/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import FindTalent from "../assets/imgs/FindTalent.svg";
import FindJobs from "../assets/imgs/Findjobs.svg";
import Header from "./Header";
import SEOData from "./SEOData";
import moment from "moment";

export const logoBlueColor = "#242265";
export const logoRedColor = "#D2232A";

const Home = () => {
  const router = useNavigate();

  // const { seoData, setSeoData } = useContext(Context);

  const handleDownloadApp = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const [openClass, setOpenClass] = useState("");

  const handleOpen = () => {
    document.body.classList.add("mobile-menu-active");
    setOpenClass("sidebar-visible");
  };

  const handleRemove = () => {
    if (openClass === "sidebar-visible") {
      setOpenClass("");
      document.body.classList.remove("mobile-menu-active");
    }
  };

  return (
    <Fragment>
      <SEOData keyword="Home" />
      <div>
        <div className="body-overlay-1" onClick={handleRemove} />
        <div className="background-image">
          <Header
            handleOpen={handleOpen}
            handleRemove={handleRemove}
            openClass={openClass}
          />
          <div
            style={{
              marginBottom: "50px",
            }}>
            <section className="section-box main-home-section">
              <div
                style={{
                  // marginBottom:"200px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "Center",
                }}>
                <div className="home-page-title">
                  <div
                    style={{ color: "#131313", height: "100%" }}
                    className="font-xxl color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                    India's <strong>Largest</strong> Job Portal
                  </div>
                  <p className="homeText color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                    Find your next STAR EMPLOYEE with easy !
                  </p>
                </div>
                <div
                  className="row main-home-section"
                  style={{ display: "flex" }}>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-40"
                    style={{
                      paddingRight: 0,
                    }}>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="banner-imgs wow animate__animated animate__fadeInUp">
                      <div className="block-1 img-1">
                        <div
                          style={{
                            display: "flex",
                            justify: "center",
                            paddingRight: 0,
                          }}>
                          <Tilt
                            glareEnable={true}
                            tiltMaxAngleX={10}
                            tiltMaxAngleY={10}
                            perspective={1000}
                            glareColor={"transparent"}>
                            <img
                              onClick={() => {
                                handleDownloadApp(
                                  "https://play.google.com/store/apps/details?id=com.kindjobs"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="img-responsive_home"
                              alt="kindjob"
                              src={FindJobs}
                            />
                          </Tilt>
                        </div>
                        <div>
                          <h4
                            style={{
                              cursor: "pointer",
                              marginTop: "10px",
                              color: "black",
                              fontWeight: "bold",
                            }}>
                            Find Jobs
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-40"
                    style={{
                      paddingRight: 0,
                    }}>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="banner-imgs wow animate__animated animate__fadeInUp">
                      <div className="block-1 img-2">
                        <div
                          style={{
                            display: "flex",
                            justify: "center",
                            paddingRight: 0,
                          }}>
                          <Tilt
                            glareEnable={true}
                            tiltMaxAngleX={10}
                            tiltMaxAngleY={10}
                            perspective={1000}
                            glareColor={"transparent"}>
                            <img
                              onClick={() => router("/employer")}
                              style={{ cursor: "pointer" }}
                              className="img-responsive_home"
                              alt="kindjob"
                              src={FindTalent}
                            />
                          </Tilt>
                        </div>
                        <div>
                          <h4
                            style={{
                              cursor: "pointer",
                              marginTop: "10px",
                              color: "black",
                              fontWeight: "bold",
                            }}>
                            Find Talent
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Fab
                                    className="home-call-icon"
                                    color="success"
                                    aria-label="edit"
                                    style={{
                                        position: "fixed",
                                        bottom: "20px",
                                        right: "20px",
                                    }}>
                                    <CallIcon />
                                </Fab> */}
              </div>

              <div
                className="row homeFooter"
                style={{
                  height: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <div className="col-md-6">
                  <span className="font-xs color-text-paragraph">
                    Copyright © {moment().year() || 2024}. Powered by Searchline Database Private Limited (Kind jobs). All rights reserved.
                  </span>
                </div>
                <div className="col-md-6 text-md-end text-start">
                  <div
                    className="footer-social"
                    style={{
                      color: "black",
                    }}>
                    <a
                      onClick={() => {
                        router("/refund-policy");
                      }}
                      className="font-xs mr-30 color-text-paragraph"
                      style={{ cursor: "pointer", textDecoration: "none" }}>
                      Refund Policy
                    </a>
                    <a
                      onClick={() => {
                        router("/pricing");
                      }}
                      className="font-xs mr-30 color-text-paragraph"
                      style={{ cursor: "pointer", textDecoration: "none" }}>
                      Pricing Policy
                    </a>
                    <a
                      onClick={() => {
                        router("/privacy");
                      }}
                      className="font-xs color-text-paragraph"
                      style={{ cursor: "pointer", textDecoration: "none" }}>
                      Privacy Policy
                    </a>
                    <a
                      onClick={() => {
                        router("/terms");
                      }}
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      className="font-xs color-text-paragraph ml-30">
                      Terms &amp; Conditions
                    </a>
                    <a
                      onClick={() => {
                        router("/termsService");
                      }}
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      className="font-xs color-text-paragraph mr-30 ml-30">
                      Terms &amp; Services
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
