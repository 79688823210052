import { Card } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import "chart.js/auto";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getTotalCandidateByAge } from "../../api/home";

const CandidateByAge = ({
  styles: {
    rootStyles = {},
    content: { titleStyles = {}, figureStyles = {} } = {},
    textBackgroundColor,
    textColor,
  } = {},
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    getTotalCandidateByAge()
      .then((res) => {
        setJobData(res?.data?.data?.chart_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const series1 = [
  //   {
  //     name: "18-24",
  //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 90, 60, 78],
  //   },
  //   {
  //     name: "25-34",
  //     data: [121, 34, 27, 11, 79, 92, 29, 31, 18, 90, 6, 24],
  //   },
  //   {
  //     name: "35-44",
  //     data: [121, 34, 27, 11, 79, 92, 29, 31, 18, 90, 6, 24],
  //   },
  //   {
  //     name: "45-54",
  //     data: [121, 34, 27, 11, 79, 92, 29, 31, 18, 90, 6, 24],
  //   },
  //   {
  //     name: "55-64",
  //     data: [121, 34, 27, 11, 79, 92, 29, 31, 18, 90, 6, 24],
  //   },
  //   {
  //     name: "65+",
  //     data: [121, 34, 27, 11, 79, 92, 29, 31, 18, 90, 6, 24],
  //   },
  // ];

  function transformOriginalArray(originalArray) {
    function transformAgeData(ageData) {
      const ageGroups = [
        "0-17",
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
      ];
      const transformedArray = ageGroups.map((group) => ({
        name: group,
        data: 0,
      }));

      ageData.forEach((entry) => {
        const ageGroupIndex = ageGroups.findIndex((group) => {
          const [min, max] = group.split("-").map(Number);
          return entry.age_cal >= min && entry.age_cal <= max;
        });

        if (ageGroupIndex !== -1) {
          transformedArray[ageGroupIndex].data += entry.count;
        }
      });

      return transformedArray;
    }

    return originalArray.map((month) => ({
      data: transformAgeData(month.age_data),
    }));
  }

  const originalArray = jobData;
  const transformedArray = transformOriginalArray(originalArray);
  const outputResult = transformData(transformedArray);

  function transformData(input) {
    const result = [];
    const nameIndexMap = {};

    input.forEach((innerArray) => {
      innerArray.data.forEach((item, index) => {
        if (!(item.name in nameIndexMap)) {
          nameIndexMap[item.name] = result.length;
          result.push({ name: item.name, data: [] });
        }
        result[nameIndexMap[item.name]].data.push(item.data);
      });
    });

    return result;
  }

  const series = outputResult;
  const options = {
    title: {
      text: "Candidates by Age",
      align: "left",
      style: {
        fontSize: "18px", // Set the desired font size
        fontFamily: "Montserrat", // Set the desired font family
        fontWeight: "bold", // Set the desired font weight
        color: "#333", // Set the desired font color
      },
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            enabled: false,
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        labels: false,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      type: "monrth",
      categories: jobData?.map((item) => item?.title.substring(0, 3)),
    },
    colors: [
      "#FF4560",
      "#FEB019",
      "#00E396",
      "#008FFB",
      "#33A1FD",
      "#F44336",
      "#9C27B0",
    ],
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: -15,
      offsetY: -10,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <Card
      sx={{
        ...rootStyles,
        backgroundColor: "white",
        width: "100%",
        height: "400px",
        padding: "20px",
        boxShadow: "none",
      }}>
      <Chart options={options} series={series} type="bar" height={350} />
    </Card>
  );
};

export default CandidateByAge;
