
import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import PersonIcon from "@mui/icons-material/Person";
import TollIcon from "@mui/icons-material/Toll";
import WorkIcon from "@mui/icons-material/Work";
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Context } from "../../Context/context";
import { getListCoinTransaction } from "../../api/home";
import logo from "../../assets/imgs/logo.png";
import { logoRedColor } from "../Header";
import PlanSelectModal from "./PlanSelectModal";

const PostJobSideBar = ({ handleOpen, openClass, handleRemove }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [count, setCount] = React.useState(0);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const {
    setInterviewerInfo,
    setCandidateRequirement,
    setBasicDetails,
    profileActions,
    setProfileActions,
    getsJob,
    setGetsJob,
    activeStep,
    setActiveStep,
    employerTab,
    setEmployerTab,
    setAlertMessage,
    setTotalCoins,
    totalCoins,
    openPlanDialog,
    setOpenPlanDialog
  } = useContext(Context);

  // const handleMenuValue = (value) => {
  //   if (value === "isProfilePageOpen") {
  //     navigate("/profile");
  //   } else if (value === "manageCoins") {
  //     navigate("/manage-coins");
  //   }
  //   setProfileActions(value);
  // };

  // const handlePostJob = () => {
  //   emptyState();
  //   setActiveStep(0);
  //   navigate("/postjob");
  // };

  const nFormatter = (num, digits = 2) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  const emptyState = () => {
    setBasicDetails({
      companyName: "",
      jobTitle: "",
      department: "",
      category: "",
      jobType: "",
      noOfOpenings: "",
      nightShift: false,
      jobLocation: "",
      jobCity: "",
      jobArea: "",
      address: "",
      receiveApplicationFrom: "",
      receiveCandidateFrom: "",
      payType: "",
      minSalary: "",
      maxSalary: "",
      additionalPerks: [],
      deposit: "",
      wfhReceiveApplication: "",
      wfhFieldJob: "",
      averageIncentive: "",
      depositAmount: "",
      depositType: "",
      depositOther: "",
      feeBePaidBy: "",
    });
    setCandidateRequirement({
      education: "",
      gender: "",
      experience: "",
      englishLevel: "",
      degreeFreference: [],
      languagePreference: [],
      skills: [],
      assets: [],
      ageCriteria: {
        minAge: "",
        maxAge: "",
      },
      anyAgeCriteria: "",
      minExperience: "",
      whichDepartment: "",
      candidateFromWhichDepartment: [],
      jobDescription: "",
      candidateDepartment: [],
    });
    setInterviewerInfo({
      interviewerDetails: "",
      recruiterName: "",
      HRWhatsappNumber: "",
      contactNo: "",
      typeOfInterview: "",
      interviewerCity: "",
      interviewerArea: "",
      interviewerAddress: "",
      companyAddressSameAsInterviewerAddress: false,
      companyCity: "",
      companyArea: "",
      companyAddress: "",
      manageCandidate: "",
    });
  };

  useEffect(() => {
    setFirstName(localStorage.getItem("userFirstName"));
  }, []);

  const handleNavTab = (value) => {
    setEmployerTab(value);
  };

  const [openAccount, setOpenAccount] = React.useState(false);

  const handleClickAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleLogout = () => {
    // setEmployerTab("logout");
    // localStorage.clear();
    // navigate("/");
    handleRemove()
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to logout?",
      showCancelButton: true,
      confirmButtonColor: logoRedColor,
      confirmButtonText: "Logout",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result?.isConfirmed) {
        // setEmployerTab("logout");
        localStorage.clear();
        handleRemove();
        setAlertMessage({
          message: "Logout Successfully",
          isOpen: true,
          type: "success",
        });
        navigate("/");
      }
    });
  };

  useEffect(() => {
    const body = {
      search: "",
      page_no: 1,
      page_record: 10,
    };
    getListCoinTransaction(body)
      .then((res) => {
        setCount(res?.data?.data?.total_coin);
        setTotalCoins(res?.data?.data?.total_coin);
        localStorage.setItem("employerToken", res?.data?.data?.token);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div
      style={{ width: "100%" }}
      className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${openClass}`}>
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-content-area" style={{ padding: 0 }}>
          <div
            className="perfect-scroll"
            style={{ width: "100%", height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}>
                <div>
                  <img
                    onClick={() => {
                      navigate("/employer");
                    }}
                    style={{ cursor: "pointer", width: "50%", height: "50%" }}
                    alt="logo"
                    width="auto"
                    src={logo}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    top: "60px",
                  }}
                  className={`burger-icon burger-icon-white ${openClass && "burger-close-postjob"
                    }`}
                  onClick={() => {
                    handleOpen();
                    handleRemove();
                  }}>
                  <span className="burger-icon-top" />
                  <span className="burger-icon-mid" />
                  <span className="burger-icon-bottom" />
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  padding: "10px",
                  // backgroundColor: "#fcedee",
                  // width: "100%",
                }}></div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#fce3e5",
                }}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",

                  }}>{`${nFormatter(
                    totalCoins ? totalCoins : 0,
                    2
                  )} coins`}</div>
                <Button
                  onClick={
                    () => {
                      handleRemove();
                      setOpenPlanDialog(true);

                    }}
                  varaint="contained"
                  style={{ backgroundColor: logoRedColor, color: "white" }}>
                  Buy Coins
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader">
                  <ListItemButton
                    style={{
                      backgroundColor:
                        employerTab === "dashboard" ? logoRedColor : "",
                      color: employerTab === "dashboard" ? "white" : "",
                    }}
                    onClick={() => {
                      setEmployerTab("dashboard");
                      handleRemove();
                    }}>
                    <ListItemIcon
                      style={{
                        color: employerTab === "dashboard" ? "white" : "",
                      }}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    style={{
                      backgroundColor:
                        employerTab === "jobs" ? logoRedColor : "",
                      color: employerTab === "jobs" ? "white" : "",
                    }}
                    onClick={() => {
                      setEmployerTab("jobs");
                      handleRemove();
                    }}>
                    <ListItemIcon
                      style={{
                        color: employerTab === "jobs" ? "white" : "",
                      }}>
                      <WorkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Jobs" />
                  </ListItemButton>
                  <ListItemButton
                    style={{
                      backgroundColor:
                        employerTab === "postJob" ? logoRedColor : "",
                      color: employerTab === "postJob" ? "white" : "",
                    }}
                    onClick={() => {
                      setEmployerTab("postJob");
                      handleRemove();
                    }}>
                    <ListItemIcon
                      style={{
                        color: employerTab === "postJob" ? "white" : "",
                      }}>
                      <MarkAsUnreadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Post a job" />
                  </ListItemButton>
                  <ListItemButton onClick={handleClickAccount}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Account" />
                    {openAccount ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openAccount} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            employerTab === "profile" ? logoRedColor : "",
                          color: employerTab === "profile" ? "white" : "",
                        }}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          setEmployerTab("profile");
                          handleRemove();
                        }}>
                        <ListItemIcon
                          style={{
                            color: employerTab === "profile" ? "white" : "",
                          }}>
                          <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                      </ListItemButton>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            employerTab === "manageCoins" ? logoRedColor : "",
                          color: employerTab === "manageCoins" ? "white" : "",
                        }}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          setEmployerTab("manageCoins");
                          handleRemove();
                        }}>
                        <ListItemIcon
                          style={{
                            color: employerTab === "manageCoins" ? "white" : "",
                          }}>
                          <TollIcon />
                        </ListItemIcon>
                        <ListItemText primary="Transactions" />
                      </ListItemButton>
                      {/* <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="D" />
              </ListItemButton> */}
                    </List>
                  </Collapse>
                  {/* <ListItemButton
                    style={{
                      backgroundColor:
                        employerTab === "report" ? logoRedColor : "",
                      color: employerTab === "report" ? "white" : "",
                    }}
                    onClick={() => {
                      setEmployerTab("report");
                      handleRemove();
                    }}>
                    <ListItemIcon
                      style={{
                        color: employerTab === "report" ? "white" : "",
                      }}>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Report" />
                  </ListItemButton> */}
                  <ListItemButton
                    onClick={handleLogout}
                    style={{
                      backgroundColor:
                        employerTab === "logout" ? logoRedColor : "",
                      color: employerTab === "logout" ? "white" : "",
                    }}>
                    <ListItemIcon
                      style={{
                        color: employerTab === "logout" ? "white" : "",
                      }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </List>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        openPlanDialog && (
          <PlanSelectModal />
        )
      }
    </div>
  );
};

export default PostJobSideBar;
