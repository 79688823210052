/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid } from "@mui/material";
import parse from "html-react-parser";
import { Context } from "../../Context/context";
import { getMainJobById } from "../../api/home";
import industry from "../../assets/imgs/page/job-single/industry.svg"
import jobLevel from "../../assets/imgs/page/job-single/job-level.svg"
import salary from "../../assets/imgs/page/job-single/salary.svg"
import experience from "../../assets/imgs/page/job-single/experience.svg"
import location from "../../assets/imgs/page/job-single/location.svg"

export const experienceOption = [
    {
        value: 1,
        label: "Min 1 Year",
    },
    {
        value: 2,
        label: "Min 3 Year",
    },
    {
        value: 3,
        label: "Min 5 Year",
    },
    {
        value: 4,
        label: "Min 10 Year",
    },
];

export default function JobDetails({ selectedJobId }) {
    const {
        setSelectedJob,
        selectedJob,
        setIsJobDetailsOpen,
    } = useContext(Context);

    useEffect(() => {
        getMainJobById(selectedJobId)
            .then((res) => {
                setSelectedJob(res?.data?.data?.job_data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <div>
                <section
                    className="section-box-2"
                    style={{ padding: "50px 10px 10px 10px", position: "relative" }}>
                    <div className="container">
                        <div className="row">
                            <div
                                onClick={() => setIsJobDetailsOpen(false)}
                                style={{
                                    position: "absolute",
                                    top: "-10px",
                                    left: "0px",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}>
                                <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
                                <span style={{ fontSize: "1.3rem", marginLeft: "10px" }}>
                                    Back
                                </span>
                            </div>
                        </div>
                        {/* <div className="banner-hero banner-image-single">
              <img src="assets/imgs/page/job-single/thumb.png" alt="kindjob" />
            </div> */}
                        <div style={{ padingTop: "0px" }}>
                            <Grid container>
                                <Grid item xs={12} sm={8} xl={8}>
                                    <h3>{`${selectedJob?.job_title || "Job Title"}`}</h3>
                                    <div className="mt-0 mb-10">
                                        <span className="card-briefcase">
                                            {selectedJob?.job_type}
                                        </span>
                                        <span className="card-time">
                                            {selectedJob?.job_location?.created_datetime
                                                ? moment(selectedJob?.job_location?.created_datetime).format(
                                                    "MM-DD-YYYY  h:mm:ss a"
                                                )
                                                : "-"}
                                        </span>
                                    </div>
                                </Grid>
                                {/* <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    xl={4}
                                    style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        onClick={() => {
                                            setIsJobDetailsOpen(false);
                                            setViewCandidateList(true);
                                        }}
                                        className="candidatelist-btn">
                                        Candidate List
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </div>
                        {/* <div className="border-bottom pt-10 " /> */}
                    </div>
                </section>
                <section className="section-box mt-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="job-overview">
                                    <h5 className="border-bottom pb-15 mb-30">
                                        Employment Information
                                    </h5>
                                    <div className="row">
                                        <div className="col-md-6 d-flex">
                                            <div className="sidebar-icon-item">
                                                <img
                                                    src={industry}
                                                    alt="kindjob"
                                                />
                                            </div>
                                            <div className="sidebar-text-info ml-10">
                                                <span className="text-description industry-icon mb-10">
                                                    Department
                                                </span>
                                                <strong className="small-heading">
                                                    {" "}
                                                    {/* {selectedJob?.job_experience?.candidates_industry_data?.filter(
                            (item) => item
                          ).length <= 0
                            ? "Not Available"
                            : selectedJob?.job_experience?.candidates_industry_data
                                .filter((item) => item)
                                .map((item, index) => {
                                  return index ===
                                    selectedJob?.job_experience
                                      ?.candidates_industry_data?.length -
                                      1
                                    ? `${item}`
                                    : `${item}/`;
                                })} */}
                                                    {
                                                        selectedJob?.department_name ?
                                                            selectedJob?.department_name :
                                                            "-"
                                                    }
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex mt-sm-15">
                                            <div className="sidebar-icon-item">
                                                <img
                                                    src={jobLevel}
                                                    alt="kindjob"
                                                />
                                            </div>
                                            <div className="sidebar-text-info ml-10">
                                                <span className="text-description joblevel-icon mb-10">
                                                    Job level
                                                </span>
                                                <strong className="small-heading">
                                                    {selectedJob?.job_experience?.experience === 1
                                                        ? "Freshers Only"
                                                        : selectedJob?.job_experience?.experience === 2
                                                            ? "Experienced Only"
                                                            : selectedJob?.job_experience?.experience === 3
                                                                ? "Any"
                                                                : ""}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-25">
                                        <div className="col-md-6 d-flex mt-sm-15">
                                            <div className="sidebar-icon-item">
                                                <img
                                                    src={salary}
                                                    alt="kindjob"
                                                />
                                            </div>
                                            <div className="sidebar-text-info ml-10">
                                                <span className="text-description salary-icon mb-10">
                                                    Salary
                                                </span>
                                                <strong className="small-heading">
                                                    {`${selectedJob?.job_pay?.minimum_salary} - ${selectedJob?.job_pay?.maximum_salary}`}
                                                    <span className="text-description">/month</span>
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex">
                                            <div className="sidebar-icon-item">
                                                <img
                                                    src={experience}
                                                    alt="kindjob"
                                                />
                                            </div>
                                            <div className="sidebar-text-info ml-10">
                                                <span className="text-description experience-icon mb-10">
                                                    Job Title
                                                </span>
                                                <strong className="small-heading">
                                                    {
                                                        selectedJob?.job_title ?
                                                            selectedJob?.job_title : "-"
                                                    }
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-25">
                    <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="assets/imgs/page/job-single/job-type.svg"
                          alt="kindjob"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description jobtype-icon mb-10">
                          Job type
                        </span>
                        <strong className="small-heading">Permanent</strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="assets/imgs/page/job-single/deadline.svg"
                          alt="kindjob"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description mb-10">Deadline</span>
                        <strong className="small-heading">10/08/2022</strong>
                      </div>
                    </div>
                  </div> */}
                                    <div className="row mt-25">
                                        {/* <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="assets/imgs/page/job-single/updated.svg"
                          alt="kindjob"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description jobtype-icon mb-10">
                          Updated
                        </span>
                        <strong className="small-heading">{moment(selectedJob?.job_location?.updated_datetime)}</strong>
                      </div>
                    </div> */}
                                        <div className="col-md-6 d-flex mt-sm-15">
                                            <div className="sidebar-icon-item">
                                                <img
                                                    src={location}
                                                    alt="kindjob"
                                                />
                                            </div>
                                            <div className="sidebar-text-info ml-10">
                                                <span className="text-description mb-10">Location</span>
                                                <strong className="small-heading">
                                                    {selectedJob?.job_location?.area_name
                                                        ? `${selectedJob?.job_location?.area_name || ""}, ${selectedJob?.job_location?.city_name
                                                        }`
                                                        : ` ${selectedJob?.job_location?.city_name || "Not available"}`}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="job-overview">
                                    <h5 className="border-bottom pb-15 mb-30">
                                        Interviewer Information
                                    </h5>
                                    {
                                        selectedJob?.job_interviewer_detail && selectedJob?.job_interviewer_detail?.recruiter_name ? (
                                            <>
                                                <div className="row mt-25">
                                                    <div className="col-md-6 d-flex mt-sm-15">
                                                        <div className="sidebar-icon-item">
                                                            <img
                                                                src={location}
                                                                alt="kindjob"
                                                            />
                                                        </div>
                                                        <div className="sidebar-text-info ml-10">
                                                            <span className="text-description mb-10">Location</span>
                                                            <strong className="small-heading">
                                                                {selectedJob?.job_interview_location?.area_name
                                                                    ? `${selectedJob?.job_interview_location?.area_name || ""}, ${selectedJob?.job_interview_location?.city_name
                                                                    }`
                                                                    : ` ${selectedJob?.job_interview_location?.city_name || "Not available"}`}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex mt-sm-15">
                                                        <div className="sidebar-icon-item">
                                                            <img
                                                                src={jobLevel}
                                                                alt="kindjob"
                                                            />
                                                        </div>
                                                        <div className="sidebar-text-info ml-10">
                                                            <span className="text-description mb-10">Interviwer Name</span>
                                                            <strong className="small-heading">
                                                                {
                                                                    selectedJob?.job_interviewer_detail && selectedJob?.job_interviewer_detail?.recruiter_name ?
                                                                        selectedJob?.job_interviewer_detail?.recruiter_name :
                                                                        "-"
                                                                }
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-25">
                                                    <div className="col-md-6 d-flex mt-sm-15">
                                                        <div className="sidebar-icon-item">
                                                            <img
                                                                src={jobLevel}
                                                                alt="kindjob"
                                                            />
                                                        </div>
                                                        <div className="sidebar-text-info ml-10">
                                                            <span className="text-description mb-10">Interviwer Email</span>
                                                            <strong className="small-heading">
                                                                {
                                                                    selectedJob?.job_interviewer_detail && selectedJob?.job_interviewer_detail?.contact_email ?
                                                                        selectedJob?.job_interviewer_detail?.contact_email :
                                                                        "-"
                                                                }
                                                            </strong>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 d-flex mt-sm-15">
                                                        <div className="sidebar-icon-item">
                                                            <img
                                                                src={jobLevel}
                                                                alt="kindjob"
                                                            />
                                                        </div>
                                                        <div className="sidebar-text-info ml-10">
                                                            <span className="text-description mb-10">HR Phone Number</span>
                                                            <strong className="small-heading">
                                                                {
                                                                    selectedJob?.job_interviewer_detail && selectedJob?.job_interviewer_detail?.hr_hatsapp_number ?
                                                                        selectedJob?.job_interviewer_detail?.hr_hatsapp_number :
                                                                        "-"
                                                                }
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            (
                                                <>
                                                    <div className="row mt-25">
                                                        <div className="col-md-6 d-flex mt-sm-15">
                                                            <div className="sidebar-icon-item">
                                                                <img
                                                                    src={location}
                                                                    alt="kindjob"
                                                                />
                                                            </div>
                                                            <div className="sidebar-text-info ml-10">
                                                                <span className="text-description mb-10">Location</span>
                                                                <strong className="small-heading">
                                                                    {selectedJob?.job_interview_location?.area_name
                                                                        ? `${selectedJob?.job_interview_location?.area_name || ""}, ${selectedJob?.job_interview_location?.city_name
                                                                        }`
                                                                        : ` ${selectedJob?.job_interview_location?.city_name || "Not available"}`}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 d-flex mt-sm-15">
                                                            <div className="sidebar-icon-item">
                                                                <img
                                                                    src={jobLevel}
                                                                    alt="kindjob"
                                                                />
                                                            </div>
                                                            <div className="sidebar-text-info ml-10">
                                                                <span className="text-description mb-10">Interviwer Name</span>
                                                                <strong className="small-heading">
                                                                    {
                                                                        selectedJob?.user && selectedJob?.user?.first_name ?
                                                                            `${selectedJob?.user?.first_name} ${selectedJob?.user?.last_name}` :
                                                                            "-"
                                                                    }
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-25">
                                                        <div className="col-md-6 d-flex mt-sm-15">
                                                            <div className="sidebar-icon-item">
                                                                <img
                                                                    src={jobLevel}
                                                                    alt="kindjob"
                                                                />
                                                            </div>
                                                            <div className="sidebar-text-info ml-10">
                                                                <span className="text-description mb-10">Interviwer Email</span>
                                                                <strong className="small-heading">
                                                                    {
                                                                        selectedJob?.user && selectedJob?.user?.email ?
                                                                            selectedJob?.user?.email :
                                                                            "-"
                                                                    }
                                                                </strong>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 d-flex mt-sm-15">
                                                            <div className="sidebar-icon-item">
                                                                <img
                                                                    src={jobLevel}
                                                                    alt="kindjob"
                                                                />
                                                            </div>
                                                            <div className="sidebar-text-info ml-10">
                                                                <span className="text-description mb-10">Recruiter Phone Number</span>
                                                                <strong className="small-heading">
                                                                    {
                                                                        selectedJob?.user && selectedJob?.user?.phone_number ?
                                                                            selectedJob?.user?.phone_number :
                                                                            "-"
                                                                    }
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                    }
                                </div>
                                <div className="content-single mb-20">
                                    <h4>Job Description</h4>
                                    <p>{parse(`${selectedJob?.job_description}`)}</p>
                                    {/* <h4>Essential Knowledge, Skills, and Experience</h4> */}
                                    <h4> Skills</h4>
                                    <ul>
                                        {selectedJob?.skills_data?.map((item, index) => {
                                            return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                    <h4>Assets</h4>
                                    <ul>
                                        {selectedJob?.asset_data?.map((item, index) => {
                                            return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                    <h4>Additional Perks</h4>
                                    <ul>
                                        {selectedJob?.additional_perks?.map((item, index) => {
                                            return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                    {/* <h4>Department</h4>
                  <ul>
                    {selectedJob?.additional_perks?.map((item, index) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul> */}
                                    <h4>Required Degree</h4>
                                    <ul>
                                        {selectedJob?.degree_data?.length > 0
                                            ? selectedJob?.degree_data?.map((item, index) => {
                                                return <li key={index}>{item}</li>;
                                            })
                                            : "No Degree Required"}
                                    </ul>
                                </div>
                                {/* <div className="author-single">
                    <span>AliThemes</span>
                  </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
