import MenuIcon from '@mui/icons-material/Menu';
import { Hidden, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
import { Context } from "../Context/context";
import logo from "../assets/imgs/logo.png";
import SidebarMain from "./SidebarMain";

export const logoBlueColor = "#242265";
export const logoRedColor = "#D2232A";


export const handleDownloadApp = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};


const Header = ({ handleOpen, handleRemove, openClass }) => {

    const [scroll, setScroll] = useState(0);
    // const [showEmployerLoginBtn, setShowEmployerLoginBtn] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [openSidebar, setOpenSidebar] = React.useState(false);

    const {
        setIsLoginPageOpen,
        login,
        setLogin,
    } = React.useContext(Context);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    }, []);

    //   const handleLogout = () => {
    //     userLogout().then((res) => {
    //       setIsLoginPageOpen(false);
    //       navigate("/");
    //       localStorage.removeItem("employerToken");
    //       localStorage.removeItem("isOtpVerifiedUser");
    //       localStorage.removeItem("userInfo");
    //       setLogin({
    //         phoneNo: "",
    //         otp: "",
    //         setOtp: false,
    //         setPhone: false,
    //         detailsPage: false,
    //       });
    //       setUser({});
    //       setToken("");
    //       setIsOtpVerified(false);
    //     });
    //   };

    // const handleLogin = () => {
    //     setIsLoginPageOpen(true);
    //     navigate("/employer/login");
    // };



    const handleEmployerLogin = () => {
        let token = localStorage.getItem("employerToken") ? true : false;
        if (
            (JSON.parse(localStorage.getItem("is_profile_created")) === 1 ||
                JSON.parse(localStorage.getItem("userData")) === true) &&
            JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true &&
            token
        ) {
            localStorage.setItem("welcomeMessage", true);
            navigate("/postjob");
        } else {
            if (JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true) {
                setLogin({
                    ...login,
                    detailsPage: true,
                    setPhone: false,
                });
                navigate("/employer/login");
            } else {
                setLogin({
                    ...login,
                    detailsPage: false,
                    setPhone: true,
                });
                navigate("/employer/login");
                setIsLoginPageOpen(true);
            }
        }
    };

    // const handleDownloadAppIOS = () => {
    //     Swal.fire({
    //         icon: "info",
    //         confirmButtonColor: logoRedColor,
    //         title: "Coming Soon!",
    //     });
    // };

    return (
        <>
            <header
                style={
                    scroll
                        ? {
                            WebkitAnimation:
                                "700ms ease-in-out 0s normal none 1 running fadeInDown",
                            animation:
                                "700ms ease-in-out 0s normal none 1 running fadeInDown",
                            WebkitBoxShadow: "0 8px 20px 0 rgba(0, 0, 0, 0.05)",
                            boxShadow: "0 8px 20px 0 rgba(0, 0, 0, 0.05)",
                            position: "fixed",
                            top: "0",
                            width: "100%",
                            zIndex: "999",
                            left: "0",
                            WebkitTransition: "all 0.3s ease 0s",
                            transition: "all 0.3s ease 0s",
                            borderBottom: "1px solid #e0e6f6",
                            background: "#fff",
                        }
                        : null
                }
                className={scroll ? "header sticky-bar" : "header sticky-bar"}
            >
                <div className="container">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "90px",
                        }}
                    >
                        <div
                            className="header-logo"
                            style={{
                                height: "150px",
                                marginTop: "10px",
                                width: "250px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Link to="/">
                                <a>
                                    <img alt="logo" src={logo} />
                                </a>
                            </Link>
                        </div>
                        <Hidden mdDown>
                            <div className="header-nav">
                                <nav
                                    className="nav-main-menu"
                                    style={{ display: "flex", justifyContent: "center" }}
                                >
                                    <ul className="main-menu" style={{ marginBottom: "0px" }}>
                                        <li>
                                            <Link
                                                to="/"
                                                className={
                                                    location.pathname === "/" || location.pathname === "/employer"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/about"
                                                className={location.pathname === "/about" ? "active" : ""}
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/contact"
                                                className={location.pathname === "/contact" ? "active" : ""}
                                            >
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            {location.pathname === "/" && (
                                <div className="header-right">
                                    <div className="block-signin" style={{ display: "flex", gap: "10px" }}>
                                        <button
                                            onClick={() =>
                                                handleDownloadApp(
                                                    "https://play.google.com/store/apps/details?id=com.kindjobs"
                                                )
                                            }
                                            style={{
                                                width: "180px",
                                                color: "white",
                                                backgroundColor: "transparent",
                                                border: "2px solid white",
                                                fontWeight: "bold",
                                                borderRadius: "5px",
                                                padding: "10px"
                                            }}
                                            variant="outlined"
                                            className="hover-up"
                                        >
                                            Find a Job
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate("/employer");
                                            }}
                                            style={{
                                                width: "180px",
                                                border: "none",
                                                color: logoRedColor,
                                                backgroundColor: "white",
                                                borderColor: logoRedColor,
                                                fontWeight: "bold",
                                                borderRadius: "5px"
                                            }}
                                            variant="outlined"
                                            className="hover-up"
                                        >
                                            Find a Talent
                                        </button>
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/employer" && (
                                <div className="header-right">
                                    <div className="block-signin" style={{ display: "flex" }}>
                                        <button
                                            onClick={handleEmployerLogin}
                                            style={{
                                                width: "100%", border: "none",
                                                color: "white",
                                                backgroundColor: logoRedColor,
                                                borderColor: logoRedColor,
                                                fontWeight: "bold",
                                                borderRadius: "5px",
                                                padding: "10px 20px"
                                            }}
                                            className=" hover-up"
                                        >
                                            Employer Login
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton onClick={() => {
                                setOpenSidebar(true)
                            }}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    </div>
                </div>
                <SidebarMain open={openSidebar} setOpenSidebar={setOpenSidebar} />
            </header>
        </>
    );
};

export default Header;
