import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { getListCoinTransaction } from "../api/home";
import { logoRedColor } from "./Header";
import { backgroundLightColor } from "./PostJob";
import TablePaginationActions from "./customTable/TablePaginationActions";
import { Context } from "../Context/context";


const ManageCoins = () => {
    // const [filter, setFilter] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [coinTransaction, setCoinTransaction] = React.useState([]);
    const [count, setCount] = React.useState(0);
    // const [allTransaction, setAllTransaction] = React.useState([]);

    const {
        setOpenPlanDialog,
      } = useContext(Context);

    // const columnConfig = [
    //   {
    //     id: "title",
    //     label: "Transaction details",
    //     align: "left",
    //   },
    //   {
    //     id: "coin",
    //     label: "Coin",
    //     align: "left",
    //   },
    //   {
    //     id: "status",
    //     label: "Status",
    //     align: "left",
    //   },

    //   {
    //     id: "created_datetime",
    //     label: "Date",
    //     align: "left",
    //   },
    // ];

    // const getFormattedData = (data) => {
    //   return {
    //     id: {
    //       data: data?._id,
    //       display: false,
    //       id: "id",
    //     },
    //     title: {
    //       data: data?.title,
    //       display: true,
    //       id: "title",
    //     },
    //     coin: {
    //       data: data?.coin,
    //       display: true,
    //       id: "coin",
    //     },
    //     status: {
    //       data: data?.status,
    //       display: true,
    //       id: "status",
    //     },
    //     created_datetime: {
    //       data: moment(data?.created_datetime).format("DD/MM/YYYY"),
    //       display: true,
    //       id: "created_datetime",
    //     },
    //     actions: {
    //       hide: true,
    //     },
    //   };
    // };

    useEffect(() => {
        setRowsPerPage(5);
        setPage(0);
    }, []);

    useEffect(() => {
        const body = {
            search: "",
            page_no: page + 1,
            page_record: rowsPerPage,
        };
        getListCoinTransaction(body)
            .then((res) => {
                // setAllTransaction(res?.data?.data?.transaction_list?.rows);
                setCoinTransaction(res?.data?.data);
                setCount(res?.data?.data?.transaction_list?.count);
                localStorage.setItem("employerToken", res?.data?.data?.token);
            })
            .catch((err) => {
                console.log("err", err);
            });
    }, [page, rowsPerPage]);

    const handleAddCoins = () => {
        Swal.fire({
            icon: "info",
            html: 'For more coins, please contact the Kindjobs admin.<br /><br />' +
                "Contact No. :  <strong>+919979111393</strong><br /><br />" +
                "Email : <strong>hello@kindjobs.co</strong",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: logoRedColor,
            confirmButtonText: "OK",
            showLoaderOnConfirm: true,
        })
    };

    // useEffect(() => {
    //   if (allTransaction) {
    //     const data = allTransaction.map((item) => getFormattedData(item));

    //     setCoinTransaction(data);
    //   }
    // }, [allTransaction]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const nFormatter = (num, digits = 2) => {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return num >= item.value;
            });
        return item
            ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
            : "0";
    };

    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                    style={{ width: "100%" }}>
                    <Grid item xs={12} style={{ maxWidth: "100%" }}>
                        <Paper
                            style={{
                                backgroundColor: backgroundLightColor,
                            }}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                style={{ padding: "20px" }}>
                                <Grid item xs={8}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item style={{ fontSize: "1rem", fontWeight: "bold" }}>
                                            Total Coins
                                        </Grid>
                                        <Grid
                                            item
                                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                                            {
                                                // nFormatter(
                                                coinTransaction?.total_coin
                                                    ? coinTransaction?.total_coin
                                                    : 0
                                                // 2
                                                // )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{ display: "flex", justifyContent: "right" }}>
                                        {/* <Grid item>
                        <Button variant="outlined">Transfer Coins</Button>
                      </Grid> */}
                                        <Grid item>
                                            <Button
                                                onClick={() => {
                                                    setOpenPlanDialog(true);
                                                }}
                                                variant="contained"
                                                style={{
                                                    backgroundColor: logoRedColor,
                                                    color: "white",
                                                }}>
                                                Add Coins
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} style={{ maxWidth: "100%" }}>
                        <h4>All transactions</h4>
                        <Grid container direction="column" spacing={2}>
                            {/* <Grid item style={{ margin: "10px 0px 0px 10px" }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Chip
                        style={{
                          width: "100%",
                          backgroundColor:
                            filter === "all" ? logoBlueColorLight : "white",
                          color: filter === "all" ? logoBlueColor : "black",
                          border:
                            filter === "all"
                              ? `1px solid ${logoBlueColor}`
                              : "1px solid black",
                        }}
                        onClick={() => {
                          setFilter("all");
                        }}
                        label="All"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        style={{
                          width: "100%",
                          backgroundColor:
                            filter === "coinsAdded"
                              ? logoBlueColorLight
                              : "white",
                          color:
                            filter === "coinsAdded" ? logoBlueColor : "black",
                          border:
                            filter === "coinsAdded"
                              ? `1px solid ${logoBlueColor}`
                              : "1px solid black",
                        }}
                        onClick={() => {
                          setFilter("coinsAdded");
                        }}
                        label="Coins Added"
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                            <Grid
                                item
                                xs={12}
                                style={{ marginTop: "20px", maxWidth: "100%" }}>
                                <TableContainer
                                    component={Paper}
                                    style={{
                                        backgroundColor: backgroundLightColor,
                                    }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: "bold" }}>
                                                    Transaction details
                                                </TableCell>
                                                <TableCell style={{ fontWeight: "bold" }} align="right">
                                                    Coin
                                                </TableCell>
                                                <TableCell style={{ fontWeight: "bold" }} align="right">
                                                    Status
                                                </TableCell>
                                                <TableCell style={{ fontWeight: "bold" }} align="right">
                                                    Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {coinTransaction?.transaction_list?.rows?.length > 0 ? (
                                                coinTransaction?.transaction_list?.rows?.map(
                                                    (row, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}>
                                                            <TableCell component="th" scope="row">
                                                                {row?.title}
                                                            </TableCell>
                                                            <TableCell align="right">{`${row?.transaction_type === 1 ? "+" : "-"
                                                                } ${row?.coin}`}</TableCell>
                                                            <TableCell align="right">
                                                                {row?.status === 1 ? "Success" : "Failed"}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {moment(row?.created_datetime).format(
                                                                    "MM/DD/YYYY"
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: "center",
                                                        }}>
                                                        No Transaction Found
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        sx={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            '.MuiTablePagination-selectLabel': {
                                                marginBottom: 0
                                            },
                                            '.MuiTablePagination-displayedRows': {
                                                marginBottom: 0
                                            }
                                        }}

                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={count}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ManageCoins;
