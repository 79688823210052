import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Context } from '../../Context/context';
import { getListCoinTransaction, getPlansData, orderPaymentsVerify, paymentOrders } from '../../api/home';
import { useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { logoRedColor } from "../Header";

const PlanSelectModal = () => {


    const {
        setTotalCoins,
        setAlertMessage,
        openPlanDialog,
        setOpenPlanDialog
    } = React.useContext(Context);

    const [plan, setPlan] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPlansData(true)
            .then((res) => {
                setPlan(res?.data?.data?.plan_data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    };

    const handlePayment = async (data) => {
        let info = JSON.parse(localStorage.getItem('userInfo'));
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert("you are offline");
            return;
        }

        try {
            const options = {
                key: 'rzp_live_vHWc1XtRGeqKvX',
                secret: "Kixvi8tJ295ZleP7hYFFcaHN", // Replace with your Razorpay key
                amount: data?.order?.amount,
                currency: 'INR',
                name: "Kind Jobs",
                description: 'Description of the purchase',
                order_id: data?.order?.id,
                image: "https://www.kindjobs.co/static/media/logo.f258377dfb0dcadbf73c.png",
                handler: async function (response) {
                    const body = {
                        "order_id": data?.order?.id,
                        "payment_id": response.razorpay_payment_id,
                        "payment_order_id": data?.order?.notes?.payment_order_id,
                    }

                    orderPaymentsVerify(body).then((res) => {
                        console.log(res, "res");
                        const body = {
                            search: "",
                            page_no: 1,
                            page_record: 10,
                        };
                        setAlertMessage({
                            message: "Payment Successful",
                            isOpen: true,
                            type: "success",
                        });
                        getListCoinTransaction(body)
                            .then((res) => {
                                setTotalCoins(res?.data?.data?.total_coin);
                            })
                            .catch((err) => {
                                console.log("err", err);
                            });
                    }).catch((err) => {
                        console.log(err, "err");
                    });
                },
                prefill: {
                    name: `${info?.first_name} ${info?.last_name}`,
                    email: info?.email, // Prefilling email if available
                    contact: info?.phone_number,
                },
                theme: {
                    color: '#F37254',
                },
                modal: {
                    ondismiss: function (response) {
                        console.log("Payment failed", response);
                        // setAlertMessage({
                        //     message: "Payment failed",
                        //     isOpen: true,
                        //     type: "danger",
                        // });
                        Swal.fire({
                            html: "<h3>Transaction Cancelled!<h3>",
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonColor: logoRedColor,
                            confirmButtonText: "Try Again",
                            showLoaderOnConfirm: true,
                          }).then(function(result) {
                            if (result.isConfirmed) {
                              setOpenPlanDialog(true);
                            }
                          })
                    }
                },
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

            // Create a new instance of Razorpay
        } catch (error) {
            console.error('Error occurred during payment:', error);
        }
    };


    const paymentOrderData = async (item) => {
        const body = {
            plan_id: item?.plan_id,
        }

        let token = localStorage.getItem("employerToken")
        if (token) {
            paymentOrders(body).then((res) => {
                handlePayment(res?.data?.data);
            }).catch((err) => {
                console.log(err, "err");
            }).finally(() => {
                setIsLoading(false)
            });
        }
    }

    const handlePlan = async (item, index) => {
        // setOpenPlanDialog(false);
        setIsLoading(index)
        paymentOrderData(item);
    }


    return (
        <Dialog
            open={openPlanDialog}
            scroll="paper"
            maxWidth="xl"
            sx={{
                backgroundColor: "rgba(0,0,0,0.5)",
            }}
            fullWidth={true}
            onClose={() => setOpenPlanDialog(false)}
        >
            <DialogTitle sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                padding: "10px 24px"
            }}>Select Plan</DialogTitle>
            <IconButton
                style={{ position: "absolute", right: "10px", top: "20px" }}
                edge="start"
                color="inherit"
                onClick={() => setOpenPlanDialog(false)}
                aria-label="close">
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{
                position: "relative",
                backgroundColor: "rgba(0,0,0,0.1)",


            }}>
                <Grid container spacing={5} sx={{
                    padding: "20px",
                }}>
                    {
                        plan?.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} lg={3} md={4} sm={6}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "30px 15px 30px 15px",
                                            backgroundColor: "#014983",
                                            color: "white",
                                            width: "100%",
                                            height: "100%",
                                            position: "relative",
                                            alignItems: "center",
                                            gap: "30px",
                                        }}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "white",
                                                color: "#014983",
                                                padding: "10px",
                                                left: "-45px",
                                                width: "190px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontWeight: "900",
                                                fontSize: "1.2rem",
                                                transform: "rotate(-0.12turn)",
                                                boxShadow: "-1px 14px 0px -2px #014983",
                                                // boxShadow: "0px 15px 10px -15px #014983",
                                            }}>
                                            {item?.coins}/- INR
                                        </span>
                                        <span
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                fontSize: "2.1rem",
                                                width: "100%",
                                                position: "relative",
                                                right: 0,
                                                display: "flex",
                                                justifyContent: "right",
                                                lineHeight: "1",
                                            }}>
                                            <div style={{ width: "80%" }}>{
                                                item?.title.toUpperCase()
                                            }
                                            </div>
                                        </span>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "15px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontSize: "1rem",
                                            }}>
                                            {
                                                item?.note.split("\n").map((note, index) => {
                                                    return (
                                                        <span key={index} style={{ textAlign: "center",fontWeight: "600" }}>
                                                            {note}
                                                        </span>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "flex-end",
                                            height: "100%",
                                        }}>
                                            <Button
                                                onClick={
                                                    () => handlePlan(item, index)
                                                }
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "#014983",
                                                    border: "1px solid white",
                                                    fontWeight: 600,
                                                    "&:hover": {
                                                        backgroundColor: "#014983",
                                                        color: "white",
                                                    },
                                                }}>
                                                {" "}
                                                Select {index === isLoading && <CircularProgress
                                                    sx={{
                                                        color: (theme) => '#1a90ff',
                                                        marginLeft: 1
                                                    }}
                                                    size={20}
                                                    thickness={6}
                                                />}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        }
                        )
                    }


                </Grid>
            </DialogContent>


        </Dialog >
    )
}

export default PlanSelectModal