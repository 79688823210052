import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getLocationCountByCandidateGender } from "../../api/home";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//     createData('Surat', 159, 6.0, 24),
//     createData('Ahemdabad', 237, 9.0, 37),
//     createData('Vadodra', 262, 16.0, 24),
//     createData('Gandhi Nagar', 305, 3.7, 67),
//     createData('Bihar', 356, 16.0, 49),
//     createData('Madhya Pradesh', 305, 3.7, 67),
//     createData('Kerla', 356, 16.0, 49),
// ];

export default function CandidateByLocation({ styles = {} }) {
  const [jobData, setJobData] = React.useState([]);

  const rows = jobData?.map((item) =>
    createData(
      `${item?.city_name}`,
      Number(item?.total_female) + Number(item?.total_male),
      Number(item?.total_male),
      Number(item?.total_female)
    )
  );

  React.useEffect(() => {
    getLocationCountByCandidateGender()
      .then((res) => {
        setJobData(res?.data?.data?.chart_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <TableContainer
      sx={{
        ...styles,
        padding: "20px",
      }}
      component={Paper}>
      <span
        style={{
          paddingLeft: "10px",
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color: "#333",
        }}>
        Candidates by Location
      </span>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottom: "none",
                fontSize: "15px",
                fontWeight: "bold",
              }}>
              {" "}
              Location
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              align="right">
              All
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              align="right">
              Male (%)
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              align="right">
              Female (%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            ".MuiTableCell-root": {
              padding: "16px 16px 0px 16px",
            },
          }}>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ borderBottom: "none" }}>
              <TableCell
                sx={{ borderBottom: "none" }}
                component="th"
                scope="row">
                {row.name}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="right">
                {row.calories}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="right">
                {row.fat}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="right">
                {row.carbs}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
