import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/context";
import { getListCoinTransaction, getPlansData, orderPaymentsVerify, paymentOrders } from "../api/home";
import moneyBack from "../assets/imgs/money_back.png";
import Layout from "./Layout";


const Pricing = () => {

  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };





  const {
    setIsLoginPageOpen,
    login,
    setLogin,
    setTotalCoins,
    setAlertMessage
  } = React.useContext(Context);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // const paymentOrderData = () => {
  //   const body = {
  //     plan_id: 2,
  //   }
  //   let token = localStorage.getItem("employerToken") ? true : false;
  //   if (token) {
  //     paymentOrders(body).then((res) => {
  //       console.log(res, "res");
  //     }).catch((err) => {
  //       console.log(err, "err");
  //     });
  //   }
  // }

  const handlePayment = async (data) => {
    let info = JSON.parse(localStorage.getItem('userInfo'));
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert("you are offline");
      return;
    }

    try {
      const options = {
        key: 'rzp_live_vHWc1XtRGeqKvX',
        // secret: "Kixvi8tJ295ZleP7hYFFcaHN", // Replace with your Razorpay key
        amount: data?.order?.amount,
        currency: 'INR',
        name: "Kind Jobs",
        description: 'Description of the purchase',
        order_id: data?.order?.id,
        image: "https://www.kindjobs.co/static/media/logo.f258377dfb0dcadbf73c.png",
        handler: async function (response) {
          const body = {
            "order_id": data?.order?.id,
            "payment_id": response.razorpay_payment_id,
            "payment_order_id": data?.order?.notes?.payment_order_id,
          }

          orderPaymentsVerify(body).then((res) => {
            console.log(res, "res");
            const body = {
              search: "",
              page_no: 1,
              page_record: 10,
            };
            setAlertMessage({
              message: "Payment Successful",
              isOpen: true,
              type: "success",
            });
            getListCoinTransaction(body)
              .then((res) => {
                setTotalCoins(res?.data?.data?.total_coin);
              })
              .catch((err) => {
                console.log("err", err);
              });
          }).catch((err) => {
            console.log(err, "err");
          });
        },
        prefill: {
          name: `${info?.first_name} ${info?.last_name}`,
          email: info?.email, // Prefilling email if available
          contact: info?.phone_number,
        },
        theme: {
          color: '#F37254',
        },
        modal: {
          ondismiss: function (response) {
            console.log("Payment failed", response);
            // setAlertMessage({
            //   message: "Payment failed",
            //   isOpen: true,
            //   type: "danger",
            // });
          }
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      // Create a new instance of Razorpay
    } catch (error) {
      console.error('Error occurred during payment:', error);
    }
  };


  const paymentOrderData = async (item) => {
    const body = {
      plan_id: item?.plan_id,
    }

    let token = localStorage.getItem("employerToken")
    if (token) {
      paymentOrders(body).then((res) => {
        handlePayment(res?.data?.data);
      }).catch((err) => {
        console.log(err, "err");
      });
    }
  }

  const handlePlan = async (item) => {
    setSelectedPlan(item);
    let token = localStorage.getItem("employerToken") ? true : false;
    if (
      (JSON.parse(localStorage.getItem("is_profile_created")) === 1 ||
        JSON.parse(localStorage.getItem("userData")) === true) &&
      JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true &&
      token
    ) {
      localStorage.setItem("welcomeMessage", true);
      navigate("/postjob");
      paymentOrderData(item);
      // openCheckout();
    } else {
      if (JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true) {
        setLogin({
          ...login,
          detailsPage: true,
          setPhone: false,
        });
        navigate("/employer/login");
      } else {
        setLogin({
          ...login,
          detailsPage: false,
          setPhone: true,
        });
        navigate("/employer/login");
        setIsLoginPageOpen(true);
      }
    }
  }

  useEffect(() => {
    getPlansData(true)
      .then((res) => {
        setPlan(res?.data?.data?.plan_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <Layout>
      <section className="section-box">
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: 0.9,
            gap: "60px",
          }}>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              lineHeight: 0.9,
              gap: "10px",
            }}>
            <div className="pricing-title-blue wow animate__animated animate__fadeInUp">
              MINIMUN PRICES,
            </div>
            <div className="pricing-title-red wow animate__animated animate__fadeInUp">
              MAXIMUM BENEFITS!
            </div>
            <p className="font-pricing  color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              With Kind Jobs, you get a wide exposure to qualified candidates
              for your company!
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "13px",
            }}>
            <span className="pricing-title-blue">NO</span>{" "}
            <span className="pricing-title-red">RESULT,</span>{" "}
            <span className="pricing-title-blue">NO</span>{" "}
            <span className="pricing-title-red">MONEY </span>{" "}
            <span className="pricing-title-blue">WITH</span>
            <img className="money_back_img" src={moneyBack} alt="moneyback" />
          </div>
          <Grid container spacing={4} className="mb-100">
            {
              plan?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} lg={3} md={4} sm={6}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "30px 15px 30px 15px",
                        backgroundColor: "#014983",
                        color: "white",
                        width: "100%",
                        position: "relative",
                        alignItems: "center",
                        gap: "40px",
                      }}>
                      <span
                        style={{
                          position: "absolute",
                          backgroundColor: "white",
                          color: "#014983",
                          padding: "10px",
                          left: "-45px",
                          width: "190px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "900",
                          fontSize: "1.2rem",
                          transform: "rotate(-0.12turn)",
                          boxShadow: "-1px 14px 0px -2px #014983",
                          // boxShadow: "0px 15px 10px -15px #014983",
                        }}>
                        {item?.coins}/- INR
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          fontSize: "2.1rem",
                          width: "100%",
                          position: "relative",
                          right: 0,
                          display: "flex",
                          justifyContent: "right",
                        }}>
                        <div style={{ width: "80%" }}>{
                          item?.title.toUpperCase()
                        }
                        </div>
                      </span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "25px",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "1rem",
                        }}>
                        {
                          item?.note.split("\n").map((note, index) => {
                            return (
                              <span key={index} style={{ textAlign: "center" }}>
                                {note}
                              </span>
                            );
                          })
                        }
                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "flex-end",
                        height: "100%",
                      }}>
                        <Button
                          onClick={
                            () => handlePlan(item)
                          }
                          variant="contained"
                          sx={{
                            backgroundColor: "white",
                            color: "#014983",
                            border: "1px solid white",
                            fontWeight: 600,
                            "&:hover": {
                              backgroundColor: "#014983",
                              color: "white",
                            },
                          }}>
                          {" "}
                          Select
                        </Button>
                      </div>
                    </div>
                  </Grid>
                )
              }
              )
            }


          </Grid>
        </div>
      </section>
    </Layout>
  );
};

export default Pricing;
