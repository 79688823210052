/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Chip, Divider, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/context";
import CKeditor from "../CKEditor";
import CustomReactSelect from "../CustomReactSelect";
import { logoRedColor } from "../Header";
import {
  Header,
  SubHeader,
  statusDropdownColorStyles
} from "./BasicDetailsForm";
import { lightRedLogoColor } from "./JobPostInfo";
import './ckEditor.css';

const CandidateRequirements = () => {
  const {
    isEditJobPostDetails,
    candidateRequirement,
    setCandidateRequirement,
    assestsOption,
    languageOption,
    educationOption,
    industryDataOption,
    englishLevelOption,
    departmentDataOption,
    skillOption,
    activeStep,
    setActiveStep,
    setAlertMessage,
    selectedDepartment,
  } = useContext(Context);

  const [educationData, setEducationData] = React.useState([]);
  const [degreeOption, setDegreeOption] = React.useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const [educationError, setEducationError] = React.useState(false);
  const [degreeError, setDegreeError] = React.useState(false);
  const [genderError, setGenderError] = React.useState(false);
  const [experienceError, setExperienceError] = React.useState(false);
  const [englishLevelError, setEnglishLevelError] = React.useState(false);
  const [languagePreferenceError, setLanguagePreferenceError] =
    React.useState(false);
  const [skillsError, setSkillsError] = React.useState(false);
  const [assetsError, setAssetsError] = React.useState(false);
  // const [ageCriteriaError, setAgeCriteriaError] = React.useState(false);
  const [minAgeError, setMinAgeError] = React.useState(false);
  const [maxAgeError, setMaxAgeError] = React.useState(false);
  const [anyAgeCriteriaError, setAnyAgeCriteriaError] = React.useState(false);
  const [whichDepartmentError, setWhichDepartmentError] = React.useState(false);
  // const [minExperienceError, setMinExperienceError] = React.useState(false);
  const [candidateDepartmentError, setCandidateDepartmentError] =
    React.useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);





  const [
    candidateFromWhichDepartmentError,
    setCandidateFromWhichDepartmentError,
  ] = React.useState(false);
  const [scrollId, setScrollId] = useState("");

  let emptyError = false;

  const radioButtonCss = {
    "&, &.Mui-checked": {
      color: logoRedColor,
    },
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    //if array then push else set
    if (Array.isArray(candidateRequirement[name])) {
      if (candidateRequirement[name].includes(value)) {
        setCandidateRequirement({
          ...candidateRequirement,
          [name]: candidateRequirement[name].filter((item) => item !== value),
        });
      } else {
        setCandidateRequirement({
          ...candidateRequirement,
          [name]: [...candidateRequirement[name], value],
        });
      }
    } else {
      setCandidateRequirement({
        ...candidateRequirement,
        [name]: value.trimStart(),
      });
    }
  };

  const handleContinue = () => {
    if (candidateRequirement.education === "") {
      setEducationError(true);
      emptyError = true;
    } else {
      setEducationError(false);
    }

    if (
      educationData?.title !== "12th Pass" &&
      candidateRequirement.degreeFreference.length <= 0
    ) {
      setDegreeError(true);
      emptyError = true;
    } else {
      setDegreeError(false);
    }

    if (candidateRequirement?.gender === "") {
      setGenderError(true);
      emptyError = true;
    } else {
      setGenderError(false);
    }

    if (candidateRequirement?.anyAgeCriteria === "") {
      setAnyAgeCriteriaError(true);
      emptyError = true;
    } else {
      setAnyAgeCriteriaError(false);
    }

    if (candidateRequirement?.anyAgeCriteria === "1") {
      if (candidateRequirement?.ageCriteria?.minAge === "") {
        setMinAgeError(true);
        emptyError = true;
      } else {
        setMinAgeError(false);
      }
      if (candidateRequirement?.ageCriteria?.maxAge === "") {
        setMaxAgeError(true);
        emptyError = true;
      } else {
        setMaxAgeError(false);
      }
    }

    if (candidateRequirement?.experience === "") {
      setExperienceError(true);
      emptyError = true;
    } else {
      setExperienceError(false);
    }

    if (candidateRequirement?.experience === "2") {
      // if (candidateRequirement?.minExperience === "") {
      //   setMinExperienceError(true);
      //   emptyError = true;
      // } else {
      //   setMinExperienceError(false);
      // }

      if (candidateRequirement?.whichDepartment === "") {
        setWhichDepartmentError(true);
        emptyError = true;
      } else {
        setWhichDepartmentError(false);
      }
    }

    if (candidateRequirement?.whichDepartment === "3") {
      if (candidateRequirement?.candidateFromWhichDepartment?.length <= 0) {
        setCandidateFromWhichDepartmentError(true);
        emptyError = true;
      } else {
        setCandidateFromWhichDepartmentError(false);
      }
    }

    if (candidateRequirement?.whichDepartment === "2") {
      if (candidateRequirement?.candidateDepartment?.length <= 0) {
        setCandidateDepartmentError(true);
        emptyError = true;
      } else {
        setCandidateDepartmentError(false);
      }
    }

    if (candidateRequirement?.englishLevel === "") {
      setEnglishLevelError(true);
      emptyError = true;
    } else {
      setEnglishLevelError(false);
    }

    if (candidateRequirement?.languagePreference?.length <= 0) {
      setLanguagePreferenceError(true);
      emptyError = true;
    } else {
      setLanguagePreferenceError(false);
    }

    if (candidateRequirement?.skills?.length <= 0) {
      setSkillsError(true);
      emptyError = true;
    } else {
      setSkillsError(false);
    }

    if (candidateRequirement?.assets?.length <= 0) {
      setAssetsError(true);
      emptyError = true;
    } else {
      setAssetsError(false);
    }

    if (emptyError) {
      setAlertMessage({
        message: "Please fill all the required fields",
        isOpen: true,
        type: "danger",
      });
    } else {
      console.log("done");
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSelectChange = (value, name) => {
    setCandidateRequirement({ ...candidateRequirement, [name]: value });
  };

  // useEffect(() => {
  //     employerSetupData2().then((res) => {
  //         setAssestsOption(res?.data?.data?.asset)
  //         setLanguageOption(res?.data?.data?.language_data)
  //         setEducationOption(res?.data?.data?.education_data)
  //         setEnglishLevelOption(res?.data?.data?.english_speaking_level_data)
  //         setSkillOption(res?.data?.data?.skill_data)
  //         setToken(res?.data?.data?.token)
  //     }).catch((err) => {
  //         console.log(err)
  //     })
  // }, [])

  useEffect(() => {
    if (candidateRequirement.education?.value) {
      setEducationData(
        educationOption.find(
          (item) => item?.education_id === candidateRequirement.education?.value
        )
      );
      setDegreeOption(
        educationOption.find(
          (item) => item?.education_id === candidateRequirement.education?.value
        )?.degrees
      );
    }
  }, [candidateRequirement.education]);


  const scrollToTop = () => {
    try {
      let element = document.getElementById(scrollId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setScrollId("scrollToTop");
    scrollToTop();
  }, [activeStep, scrollId]);

  return (
    <Grid container spacing={4} style={{ color: "black", height: "100%" }}>
      <Grid item xs={12}>
        <h4>Candidate Requirements</h4>
        <div style={{ fontSize: "14px", color: "gray" }}>
          We'll use these requirement details to make your job visible to the
          right candidates.
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SubHeader title={"Education"} isRequired={true} />
            <CustomReactSelect
              id="outlined-select"
              select
              styles={statusDropdownColorStyles}
              fullWidth
              name="education"
              options={educationOption.map((item) => {
                return { value: item?.education_id, label: item?.title };
              })}
              value={candidateRequirement.education?.value}
              onChange={(value) => {
                handleSelectChange(value, "education");
                setEducationError(false);
                setEducationData(
                  educationOption.find(
                    (item) => item?.education_id === value?.value
                  )
                );
                setDegreeOption(
                  educationOption.find(
                    (item) => item?.education_id === value?.value
                  )?.degrees
                );
              }}
            />
            {educationError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select minimum education
              </div>
            )}
          </Grid>
          {educationData?.title !== "12th Pass" &&
            candidateRequirement.education !== "" ? (
            <Grid item xs={12}>
              <SubHeader title={"Degree Preference"} isRequired={true} />
              {candidateRequirement.degreeFreference.map((item) => (
                <Chip
                  sx={{ margin: "5px", borderRadius: "10px" }}
                  label={item?.label}
                  onDelete={() => {
                    setCandidateRequirement({
                      ...candidateRequirement,
                      degreeFreference:
                        candidateRequirement.degreeFreference.filter(
                          (degree) => degree !== item
                        ),
                    });
                  }}
                />
              ))}
              <CustomReactSelect
                id="outlined-select"
                fullWidth
                styles={statusDropdownColorStyles}
                // label="Degree Preference"
                name="degreeFreference"
                options={
                  //remove selected degree
                  degreeOption
                    .filter(
                      (data) =>
                        !candidateRequirement.degreeFreference
                          .map((item) => item.value)
                          .includes(data?.degree_id)
                    )
                    .map((item) => {
                      return { value: item?.degree_id, label: item?.title };
                    })
                }
                value={candidateRequirement?.degreeFreference?.value}
                onChange={(e) => {
                  // push to array
                  setDegreeError(false);
                  setCandidateRequirement({
                    ...candidateRequirement,
                    degreeFreference: [
                      ...candidateRequirement?.degreeFreference,
                      e,
                    ],
                  });
                }}
                placeholder={
                  candidateRequirement?.degreeFreference?.length === 0
                    ? "Select Degree Preference"
                    : `${candidateRequirement.degreeFreference.length} selected`
                }
                helperText="Select up tp 10 degree preference"
              />
              {degreeError && (
                <div style={{ color: "red", fontSize: "12px" }}>Please select minimum degree</div>
              )}
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <SubHeader title={"Gender"} isRequired={true} />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="gender"
              value={candidateRequirement.gender}
              onChange={(e) => {
                handleChange(e);
                setGenderError(false);
              }}>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value={1}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Female"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Male"
                />
                <FormControlLabel
                  value={3}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Both"
                />
              </div>
            </RadioGroup>
            {genderError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please select gender</div>
            )}
          </Grid>

          <Grid item xs={12}>
            <SubHeader title={"Any age criteria ?"} isRequired={true} />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="anyAgeCriteria"
              value={candidateRequirement.anyAgeCriteria}
              onChange={(e) => {
                handleChange(e);
                setAnyAgeCriteriaError(false);
              }}>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value={1}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="No"
                />
              </div>
            </RadioGroup>
            {anyAgeCriteriaError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please select any age criteria</div>
            )}
          </Grid>

          {candidateRequirement.anyAgeCriteria === "1" ? (
            <Grid item xs={12} className="form-group">
              <SubHeader title={"Age Criteria"} isRequired={true} />
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <input
                    className="form-control"
                    name="ageCriteria.minAge"
                    value={candidateRequirement.ageCriteria.minAge}
                    onChange={(e) => {
                      setMinAgeError(false);
                      setCandidateRequirement({
                        ...candidateRequirement,
                        ageCriteria: {
                          ...candidateRequirement.ageCriteria,
                          minAge: e.target.value,
                        },
                      });
                    }}
                    placeholder="Min Age"
                    style={{ marginRight: "10px" }}
                  />
                  {minAgeError && (
                    <div style={{ color: "red", fontSize: "12px" }}>Please select min age</div>
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <input
                    className="form-control"
                    name="ageCriteria.maxAge"
                    value={candidateRequirement.ageCriteria.maxAge}
                    onChange={(e) => {
                      setMaxAgeError(false);
                      setCandidateRequirement({
                        ...candidateRequirement,
                        ageCriteria: {
                          ...candidateRequirement.ageCriteria,
                          maxAge: e.target.value,
                        },
                      });
                    }}
                    placeholder="Max Age"
                  />
                  {maxAgeError && (
                    <div style={{ color: "red", fontSize: "12px" }}>Please select max age</div>
                  )}
                </div>
              </div>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <SubHeader title={"Experience required"} isRequired={true} />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="experience"
              value={candidateRequirement.experience}
              onChange={(e) => {
                handleChange(e);
                setExperienceError(false);
              }}>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value={1}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Freshers Only"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Experienced Only"
                />
                <FormControlLabel
                  value={3}
                  control={<Radio sx={{ ...radioButtonCss }} />}
                  label="Any"
                />
              </div>
            </RadioGroup>
            {experienceError && (
              <div style={{ color: "red", fontSize: "12px" }}>Please select experience</div>
            )}
            {/* {candidateRequirement.experience === "2" ||
            candidateRequirement.experience === "3" ? (
              //make radio button with only title
              <>
                <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                  {experienceOption?.map((item) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label for={`${item.value}`}>
                        <input
                          id={`${item.value}`}
                          type="radio"
                          name="minExperience"
                          value={item.value}
                          onChange={() => {
                            setMinExperienceError(false);
                            setCandidateRequirement({
                              ...candidateRequirement,
                              minExperience: item.value,
                            });
                          }}
                          // checked={candidateRequirement.minExperience === item.value}
                          hidden
                        />
                        <div
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "30px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            width: "150px",
                            alignItems: "center",
                            textAlign: "center",
                            fontWeight:
                              candidateRequirement.minExperience === item.value
                                ? "bold"
                                : "normal",
                            color:
                              candidateRequirement.minExperience === item.value
                                ? logoRedColor
                                : "black",
                            backgroundColor:
                              candidateRequirement.minExperience === item.value
                                ? lightRedLogoColor
                                : "white",
                            border:
                              candidateRequirement.minExperience === item.value
                                ? `1px solid ${logoRedColor}`
                                : "1px solid black",
                          }}>
                          {item.label}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                {minExperienceError && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Please select minimum experience
                  </div>
                )}
              </>
            ) : null} */}
          </Grid>

          {candidateRequirement.experience === "2" ||
            candidateRequirement.experience === "3" ? (
            <Grid item xs={12}>
              <Header style={{ marginTop: "10px" }}>
                Candidate from which department can apply?
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}>
                  We will filter based on candidate's latest experience
                </div>
              </Header>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="whichDepartment"
                value={candidateRequirement.whichDepartment}
                onChange={(e) => {
                  handleChange(e);
                  setWhichDepartmentError(false);
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}>
                  <FormControlLabel
                    value={1}
                    control={<Radio sx={{ ...radioButtonCss }} />}
                    label={`${selectedDepartment?.department_name}`}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio sx={{ ...radioButtonCss }} />}
                    label="From multiple departments"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio sx={{ ...radioButtonCss }} />}
                    label="Any Department"
                  />
                </div>
              </RadioGroup>
              {whichDepartmentError && (
                <div style={{ color: "red", fontSize: "12px" }}>Please select department</div>
              )}
            </Grid>
          ) : null}
          {candidateRequirement.whichDepartment === "2" && (
            <Grid item xs={12}>
              <SubHeader title={"Add more departments"} isRequired={true} />
              {candidateRequirement?.candidateDepartment?.map((item) => (
                <Chip
                  sx={{ margin: "5px", borderRadius: "10px" }}
                  label={item?.label}
                  onDelete={() => {
                    setCandidateRequirement({
                      ...candidateRequirement,
                      candidateDepartment:
                        candidateRequirement.candidateDepartment.filter(
                          (degree) => degree !== item
                        ),
                    });
                  }}
                />
              ))}
              <CustomReactSelect
                isClearable
                classNamePrefix="reactselect-select"
                options={departmentDataOption
                  .filter(
                    (data) =>
                      !candidateRequirement?.candidateDepartment
                        ?.map((item) => item?.value)
                        .includes(data?.department_id)
                  )
                  .map((item) => {
                    return {
                      label: item?.department_name,
                      value: item?.department_id,
                    };
                  })}
                name="candidateDepartment"
                styles={statusDropdownColorStyles}
                value={candidateRequirement?.candidateDepartment?.value}
                onChange={(e) => {
                  setCandidateDepartmentError(false);
                  setCandidateRequirement({
                    ...candidateRequirement,
                    candidateDepartment: [
                      ...candidateRequirement.candidateDepartment,
                      e,
                    ],
                  });
                }}
                label="Department / Function"
              />
              {candidateDepartmentError && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please select department
                </div>
              )}
            </Grid>
          )}
          {candidateRequirement.whichDepartment === "3" ? (
            <Grid item xs={12}>
              <Header style={{ marginTop: "10px" }}>
                Choose candidate's industry experience
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}>
                  Add industries in which candidates should have prior
                  experience for this job
                </div>
              </Header>
              <Grid item xs={12}>
                {/* <Header>Degree Preference</Header> */}
                {candidateRequirement?.candidateFromWhichDepartment?.map(
                  (item) => (
                    <Chip
                      sx={{ margin: "5px", borderRadius: "10px" }}
                      label={item?.label}
                      onDelete={() => {
                        setCandidateRequirement({
                          ...candidateRequirement,
                          candidateFromWhichDepartment:
                            candidateRequirement.candidateFromWhichDepartment.filter(
                              (degree) => degree !== item
                            ),
                        });
                      }}
                    />
                  )
                )}
                <CustomReactSelect
                  fullWidth
                  styles={statusDropdownColorStyles}
                  // label="Degree Preference"
                  name="candidateFromWhichDepartment"
                  options={industryDataOption
                    .filter(
                      (data) =>
                        !candidateRequirement.candidateFromWhichDepartment
                          .map((item) => item.value)
                          .includes(data?.industries_id)
                    )
                    .map((item) => {
                      return { value: item?.industries_id, label: item?.title };
                    })}
                  value={
                    candidateRequirement?.candidateFromWhichDepartment?.value
                  }
                  onChange={(e) => {
                    setCandidateFromWhichDepartmentError(false);
                    setCandidateRequirement({
                      ...candidateRequirement,
                      candidateFromWhichDepartment: [
                        ...candidateRequirement.candidateFromWhichDepartment,
                        e,
                      ],
                    });
                  }}
                  placeholder={
                    candidateRequirement?.candidateFromWhichDepartment
                      ?.length === 0
                      ? "Select industry experience"
                      : `${candidateRequirement?.candidateFromWhichDepartment.length} selected`
                  }
                  helperText="Select up tp 10 degree preference"
                />
              </Grid>
              {candidateFromWhichDepartmentError && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please select industry experience
                </div>
              )}
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SubHeader title={"English level preference"} isRequired={true} />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="englishLevel"
              value={candidateRequirement.englishLevel}
              onChange={(e) => {
                handleChange(e);
                setEnglishLevelError(false);
              }}>
              <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
                {englishLevelOption.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item?.english_speaking_level_id}
                    control={<Radio sx={{ ...radioButtonCss }} />}
                    label={item?.title}
                  />
                ))}
              </div>
            </RadioGroup>
            {englishLevelError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select english level
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <SubHeader
              title={"Regional language preference"}
              isRequired={true}
            />
            <Grid container spacing={2}>
              {languageOption.map((item, index) => {
                return (
                  <Grid item>
                    <div key={index}>
                      <input
                        id={`employee-${item.language_id}`}
                        type="checkbox"
                        hidden
                        name="languagePreference"
                        value={item.language_id}
                        style={{ width: "20%" }}
                      // onChange={handleChange}
                      />
                      <Chip
                        label={item?.title}
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          backgroundColor:
                            candidateRequirement.languagePreference.includes(
                              item.language_id
                            )
                              ? lightRedLogoColor
                              : "white",
                          color:
                            candidateRequirement.languagePreference.includes(
                              item.language_id
                            )
                              ? logoRedColor
                              : "black",
                          fontWeight:
                            candidateRequirement.languagePreference.includes(
                              item.language_id
                            )
                              ? "bold"
                              : "normal",
                          border:
                            candidateRequirement.languagePreference.includes(
                              item.language_id
                            )
                              ? `1px solid ${logoRedColor}`
                              : "1px solid black",
                        }}
                        onClick={() => {
                          if (
                            candidateRequirement.languagePreference.includes(
                              item.language_id
                            )
                          ) {
                            setLanguagePreferenceError(false);
                            setCandidateRequirement({
                              ...candidateRequirement,
                              languagePreference:
                                candidateRequirement.languagePreference.filter(
                                  (language) => language !== item.language_id
                                ),
                            });
                          } else {
                            setLanguagePreferenceError(false);
                            setCandidateRequirement({
                              ...candidateRequirement,
                              languagePreference: [
                                ...candidateRequirement.languagePreference,
                                item.language_id,
                              ],
                            });
                          }
                        }}
                        onDelete={() => {
                          setCandidateRequirement({
                            ...candidateRequirement,
                            languagePreference:
                              candidateRequirement.languagePreference.filter(
                                (language) => language !== item.language_id
                              ),
                          });
                        }}
                        deleteIcon={
                          candidateRequirement.languagePreference.includes(
                            item.language_id
                          ) ? (
                            <CloseIcon style={{ color: logoRedColor }} />
                          ) : (
                            <AddIcon style={{ color: "black" }} />
                          )
                        }
                      />

                      {/* <label for={`employee-${item.language_id}`} style={{
                                                    textAliogn: "center",
                                                    padding: "2% 4% 2% 4%"
                                                }}>
                                                    {item.language_id}
                                                </label>
                                                <span style={{ marginBottom: "auto", fontSize: "1.5rem", fontWeight: "bold" }}> + </span> */}
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            {languagePreferenceError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select language preference
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <SubHeader
              title={"Skill(s) required for this job"}
              isRequired={true}
            />
            <CustomReactSelect
              id="outlined-select"
              select
              styles={statusDropdownColorStyles}
              allowSort={false}
              fullWidth
              name="education"
              options={
                [
                  { value: "all", label: "Select All" },
                  ...skillOption.map((item) => {
                    return { value: item?.skill_id, label: item?.title };
                  }),
                ]

              }
              value={selectedSkills}
              onChange={
                (value) => {
                  setSelectedSkills(value);
                  setSkillsError(false);
                  if (value[value.length - 1]?.value === "all") {
                    setCandidateRequirement({
                      ...candidateRequirement,
                      skills: skillOption.map((item) => item?.skill_id),
                    });
                  } else {
                    setCandidateRequirement({
                      ...candidateRequirement,
                      skills: value.map((item) => item?.value),
                    });
                  }
                }
              }
              isMulti
            />
            {skillsError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select skills
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <SubHeader
              title={"Assest(s) required for this job"}
              isRequired={true}
            />

            <Grid container spacing={2}>
              {assestsOption.map((item, index) => {
                return (
                  <Grid item>
                    <div key={index}>
                      <input
                        id={`employee-${item.asset_id}`}
                        type="checkbox"
                        hidden
                        name="assets"
                        asset_id={item.asset_id}
                        onChange={(e) => {
                          setAssetsError(false);
                          handleChange(e);
                        }}
                        style={{ width: "20%" }}
                      />
                      <Chip
                        label={item?.title}
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          backgroundColor: candidateRequirement.assets.includes(
                            item.asset_id
                          )
                            ? lightRedLogoColor
                            : "white",
                          color: candidateRequirement.assets.includes(
                            item.asset_id
                          )
                            ? logoRedColor
                            : "black",
                          fontWeight: candidateRequirement.assets.includes(
                            item.asset_id
                          )
                            ? "bold"
                            : "normal",
                          border: candidateRequirement.assets.includes(
                            item.asset_id
                          )
                            ? `1px solid ${logoRedColor}`
                            : "1px solid black",
                        }}
                        onClick={() => {
                          if (
                            candidateRequirement.assets.includes(item.asset_id)
                          ) {
                            setAssetsError(false);
                            setCandidateRequirement({
                              ...candidateRequirement,
                              assets: candidateRequirement.assets.filter(
                                (assest) => assest !== item.asset_id
                              ),
                            });
                          } else {
                            setAssetsError(false);
                            setCandidateRequirement({
                              ...candidateRequirement,
                              assets: [
                                ...candidateRequirement.assets,
                                item.asset_id,
                              ],
                            });
                          }
                        }}
                        onDelete={() => {
                          setCandidateRequirement({
                            ...candidateRequirement,
                            assets: candidateRequirement.assets.filter(
                              (assest) => assest !== item.asset_id
                            ),
                          });
                        }}
                        deleteIcon={
                          candidateRequirement.assets.includes(
                            item.asset_id
                          ) ? (
                            <CloseIcon style={{ color: logoRedColor }} />
                          ) : (
                            <AddIcon style={{ color: "black" }} />
                          )
                        }
                      />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            {assetsError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                Please select assests
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ backgroundColor: "black" }} />
      </Grid>
      <Grid item xs={12}>
        <h4>
          Job Description
          <span style={{ color: "red", fontSize: "16px" }}>*</span>
        </h4>
        <div style={{ fontSize: "14px", color: "gray" }}>
          Describe the responsibilities of this job and others specific
          requirements here
        </div>
        <Grid container style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <div >
              <CKeditor
                value={candidateRequirement?.jobDescription}
                name="jobDescription"
                onReady={(editor) => {
                  editor.ui.view.editable.element.style.minHeight = "500px";
                }}
                onChange={(data) => {
                  setCandidateRequirement({
                    ...candidateRequirement,
                    jobDescription: data,
                  });
                }}
                editorLoaded={editorLoaded}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}>
        <Button
          variant="contained"
          onClick={handleBack}
          style={{ width: "30%", backgroundColor: "gray" }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleContinue}
          style={{ width: "30%", backgroundColor: "green" }}>
          Continue
        </Button>
      </Grid>
      {isEditJobPostDetails && (
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}>
          <Button
            variant="outlined"
            style={{
              width: "30%",
              borderColor: logoRedColor,
              color: logoRedColor,
            }}>
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CandidateRequirements;