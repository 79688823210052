import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { Context } from "../../Context/context";
import { userLogin, verifyOtp } from "../../api/auth";
import otpSvg from "../../assets/imgs/OtpSvg.svg";
import loginSvg from "../../assets/imgs/loginSvg.svg";
import CustomAlert from "../CustomAlert";
import { logoRedColor } from "../Header";
import Layout from "../Layout";
import SEOData from "../SEOData";
import EmployerDetails from "./employer-details";


const StyleButton = styled(Button)({
  backgroundColor: logoRedColor,
  padding: "8px 8px",
  color: "white",
  fontSize: "1rem",
  borderRadius: "5px",
  width: "100%",
  '&:hover': {
    color: "white"
  }

})

const EmployerLogin = () => {
  const {
    login,
    setLogin,
    setUser,
    setToken,
    token,
    setIsOtpVerified,
    isOtpVerified,
    setAlertMessage,
    alertMessage,
    setWelcomeMessage,
  } = useContext(Context);

  const navigate = useNavigate();
  const [error, setError] = React.useState({
    //error for phone no and otp
    phoneNo: false,
    otp: false,
  });
  const [save, setSave] = React.useState(false);
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [counter, setCounter] = React.useState(60);
  const [isResend, setIsResend] = React.useState(false);

  let authToken;
  // useEffect(() => {
  //   // setIsOtpVerifyUser(
  //   //   localStorage.getItem("isOtpVerifiedUser") ?
  //   //     localStorage.getItem("isOtpVerifiedUser") :
  //   //     isOtpVerified)

  // }, []);

  const handleOTP = (e) => {
    if (login.phoneNo === "" || login.phoneNo.length < 10) {
      setError({ ...error, phoneNo: true });
      return;
    }
    const body = {
      phone_number: 91 + login.phoneNo,
      device_info: null,
    };
    userLogin(body)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setAlertMessage({
            message: "OTP sent successfully",
            isOpen: true,
            type: "success",
          });
          localStorage.setItem(
            "is_profile_created",
            res?.data?.data?.is_profile_created
          );
          localStorage.setItem(
            "userInfo",
            JSON.stringify(res?.data?.data?.user)
          );
          localStorage.setItem("employerToken", res?.data?.data?.token);
          setLogin({
            ...login,
            setOtp: true,
            setPhone: false,
            otp: res?.data?.data?.otp,
          });
          setError({ ...error, phoneNo: false });
          setIsOtpSent(true);
          setToken(res?.data?.data?.token);
          setUser(res?.data?.data?.user);
          setError({ ...error, phoneNo: false });
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertMessage({
          message: err?.response?.data?.message,
          isOpen: true,
          type: "danger",
        });
      });
  };

  const handleLogin = () => {
    if (login.otp === "" || login.otp.length < 6) {
      setError({ ...error, otp: true });
      return;
    }
    const body = {
      otp: otp,
    };
    verifyOtp(body, token)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setAlertMessage({
            message: "Login successfully",
            isOpen: true,
            type: "success",
          });
          if (JSON.parse(localStorage.getItem("is_profile_created")) === 1) {
            navigate("/postjob");
            localStorage.setItem("welcomeMessage", true);
          } else {
            setLogin({
              ...login,
              detailsPage: true,
              setOtp: false,
              setPhone: false,
              phoneNo: "",
              otp: "",
            });
          }
          setSave(true);
          setUser(res?.data?.user);
          setError({ ...error, otp: false });
          setIsOtpVerified(res?.data?.data?.is_otp_verified);
          localStorage.setItem("employerToken", res?.data?.data?.token);
          // localStorage.setItem(
          //   "userInfo",
          //   JSON.stringify(res?.data?.data?.user)
          // );
          localStorage.setItem(
            "isOtpVerifiedUser",
            res?.data?.data?.is_otp_verified
          );
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertMessage({
          message: err?.response?.data?.message,
          isOpen: true,
          type: "danger",
        });
      });
  };

  const handleOtpChange = (e) => {
    setOtp(e);
    setError({ ...error, otp: false });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (/^[0-9]{0,10}$/.test(value)) {
      setError({ ...error, phoneNo: false });
      setLogin({ ...login, phoneNo: e.target.value });
    }
  };

  const handleEditPhoneNo = () => {
    setCounter(60)
    setLogin({ ...login, setOtp: false, setPhone: true });
  };

  const handleResandOTP = () => {
    if (login.phoneNo === "" || login.phoneNo.length < 10) {
      setError({ ...error, phoneNo: true });
      return;
    }

    const body = {
      phone_number: 91 + login.phoneNo,
      device_info: null,
    };
    userLogin(body)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setAlertMessage({
            message: "OTP sent successfully",
            isOpen: true,
            type: "success",
          });

          setLogin({
            ...login,
            setOtp: true,
            setPhone: false,
            otp: res?.data?.data?.otp,
          });
          setError({ ...error, phoneNo: false });
          localStorage.setItem(
            "is_profile_created",
            res?.data?.data?.is_profile_created
          );

          localStorage.setItem(
            "userInfo",
            JSON.stringify(res?.data?.data?.user)
          );
          localStorage.setItem("employerToken", res?.data?.data?.token);
          setToken(res?.data?.data?.token);
          setUser(res?.data?.data?.user);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertMessage({
          message: err?.response?.data?.message,
          isOpen: true,
          type: "danger",
        });
      });
  };

  useEffect(() => {
    setCounter(60)

    return () => {
      setLogin({
        phoneNo: "",
        otp: "",
        setOtp: false,
        setPhone: false,
        detailsPage: false,
      })
      setCounter("")
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  useEffect(() => {
    authToken = localStorage.getItem("employerToken");

    if (
      JSON.parse(localStorage.getItem("is_profile_created")) === 1 &&
      JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true &&
      localStorage.getItem("employerToken")
    ) {
      console.log("here");
      navigate("/postjob");
    } else {
      if (
        JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true &&
        JSON.parse(localStorage.getItem("is_profile_created")) === 0
      ) {
        setLogin({
          ...login,
          detailsPage: true,
          setPhone: false,
        });
      } else {
        setLogin({
          ...login,
          setOtp: false,
          detailsPage: false,
          setPhone: true,
        });
      }
    }
    //9904157241
    // if (
    //   !localStorage.getItem("isOtpVerifiedUser") &&
    //   !localStorage.getItem("is_profile_created")
    // ) {
    //   setLogin({
    //     ...login,
    //     detailsPage: false,
    //     setPhone: true,
    //   });
    // } else {
    //   if (
    //     !localStorage.getItem("userData") &&
    //     JSON.parse(localStorage.getItem("isOtpVerifiedUser")) === true
    //   ) {
    //     setLogin({
    //       ...login,
    //       detailsPage: true,
    //       setPhone: false,
    //     });
    //   } else {
    //     setLogin({
    //       ...login,
    //       detailsPage: false,
    //       setPhone: true,
    //     });
    //   }
    // }
  }, []);

  useEffect(() => {
    let interval;
    setIsResend(false);
    if (login?.setOtp === true) {
      let timer = 60;
      interval = setInterval(() => {
        timer--;
        setCounter(timer);
        if (timer === 0) {
          setIsResend(true);
          clearInterval(interval);
        }
      }, 1000);
    } else {
      setCounter(60);
    }

    return () => {
      clearInterval(interval);
    }
  }, [login]);

  const reverseTimer = () => {
    if (login?.setOtp) {
      let timer = 60;
      let interval = setInterval(() => {
        timer--;
        setCounter(timer);
        if (timer === 0) {
          setIsResend(true);
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  // useEffect(() => {
  //   const keyDownHandler = event => {
  //     console.log('User pressed: ', event.key);

  //     if (event.key === 'Enter') {
  //       event.preventDefault();
  //       if(localStorage.getItem)

  //       // 👇️ your logic here
  //       // if (login.phoneNo === "") {
  //       handleOTP()
  //       // };
  //     }
  //   };

  //   document.addEventListener('keydown', keyDownHandler);

  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, [document]);

  return (
    <>
      <SEOData keyword="Employer Login" />
      <div
        style={{
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}>
        {alertMessage && alertMessage?.isOpen && (
          <CustomAlert
            show={alertMessage?.isOpen}
            message={alertMessage?.message}
            type={alertMessage?.type}
            setShow={setAlertMessage}
          />
        )}
        <>
          <Layout>
            <section className="pt-30 login-register">
              <div
                className="container"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}>
                <div
                  className="row login-register-cover pb-100"
                  style={{ height: "100%" }}>
                  {(authToken && isOtpVerified) || login?.detailsPage ? (
                    <EmployerDetails />
                  ) : (
                    <>
                      {login?.setOtp && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center">
                          <Grid item xs={12} sm={6}>
                            {/* <h1>Hire from a pool</h1> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              <img
                                style={{ width: "80%", height: "80%" }}
                                src={otpSvg}
                                alt="kindjob"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div
                              className=" mx-auto "
                              style={{ width: "90%" }}>
                              <div className="text-center">
                                <p className="font-sm text-brand-2"></p>
                                <h2 className="mt-10 text-brand-1">
                                  Enter OTP
                                </h2>
                                <p className="font-sm text-muted mb-30">
                                  OTP sent to{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {login.phoneNo || ""}
                                  </span>{" "}
                                  <span
                                    onClick={handleEditPhoneNo}
                                    style={{
                                      color: "blue",
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}>
                                    Edit
                                  </span>
                                </p>
                              </div>
                              <div className="form-group">
                                <OtpInput
                                  style={{}}
                                  inputStyle={{
                                    width: "100%",
                                    display: "flex",
                                    marginLeft: "15px",
                                  }}
                                  value={otp}
                                  onChange={handleOtpChange}
                                  numInputs={6}
                                  separator={<span> </span>}
                                />
                              </div>
                              {error.otp && (
                                <p className="text-danger">Please enter otp</p>
                              )}
                              <div
                                // className="row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}>
                                <div>
                                  <p className="font-sm color-text-paragraph-2">
                                    Remaining time: {counter} sec
                                    {/* show reverse timer for 1 min */}
                                  </p>
                                </div>
                                <div className="font-sm color-text-paragraph-2">
                                  {isResend ? (
                                    <p
                                      className="font-sm color-text-paragraph-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={handleResandOTP}>
                                      Resend OTP
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group">
                                <StyleButton
                                  // className="btn btn-brand-1 w-100 mt-20"
                                  type="submit"
                                  name="login"
                                  onClick={handleLogin}
                                  style={{ backgroundColor: logoRedColor }}>
                                  Login
                                </StyleButton>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                      {login.setPhone && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center">
                          <Grid item xs={12} sm={6}>
                            {/* <h1>Hire from a pool</h1> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              <img
                                style={{ width: "80%", height: "80%" }}
                                src={loginSvg}
                                alt="kindjob"
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <div
                              className=" mx-auto"
                              style={{ width: "90%" }}>
                              <div className="text-center">
                                <h2 className="mt-10 mb-40 text-brand-1">
                                  Employer Login
                                </h2>
                              </div>
                              <div className="form-group">
                                <label className="form-label" htmlFor="input-1">
                                  Employer Login/Sign Up
                                </label>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    height: "50px",
                                  }}>
                                  <div
                                    style={{
                                      width: "100px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "#ededed",
                                      borderRadius: "5px",
                                      fontSize: "1.2rem",
                                      fontWeight: "bold",
                                      padding: "3.5%",
                                    }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#ededed",
                                        fontSize: "1.2rem",
                                        fontWeight: "bold",
                                      }}>
                                      +
                                    </div>
                                    91
                                  </div>
                                  <input
                                    // style={{ height: "100%" }}
                                    className="form-control"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    id="input-1"
                                    // type="number"
                                    required
                                    value={login.phoneNo}
                                    name="phoneNo"
                                    placeholder="Phone No."
                                  />
                                </div>
                                {error.phoneNo && (
                                  <div className="text-danger">
                                    Please enter valid phone number
                                  </div>
                                )}
                              </div>
                              <div
                                className="form-group"
                                style={{ display: "flex" }}>
                                <StyleButton
                                  // className="btn btn-brand-1 w-100 mt-20"
                                  name="login"
                                  onClick={handleOTP}
                                  type="submit"
                                  style={{ backgroundColor: logoRedColor }}>
                                  GET OTP
                                </StyleButton>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* <div className="img-1 d-none d-lg-block">
                  <img
                    className="shape-1"
                    src="../assets/imgs/page/login-register/img-4.svg"
                    alt="kindjob"
                  />
                </div> */}
                </div>
              </div>
            </section>
          </Layout>
        </>
      </div>
    </>
  );
};

export default EmployerLogin;
