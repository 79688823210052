/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from "react";

export const Context = createContext();

function AppProvider({ children }) {
    const [login, setLogin] = useState({
        phoneNo: "",
        otp: "",
        setOtp: false,
        setPhone: false,
        detailsPage: false,
    });
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [user, setUser] = useState({});
    const [token, setToken] = useState("");
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isLoginPageOpen, setIsLoginPageOpen] = useState(false);
    const [profileActions, setProfileActions] = useState("");
    const [isEditJobPostDetails, setIsEditJobPostDetails] = useState(false);
    const [planDetails, setPlanDetails] = useState()
    const [interviewerInfo, setInterviewerInfo] = useState({
        interviewerDetails: "",
        recruiterName: "",
        HRWhatsappNumber: "",
        contactNo: "",
        typeOfInterview: "",
        interviewerCity: "",
        interviewerArea: "",
        interviewerAddress: "",
        companyAddressSameAsInterviewerAddress: false,
        companyCity: "",
        companyArea: "",
        companyAddress: "",
        manageCandidate: "",
    });

    const [basicDetails, setBasicDetails] = useState({
        isCompany: "",
        companyName: "",
        jobTitle: "",
        department: "",
        category: "",
        jobType: "",
        noOfOpenings: "",
        nightShift: false,
        jobLocation: "",
        jobCity: "",
        jobArea: "",
        address: "",
        receiveApplicationFrom: "",
        receiveCandidateFrom: "",
        applicationFromAnywhere: "",
        applicationFrom: "",
        payType: "",
        minFixedSalary: "",
        maxFixedSalary: "",
        additionalPerks: [],
        deposit: "",
        wfhReceiveApplication: "",
        wfhFieldJob: "",
        averageIncentive: "",
        depositAmount: "",
        depositType: "",
        depositOther: "",
        feeBePaidBy: "",
    });

    const [candidateRequirement, setCandidateRequirement] = useState({
        education: "",
        gender: "",
        experience: "",
        englishLevel: "",
        degreeFreference: [],
        languagePreference: [],
        skills: [],
        assets: [],
        ageCriteria: {
            minAge: "",
            maxAge: "",
        },
        anyAgeCriteria: "",
        minExperience: "",
        whichDepartment: "",
        candidateFromWhichDepartment: [],
        jobDescription: "",
        candidateDepartment: [],
    });

    const [additionalPerksOption, setAdditionalPerksOption] = useState([]);
    const [departmentDataOption, setDepartmentDataOption] = useState([]);
    const [industryDataOption, setIndustryDataOption] = useState([]);
    const [jobRoleDataOption, setJobRoleDataOption] = useState([]);
    const [jobTypeDataOption, setJobTypeDataOption] = useState([]);
    const [joiningFeeTypeOption, setJoiningFeeTypeOption] = useState([]);
    const [noOfEmployeeOption, setNoOfEmployeeOption] = useState([]);
    const [payTypeOption, setPayTypeOption] = useState([]);
    const [roleDataOption, setRoleDataOption] = useState([]);

    const [cityDataOption, setCityDataOption] = useState([]);
    const [areaDataOption, setAreaDataOption] = useState([]);

    const [assestsOption, setAssestsOption] = useState([]);
    const [languageOption, setLanguageOption] = useState([]);
    const [educationOption, setEducationOption] = useState([]);
    const [englishLevelOption, setEnglishLevelOption] = useState([]);
    const [skillOption, setSkillOption] = useState([]);

    //for home
    const [topRecruiter, setTopRecruiter] = useState([]);
    const [categoryJob, setCategoryJob] = useState([]);
    const [browseByCategory, setBrowseByCategory] = useState([]);

    const [getsJob, setGetsJob] = useState([]);
    const [activeStep, setActiveStep] = useState(0);

    const [employerTab, setEmployerTab] = useState("dashboard");
    const [alertMessage, setAlertMessage] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [selectedJob, setSelectedJob] = useState({});
    const [isJobDetailsOpen, setIsJobDetailsOpen] = useState(false);
    const [viewCandidateList, setViewCandidateList] = useState(false);
    const [candidateListData, setCandidateListData] = useState([]);
    const [title, setTitle] = useState("");
    const [welcomeMessage, setWelcomeMessage] = useState(false);
    const [postJobId, setPostJobId] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [totalCoins, setTotalCoins] = useState(0);
    const [jobRoleData, setJobRoleData] = useState([]);
    const [openPlanDialog, setOpenPlanDialog] = useState(false);

    const [seoData, setSeoData] = useState({});

    useEffect(() => {
        setToken(
            localStorage.getItem("employerToken")
                ? localStorage.getItem("employerToken")
                : token
        );
        setIsOtpVerified(
            localStorage.getItem("isOtpVerifiedUser")
                ? localStorage.getItem("isOtpVerifiedUser")
                : isOtpVerified
        );
    }, []);

    return (
        <Context.Provider
            value={{
                login,
                setLogin,
                user,
                setUser,
                token,
                setToken,
                isOtpVerified,
                setIsOtpVerified,
                isLoginPageOpen,
                setIsLoginPageOpen,
                profileActions,
                setProfileActions,
                isEditJobPostDetails,
                setIsEditJobPostDetails,
                interviewerInfo,
                setInterviewerInfo,
                basicDetails,
                setBasicDetails,
                candidateRequirement,
                setCandidateRequirement,
                additionalPerksOption,
                setAdditionalPerksOption,
                departmentDataOption,
                setDepartmentDataOption,
                industryDataOption,
                setIndustryDataOption,
                jobRoleDataOption,
                setJobRoleDataOption,
                jobTypeDataOption,
                setJobTypeDataOption,
                joiningFeeTypeOption,
                setJoiningFeeTypeOption,
                noOfEmployeeOption,
                setNoOfEmployeeOption,
                payTypeOption,
                setPayTypeOption,
                roleDataOption,
                setRoleDataOption,
                cityDataOption,
                setCityDataOption,
                areaDataOption,
                setAreaDataOption,
                assestsOption,
                setAssestsOption,
                languageOption,
                setLanguageOption,
                educationOption,
                setEducationOption,
                englishLevelOption,
                setEnglishLevelOption,
                skillOption,
                setSkillOption,
                isTokenExpired,
                setIsTokenExpired,
                topRecruiter,
                setTopRecruiter,
                categoryJob,
                setCategoryJob,
                browseByCategory,
                setBrowseByCategory,
                getsJob,
                setGetsJob,
                activeStep,
                setActiveStep,
                employerTab,
                setEmployerTab,
                alertMessage,
                setAlertMessage,
                selectedJob,
                setSelectedJob,
                isJobDetailsOpen,
                setIsJobDetailsOpen,
                viewCandidateList,
                setViewCandidateList,
                candidateListData,
                setCandidateListData,
                title,
                setTitle,
                welcomeMessage,
                setWelcomeMessage,
                postJobId,
                setPostJobId,
                selectedDepartment,
                setSelectedDepartment,
                totalCoins,
                setTotalCoins,
                seoData,
                setSeoData,
                jobRoleData,
                setJobRoleData,
                planDetails,
                setPlanDetails,
                openPlanDialog,
                setOpenPlanDialog,
            }}>
            {children}
        </Context.Provider>
    );
}

export default AppProvider;
