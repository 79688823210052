/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { Header, SubHeader } from "./BasicDetailsForm";
import { Context } from "../../Context/context";
import Swal from "sweetalert2";
import { logoRedColor } from "../Header";
import { getListCoinTransaction, getMainJobById, getPlansData, setJobPostWithPlan } from "../../api/home";


const SelectPlan = () => {
  const {
    activeStep,
    setActiveStep,
    setInterviewerInfo,
    setCandidateRequirement,
    setBasicDetails,
    setIsEditJobPostDetails,
    token,
    setAlertMessage,
    postJobId,
    setTotalCoins,
    selectedJob,
    totalCoins,
    setSelectedJob,
    setOpenPlanDialog
  } = useContext(Context);


  const [plan, setPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [scrollId, setScrollId] = useState("");

  const scrollToTop = () => {
    try {
      let element = document.getElementById(scrollId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setScrollId("scrollToTop");
    scrollToTop();
  }, [activeStep, scrollId]);

  useEffect(() => {
    getPlansData(token)
      .then((res) => {
        setPlan(res?.data?.data?.plan_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  useEffect(() => {
    getTotalCoins();
  }, []);

  const emptyState = () => {
    setBasicDetails({
      companyName: "",
      jobTitle: "",
      department: "",
      category: "",
      jobType: "",
      noOfOpenings: "",
      nightShift: false,
      jobLocation: "",
      jobCity: "",
      jobArea: "",
      address: "",
      receiveApplicationFrom: "",
      receiveCandidateFrom: "",
      payType: "",
      minSalary: "",
      maxSalary: "",
      additionalPerks: [],
      deposit: "",
      wfhReceiveApplication: "",
      wfhFieldJob: "",
      averageIncentive: "",
      depositAmount: "",
      depositType: "",
      depositOther: "",
      feeBePaidBy: "",
    });
    setCandidateRequirement({
      education: "",
      gender: "",
      experience: "",
      englishLevel: "",
      degreeFreference: [],
      languagePreference: [],
      skills: [],
      assets: [],
      ageCriteria: {
        minAge: "",
        maxAge: "",
      },
      anyAgeCriteria: "",
      minExperience: "",
      whichDepartment: "",
      candidateFromWhichDepartment: [],
      jobDescription: "",
      candidateDepartment: [],
    });
    setInterviewerInfo({
      interviewerDetails: "",
      recruiterName: "",
      HRWhatsappNumber: "",
      contactNo: "",
      typeOfInterview: "",
      interviewerCity: "",
      interviewerArea: "",
      interviewerAddress: "",
      companyAddressSameAsInterviewerAddress: false,
      companyCity: "",
      companyArea: "",
      companyAddress: "",
      manageCandidate: "",
    });
  };

  const getTotalCoins = () => {
    const body = {
      search: "",
      page_no: 1,
      page_record: 10,
    };
    getListCoinTransaction(body)
      .then((res) => {
        setTotalCoins(res?.data?.data?.total_coin);
        localStorage.setItem("employerToken", res?.data?.data?.token);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleContinue = () => {
    const body = {
      plan_id: selectedPlan,
      main_job_id: Number(postJobId),
    };

    if (selectedPlan === "") {
      setAlertMessage({
        message: "Please select plan",
        isOpen: true,
        type: "danger",
      });
      return;
    } else {

      // get selected paln coins
      const selectedPlanCoins = plan.find((item) => item?.plan_id === selectedPlan)
        ?.coins;

      if (selectedPlanCoins > totalCoins) {
        // setAlertMessage({
        //   message: "You don't have enough coins to post this job",
        //   isOpen: true,
        //   type: "danger",
        // });        
        Swal.fire({
          html: "<span style='font-size:30px;margin-bottom:5px;'>🙁</span> <br />Sorry, You don't have enough coins to post this job<br />",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: logoRedColor,
          confirmButtonText: "Buy Coins",
          showLoaderOnConfirm: true,
        }).then(function(result) {
          if (result.isConfirmed) {
            setOpenPlanDialog(true);
          }
        })
      } else {
        setJobPostWithPlan(body)
          .then((res) => {
            if (res?.data?.statusCode === 200) {
              setAlertMessage({
                message: "Job posted successfully",
                isOpen: true,
                type: "success",
              });
              getTotalCoins();
              emptyState();
              setActiveStep(0);
            }
          })
          .catch((err) => {
            setAlertMessage({
              message: err?.response?.data?.message,
              isOpen: true,
              type: "danger",
            });
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    getMainJobById(Number(postJobId))
      .then((res) => {
        setSelectedJob(res?.data?.data?.job_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Header>Job role review</Header>

        <Grid
          container
          justifyContent={"space-between"}
          alignItems="center"
          style={{
            color: "black",
            border: "1px solid #e0e6f7",
            borderRadius: "10px",
            padding: "10px",
          }}>
          <Grid item>
            <Grid container direction="column">
              <Grid item style={{ fontWeight: "bold", color: "#4f5e64" }}>{selectedJob?.user_company?.company_name}</Grid>
              <Grid item style={{ color: "#4f5e64" }}>{selectedJob?.job_title}</Grid>
              <Grid item style={{ fontSize: "0.6", color: "#4f5e64" }}>
                {selectedJob?.job_type} |{" "}
                {`${selectedJob?.job_location?.city_name
                  ? selectedJob?.job_location?.city_name
                  : "Anywhere in India"
                  }`}{" "}
                |{" "}
                {`${selectedJob?.job_pay?.minimum_salary
                  ? Number(selectedJob?.job_pay?.minimum_salary)
                  : 0
                  } -
                  ${selectedJob?.job_pay?.maximum_salary
                    ? Number(selectedJob?.job_pay?.maximum_salary)
                    : 0
                  }`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setActiveStep(0);
                setIsEditJobPostDetails(true);
              }}
              disabled={true}
              variant="outlined">
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SubHeader title={"Select your preferred plan"} isRequired={true} />
          </Grid>
          <Grid item>
            <div className="block-pricing">
              <div className="row">
                {plan?.map((item, index) => (
                  <div key={index} className="col-xl-3 col-lg-6 col-md-6">
                    <div className="box-pricing-item" style={{ border: selectedPlan === item?.plan_id ? "1px solid #c12f33" : "1px solid #e0e6f7" }}>
                      <h3 style={{ color: selectedPlan === item?.plan_id ? "#c12f33" : "black", fontSize: "25px" }}>{item.title}</h3>
                      <div className="box-info-price">
                        <span className="text-price color-red" style={{ color: selectedPlan === item?.plan_id ? "#c12f33" : "black" }}>
                          {item.coins}
                        </span>
                        <span className="text-month text-dark"> Coins</span>
                      </div>
                      <div className="border-bottom mb-20">
                        <p className="text-desc-package font-sm color-text-paragraph mb-20">{`${item.total_application} Target Applications`}</p>
                      </div>
                      <ul className="list-package-feature" style={{ paddingLeft: 0 }}>
                        {/* <li> {`${item.total_application} applications`}</li> */}
                        {
                          item?.note.split("\n").map((note, index) => {
                            return (
                              <li style={{fontWeight:"500"}}>{note}</li>
                            );
                          })
                        }
                        {/* <li>Coin returned for less applications in 15 days</li> */}
                      </ul>
                      <button
                        onClick={() => {
                          setSelectedPlan(item?.plan_id);
                        }}
                        className={" btn-border hover-up"}
                        style={{
                          backgroundColor:
                            selectedPlan === item?.plan_id ? "#c12f33" : "white",
                          color: selectedPlan === item?.plan_id ? "#ffffff" : "black",
                          border: selectedPlan === item?.plan_id ? "1px solid #c12f33" : "1px solid black",
                          cursor: "pointer"
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}>
        <Button
          variant="contained"
          onClick={handleBack}
          style={{ width: "30%", backgroundColor: "gray" }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleContinue}
          style={{ width: "30%", backgroundColor: "green" }}>
          Post a job
        </Button>
      </Grid>
    </Grid >
  );
};

export default SelectPlan;
